import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router";
import { Container, Card, Col, Spinner, Button } from "react-bootstrap";
import ReactQuill from "react-quill";
import "react-quill/dist/quill.snow.css";
import { toast } from "react-toastify";

const OrderEmailTemplateMainComp = () => {
  // Simulate API data
  const fakeEmailData = {
    data: [
      {
        _id: "12345",
        signupTemplate: "<p>Welcome to our platform!</p>",
        orderConfirmationTemplate: `
          <h3>Order Confirmation</h3>
          <p>Thank you for your order!</p>
          <p><strong>Order ID:</strong> 1234567</p>
          <p><strong>Total:</strong> $160.00</p>
        `,
        updatedAt: new Date().toISOString(),
      },
    ],
  };

  const isLoading = false;
  const error = null;
  const navigate = useNavigate();
  const email = fakeEmailData.data[0];
  const orderTemplate = email?.orderConfirmationTemplate;

  const [content, setContent] = useState("");

  useEffect(() => {
    if (orderTemplate) {
      setContent(orderTemplate);
    }
  }, [orderTemplate]);

  const handleEditorChange = (value) => {
    setContent(value);
  };

  const handleSave = async () => {
    if (email?._id && content) {
      try {
        console.log(
          "Simulated API Response: Successfully updated email template."
        );
        toast.success("Email Template successfully updated");
        // navigate("/email");
      } catch (err) {
        console.error("Error saving email template", err);
      }
    }
  };

  const handleBack = () => {
    navigate(-1); // Navigate to the previous page
  };

  if (isLoading) {
    return (
      <Container className="bg-white p-4 text-center">
        <h3>Loading...</h3>
      </Container>
    );
  }

  return (
    <Container>
      <Button onClick={handleBack} style={{ marginBottom: "1rem" }}>
        Go back
      </Button>
      <Col md={8} className="mt-2 mx-auto">
        <Card>
          <Card.Header>Edit and Preview Email Content</Card.Header>
          <Card.Body>
            {error ? (
              <p>Error loading data</p>
            ) : (
              <>
                <ReactQuill
                  value={content}
                  onChange={handleEditorChange}
                  theme="snow"
                  modules={{
                    toolbar: [
                      [{ header: "1" }, { header: "2" }],
                      ["bold", "italic", "underline"],
                      [{ list: "ordered" }, { list: "bullet" }],
                      ["link"],
                      ["clean"],
                    ],
                  }}
                />

                <hr />

                <h5>Preview:</h5>
                <div
                  style={{
                    fontFamily: "Arial, sans-serif",
                    color: "#333",
                    width: "500px",
                    margin: "auto",
                    padding: "20px",
                    border: "1px solid #ddd",
                    borderRadius: "8px",
                  }}
                  dangerouslySetInnerHTML={{
                    __html: `
                      <p style="font-size: 16px; line-height: 1.5;">${content}</p>
                      <div className="table-responsive mb-3">
                        <table className="table table-bordered">
                          <thead>
                            <tr>
                              <th>Product ID</th>
                              <th>Quantity</th>
                              <th>Subtotal</th>
                            </tr>
                          </thead>
                          <tbody>
                            <tr>
                              <td>P001</td>
                              <td>2</td>
                              <td>$40.00</td>
                            </tr>
                            <tr>
                              <td>P002</td>
                              <td>1</td>
                              <td>$30.00</td>
                            </tr>
                            <tr>
                              <td>P003</td>
                              <td>3</td>
                              <td>$90.00</td>
                            </tr>
                          </tbody>
                        </table>
                      </div>
                      <p className="font-weight-bold text-right">
                        <strong>Total Amount:</strong> $160.00
                      </p>
                    `,
                  }}
                />
              </>
            )}
          </Card.Body>
          {email && (
            <Card.Footer>
              <small className="text-muted">
                Updated: {new Date(email.updatedAt).toLocaleDateString()}
              </small>
            </Card.Footer>
          )}
        </Card>
        <Button variant="primary" onClick={handleSave} className="mt-3">
          Save Changes
        </Button>
      </Col>
    </Container>
  );
};

export default OrderEmailTemplateMainComp;
