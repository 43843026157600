import React, { useEffect } from "react";
import { useNavigate } from "react-router";
import { Container, Row } from "react-bootstrap";

const AllEmailTempMainComp = () => {
  // Fake data for email templates
  const emailData = {
    data: [
      {
        signupTemplate: `<p>Welcome to our platform! Please click the button below to verify your account.</p>`,
        orderConfirmationTemplate: `<p>Thank you for your order! Below are your order details:</p>`,
        footerTemplate: `<p>&copy; 2024 Company Name. All rights reserved.</p>`,
      },
    ],
  };

  const isLoading = false; // Simulating no loading state
  const error = null; // Simulating no error state
  // const { data: emailData, isLoading, error, refetch } = useGetEmailTemplateQuery();

  useEffect(() => {
    // Trigger refetch every time the component mounts
    // refetch();
  }, []);

  const navigate = useNavigate();
  const email = emailData?.data?.[0];
  const signupTemplate = email?.signupTemplate;
  const orderTemplate = email?.orderConfirmationTemplate;
  const footerTemplate = email?.footerTemplate;

  console.log("email data", email);

  if (isLoading) {
    return (
      <Container className="bg-white p-4 text-center">
        <h3>Loading...</h3>
      </Container>
    );
  }

  return (
    <>
      <Container className="bg-white p-4">
        <Row>
          <h3 className="mb-4">Email Templates</h3>

          {/* Static Order Confirmation Section with Dynamic Template */}
          {orderTemplate && (
            <div className="mb-4">
              <div className="card">
                <div className="card-header d-flex justify-content-between align-items-center">
                  <h5>Order Confirmation Email</h5>
                  <button
                    className="btn btn-primary btn-sm"
                    onClick={() =>
                      navigate("/dashboard/email-management/order")
                    }
                  >
                    Edit Template
                  </button>
                </div>
                <div
                  className="card-body"
                  style={{
                    fontFamily: "Arial, sans-serif",
                    color: "#333",
                    width: "500px",
                    margin: "auto",
                    padding: "20px",
                    border: "1px solid #ddd",
                    borderRadius: "8px",
                    marginTop: "20px",
                  }}
                >
                  <div dangerouslySetInnerHTML={{ __html: orderTemplate }} />
                  <p className="mt-3" style={{ fontSize: "16px" }}>
                    <strong>Order ID:</strong> 1234567
                  </p>
                  <div className="table-responsive mb-3">
                    <table className="table table-bordered">
                      <thead>
                        <tr>
                          <th
                            className="text-left"
                            style={{ fontSize: "14px" }}
                          >
                            Product ID
                          </th>
                          <th
                            className="text-center"
                            style={{ fontSize: "14px" }}
                          >
                            Quantity
                          </th>
                          <th
                            className="text-right"
                            style={{ fontSize: "14px" }}
                          >
                            Subtotal
                          </th>
                        </tr>
                      </thead>
                      <tbody>
                        <tr>
                          <td style={{ fontSize: "14px" }}>P001</td>
                          <td
                            className="text-center"
                            style={{ fontSize: "14px" }}
                          >
                            2
                          </td>
                          <td
                            className="text-right"
                            style={{ fontSize: "14px" }}
                          >
                            $40.00
                          </td>
                        </tr>
                        <tr>
                          <td style={{ fontSize: "14px" }}>P002</td>
                          <td
                            className="text-center"
                            style={{ fontSize: "14px" }}
                          >
                            1
                          </td>
                          <td
                            className="text-right"
                            style={{ fontSize: "14px" }}
                          >
                            $30.00
                          </td>
                        </tr>
                        <tr>
                          <td style={{ fontSize: "14px" }}>P003</td>
                          <td
                            className="text-center"
                            style={{ fontSize: "14px" }}
                          >
                            3
                          </td>
                          <td
                            className="text-right"
                            style={{ fontSize: "14px" }}
                          >
                            $90.00
                          </td>
                        </tr>
                      </tbody>
                    </table>
                  </div>
                  <p
                    className="font-weight-bold text-right"
                    style={{ fontSize: "16px" }}
                  >
                    <strong>Total Amount:</strong> $160.00
                  </p>
                  <footer
                    style={{
                      textAlign: "center",
                      marginTop: "30px",
                      fontSize: "12px",
                      color: "#777",
                    }}
                  >
                    <div dangerouslySetInnerHTML={{ __html: footerTemplate }} />
                  </footer>
                </div>
              </div>
            </div>
          )}

          {/* Static Signup Section with Dynamic Template */}
          {signupTemplate && (
            <div className="mb-4">
              <div className="card">
                <div className="card-header d-flex justify-content-between align-items-center">
                  <h5>Signup Email Template</h5>
                  <button
                    className="btn btn-primary btn-sm"
                    onClick={() =>
                      navigate("/dashboard/email-management/signup")
                    }
                  >
                    Edit Template
                  </button>
                </div>
                <div
                  className="card-body"
                  style={{
                    fontFamily: "Arial, sans-serif",
                    color: "#333",
                    // maxWidth: "500px",
                    width: "500px",
                    margin: "auto",
                    padding: "20px",
                    border: "1px solid #ddd",
                    borderRadius: "8px",
                    marginTop: "20px",
                  }}
                >
                  <div dangerouslySetInnerHTML={{ __html: signupTemplate }} />
                  <div className="text-center my-3">
                    <a
                      // href="${verificationLink}"
                      className="btn btn-primary btn-lg"
                    >
                      Verify Your Account
                    </a>
                  </div>
                  <footer
                    style={{
                      textAlign: "center",
                      marginTop: "30px",
                      fontSize: "12px",
                      color: "#777",
                    }}
                  >
                    <div dangerouslySetInnerHTML={{ __html: footerTemplate }} />
                  </footer>
                </div>
              </div>
            </div>
          )}

          {footerTemplate && (
            <div>
              <div className="card">
                <div className="card-header d-flex justify-content-between align-items-center">
                  <h5>Footer Template Template</h5>
                  <button
                    className="btn btn-primary btn-sm"
                    onClick={() =>
                      navigate("/dashboard/email-management/footer")
                    }
                  >
                    Edit Template
                  </button>
                </div>
                <div
                  className="card-body"
                  style={{
                    fontFamily: "Arial, sans-serif",
                    color: "#333",
                    maxWidth: "500px",
                    minWidth: "500px",
                    margin: "auto",
                    padding: "20px",
                    border: "1px solid #ddd",
                    borderRadius: "8px",
                    marginTop: "20px",
                  }}
                >
                  <footer
                    style={{
                      textAlign: "center",
                      marginTop: "30px",
                      fontSize: "12px",
                      color: "#777",
                    }}
                  >
                    <div dangerouslySetInnerHTML={{ __html: footerTemplate }} />
                  </footer>
                </div>
              </div>
            </div>
          )}

          {error && <p>Error loading email templates</p>}
        </Row>
      </Container>
    </>
  );
};

export default AllEmailTempMainComp;
