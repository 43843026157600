import React from "react";
import { Col, Form, Row } from "react-bootstrap";

const AddCommentFormEng = ({ productData, handleInputChange }) => {
  return (
    <div>
      <Row className="mb-3">
        <Col sm={12}>
          <h4 className="text-left mb-4 fw-bold ">
            Add Comment (English)
          </h4>
        </Col>

        <Col sm={12}>
          <Form.Group controlId="formProduct">
            <Form.Label>Comment</Form.Label>
            <Form.Control
              type="text"
              name="comment_en"
              value={productData?.comment_en}
              onChange={handleInputChange}
              placeholder="Enter comment"
              // required
            />
          </Form.Group>
        </Col>
      </Row>
    </div>
  );
};

export default AddCommentFormEng;
