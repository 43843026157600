import React from "react";

import CircularProgress from "@mui/material/CircularProgress";

const Spinner_component = ({ message }) => {
  return (
    <div className="d-flex flex-column justify-content-center align-items-center w-100 h-100">
      <tr>
        <td style={{ border: "none" }} colSpan="5" className="text-center">
          <CircularProgress />
        </td>
      </tr>
    </div>
  );
};

export default Spinner_component;
