import React, { useState, useEffect } from "react";
import { Button, Form, Row, Col, Spinner, ListGroup } from "react-bootstrap";
import { makeRequest } from "../../../../../api/axiosService";
import { toast } from "react-toastify";

const TopHeader = () => {
  const [headerData, setHeaderData] = useState({
    message: "",
    message_en: "",
    message_ar: "",
    message_kr: "",
    show: false,
  });
  const [messages, setMessages] = useState([]); // Array of messages
  const [isLoading, setIsLoading] = useState(true); // Loading state for fetching
  const [isSaving, setIsSaving] = useState(false); // Saving spinner
  const [isDeleting, setIsDeleting] = useState(null); // Deleting spinner per message

  // Fetch existing header messages on component mount
  useEffect(() => {
    const fetchHeaderMessages = async () => {
      try {
        const resp = await makeRequest("/header-messages/", "GET");
        if (resp.success && resp.data) {
          setMessages(resp.data);
        }
      } catch (error) {
        console.error("Failed to fetch header messages:", error);
      } finally {
        setIsLoading(false);
      }
    };

    fetchHeaderMessages();
  }, []);

  const saveHeader = async () => {
    setIsSaving(true);
    try {
      const resp = await makeRequest("/header-messages/", "POST", {
        message_en: headerData.message_en,
        message_ar: headerData.message_ar,
        message_ku: headerData.message_kr,
        show: headerData.show,
      });
      if (resp.success) {
        toast.success("Saved Successfully");
        setMessages([...messages, resp.data]); // Add the new message to the list
        setHeaderData({
          message_en: "",
          message_ar: "",
          message_kr: "",
          show: false,
        }); // Reset the form
      }
    } catch (error) {
      console.error("Failed to save header message:", error);
    } finally {
      setIsSaving(false);
    }
  };

  const deleteMessage = async (id) => {
    setIsDeleting(id);
    try {
      const resp = await makeRequest(`/header-messages/${id}/`, "DELETE");
      if (resp.success) {
        toast.success("Deleted Successfully");
        setMessages(messages.filter((msg) => msg.id !== id)); // Remove the deleted message
      }
    } catch (error) {
      console.error(`Failed to delete message with id ${id}:`, error);
    } finally {
      setIsDeleting(null);
    }
  };

  return (
    <div className="dashCard mb-4">
      {isLoading ? (
        <div className="text-center mt-5">
          <Spinner animation="border" variant="primary" />
          <p>Loading Header Messages...</p>
        </div>
      ) : (
        <Row className="mb-3">
          <Col className="d-flex align-items-center">
            <h2 className="mainTitleAdd">Header Messages</h2>
          </Col>
        </Row>
      )}

      {/* New Fields: message_en, message_ar, message_kr */}
      <Row className="mb-3">
        <Col sm={12}>
          <Form.Control
            type="text"
            placeholder="Enter message in English"
            value={headerData.message_en}
            onChange={(e) =>
              setHeaderData({ ...headerData, message_en: e.target.value })
            }
          />
        </Col>
      </Row>
      <Row className="mb-3">
        <Col sm={12} dir="rtl">
          <Form.Control
            type="text"
            placeholder="أدخل الرسالة باللغة العربية"
            value={headerData.message_ar}
            onChange={(e) =>
              setHeaderData({ ...headerData, message_ar: e.target.value })
            }
          />
        </Col>
      </Row>
      <Row className="mb-3" dir="rtl">
        <Col sm={12}>
          <Form.Control
            type="text"
            placeholder="نامە بە کوردی داخڵ بکە"
            value={headerData.message_kr}
            onChange={(e) =>
              setHeaderData({ ...headerData, message_kr: e.target.value })
            }
          />
        </Col>
      </Row>

      <Row className="mb-3 mx-2">
        <Col sm={12}>
          <Form.Check
            type="checkbox"
            label="Show Header"
            checked={headerData.show}
            onChange={(e) =>
              setHeaderData({ ...headerData, show: e.target.checked })
            }
          />
        </Col>
      </Row>

      <Row className="mb-3">
        <Col sm={12}>
          <Button
            style={{ float: "right" }}
            variant="primary"
            onClick={saveHeader}
            disabled={isSaving}
            size="sm"
          >
            {isSaving ? <Spinner animation="border" size="sm" /> : "Save"}
          </Button>
        </Col>
      </Row>

      {/* Display Messages */}
      <ListGroup className="mt-3">
        {messages.map((msg) => (
          <ListGroup.Item
            key={msg.id}
            className="d-flex justify-content-between align-items-center"
          >
            <div>
              <strong>Message (EN):</strong> {msg.message_en} <br />
              <strong>Message (AR):</strong> {msg.message_ar} <br />
              <strong>Message (KU):</strong> {msg.message_ku} <br />
              <strong>Show:</strong> {msg.show ? "Yes" : "No"} <br />
              <small>
                Created: {new Date(msg.created_at).toLocaleString()}
              </small>
            </div>
            <Button
              variant="danger"
              size="xs"
              onClick={() => deleteMessage(msg.id)}
              disabled={isDeleting === msg.id}
            >
              {isDeleting === msg.id ? (
                <Spinner animation="border" size="sm" />
              ) : (
                "Delete"
              )}
            </Button>
          </ListGroup.Item>
        ))}
      </ListGroup>
    </div>
  );
};

export default TopHeader;
