import React from "react";
import { Col, Form, Row } from "react-bootstrap";

const AddProductFormAr = ({ productData, handleInputChange }) => {
  return (
    <div dir="rtl">
      <Row className="mb-3">
        <Col sm={12}>
          <h4 className="text-right mb-4 fw-bold">
            إضافة تفاصيل المنتج (بالعربية)
          </h4>
        </Col>

        <Col sm={12}>
          <Form.Group controlId="formProductName">
            <Form.Label>اسم المنتج</Form.Label>
            <Form.Control
              type="text"
              name="name_ar"
              value={productData?.name_ar}
              onChange={handleInputChange}
              placeholder="أدخل اسم المنتج"
              // required
            />
          </Form.Group>
        </Col>

        <Col sm={12}>
          <Form.Group controlId="formProductDescription" className="mb-3">
            <Form.Label>وصف</Form.Label>
            <Form.Control
              as="textarea"
              name="description_ar"
              rows={4}
              value={productData?.description_ar}
              onChange={handleInputChange}
              style={{
                border: "1px solid #ebedf2",
                padding: "15px 15px",
              }}
              placeholder="أدخل وصف المنتج"
            />
          </Form.Group>
        </Col>
      </Row>
    </div>
  );
};

export default AddProductFormAr;
