import React from "react";
import EditAdminForm from "../../../forms/editAdminForm";

const EditAdminMainComp = () => {
  return (
    <div class="container-fluid">
      <EditAdminForm />
    </div>
  );
};

export default EditAdminMainComp;
