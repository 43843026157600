import React from "react";
import { Button, Row, Col } from "react-bootstrap";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faChevronLeft,
  faChevronRight,
} from "@fortawesome/free-solid-svg-icons"; // Import the icons
import "./style.css";

const PaginationComponent = ({ pagination, handlePageChange }) => {
  return (
    <Row className="mt-4">
      <Col sm={12} className="d-flex justify-content-end">
        <Button
          variant="outline-primary"
          onClick={() => handlePageChange(pagination.currentPage - 1)}
          disabled={!pagination.previous}
          className="rounded-3 me-2 btn-sm"
        >
          <FontAwesomeIcon icon={faChevronLeft} /> {/* Left arrow icon */}
        </Button>
        <Button
          variant="outline-primary"
          onClick={() => handlePageChange(pagination.currentPage + 1)}
          disabled={!pagination.next}
          className="rounded-3 btn-sm"
        >
          <FontAwesomeIcon icon={faChevronRight} /> {/* Right arrow icon */}
        </Button>
      </Col>
    </Row>
  );
};

export default PaginationComponent;
