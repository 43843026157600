import React from "react";
import SidebarComp from "../../components/sidebar/sidebar";
import NavbarComp from "../../components/navbar/navbar";
// import AllBrandMainComp from "../../components/body/alldatacomponent/brands/allBrandMainComp";
// import AllCompanyMainComp from "../../components/body/alldatacomponent/company/allcompanymaincomp";
import { AllBrandMainComp } from "../../components/body/alldatacomponent/brands/allBrandMainComp.js";

const BrandManagementScreen = () => {
  return (
    <div className="container-scroller">
      <NavbarComp />
      <div className="container-fluid page-body-wrapper">
        <SidebarComp />
        <div className="main-panel">
          <div className="content-wrapper">
            <AllBrandMainComp />
          </div>
        </div>
      </div>
    </div>
  );
};

export default BrandManagementScreen;
