import AllDataCommonComp from "../common/common";
// import UserCards from "../../../cards/users/userscard";
import { faUsers } from "@fortawesome/free-solid-svg-icons";
import AllUsers from "../../../cards/users/userscard";

const AllUsersMainComp = () => {
  return (
    <div class="container-fluid mt-4">
      <AllDataCommonComp
        addUrl="/dashboard/users-management/add"
        headerIcon={faUsers}
        allemployee="All Users"
      />

      <AllUsers />
    </div>
  );
};

export default AllUsersMainComp;
