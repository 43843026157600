import React, { useState } from "react";
import "./style.css";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faEyeSlash, faEye } from "@fortawesome/free-solid-svg-icons";

const CustomInput = (props) => {
  const [typePass, setTypePass] = useState(true);

  const togglePassType = () => {
    setTypePass(!typePass);
  };

  const onChange = (e) => {
    let value = e.target.value;

    // Check if the input type is number
    if (props?.type === "number") {
      // Allow only valid number input including decimal
      const regExp = /^\d*\.?\d*$/;
      if (!regExp.test(value)) {
        return; // Prevent invalid input
      }

      // Limit the length if maxLength is defined
      if (props?.maxLength && value.length > props.maxLength) {
        value = value.slice(0, props.maxLength);
      }

      // Update the value only if it's valid
      e.target.value = value;
    } else if (props?.maxLength && value.length > props.maxLength) {
      e.target.value = value.slice(0, props.maxLength);
    }

    return props?.onChange(e);
  };

  return (
    <div className={`inputWrapper ${props?.containerClass}`}>
      {props?.label && (
        <label htmlFor={props?.id} className={props?.labelClass}>
          {props?.label}
          <span>{props?.required ? "*" : ""}</span>
        </label>
      )}
      {props?.type === "password" ? (
        <div className="passwordWrapper">
          <input
            maxLength={props?.maxLength}
            type={typePass ? "password" : "text"}
            placeholder={props?.placeholder}
            value={props?.value || ""}
            required={props?.required}
            id={props?.id}
            name={props?.name}
            className={`${props?.inputClass} passInput`}
            onChange={onChange}
          />
          <button type="button" className="eyeButton" onClick={togglePassType}>
            <FontAwesomeIcon icon={typePass ? faEyeSlash : faEye} />
          </button>
        </div>
      ) : props?.type === "textarea" ? (
        <textarea
          maxLength={props?.maxLength}
          placeholder={props?.placeholder}
          value={props?.value || ""}
          required={props?.required}
          id={props?.id}
          name={props?.name}
          className={props?.inputClass}
          onChange={onChange}
        />
      ) : (
        <input
          style={props?.error ? { border: "1px red solid" } : {}}
          maxLength={props?.maxLength}
          type={props?.type || "text"} // Default to 'text' for decimal handling
          placeholder={props?.placeholder}
          value={props?.value || ""}
          required={props?.required}
          id={props?.id}
          name={props?.name}
          className={props?.inputClass}
          onChange={onChange}
        />
      )}
      {props?.error && (
        <p className="mt-1 text-danger text-small">{props?.error}</p>
      )}
    </div>
  );
};

export default CustomInput;
