import React, { useEffect, useState, useRef } from "react";
import { useNavigate, useParams } from "react-router";
import { Form, Button, Row, Col, Spinner } from "react-bootstrap";
import { toast } from "react-toastify";
import BackButton from "../../../../BackButton";
import { makeRequest } from "../../../../../api/axiosService";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faTrash } from "@fortawesome/free-solid-svg-icons";
import Spinner_component from "../../../../profile/Spinner";

const EditSubCategoryMainComp = () => {
  const MAX_FILE_SIZE = 5 * 1024 * 1024; // 5 MB
  const fileInputRef = useRef(null);
  const [uploadImg, setUplaodImg] = useState(false);
  const { id } = useParams(); // Extract ID from URL
  const user = JSON.parse(localStorage.getItem("user"));


  const navigate = useNavigate();
  const [loading, setLoading] = useState(false);
  const [categories, setCategories] = useState([]);
  const [isFetching, setIsFetching] = useState(true); // For fetching data

  const [subCategoryData, setSubCategoryData] = useState({
    name_en: "",
    name_ar: "",
    name_ku: "",
    category: "",
    image: "",
    active: false,
  });

  const [errors, setErrors] = useState({});

  useEffect(() => {
    document.title = "Edit Sub-Category";
  }, []);

  const fetchCategories = async () => {
    try {
      //  setIsLoading(true);
      const resp = await makeRequest(`/category/`, "GET");
      //   console.log(resp, "resp");
      setCategories(resp?.results || []);
    } catch (error) {
      console.error("Error fetching categories:", error);
    } finally {
      //  setIsLoading(false);
    }
  };

  const fetchSubCategoryById = async () => {
    try {
      setIsFetching(true);
      const response = await makeRequest(`/subcategories/${id}`, "GET");
      const data = response;

      setSubCategoryData({
        name_en: data?.name_en || "",
        name_ar: data?.name_ar || "",
        name_ku: data?.name_ku || "",
        category: data?.parent_category || null,
        image: data?.image || "",
        active: data?.active || false,
      });
    } catch (error) {
      console.error("Error fetching category by ID:", error);
      toast.error("Failed to load sub-category data.");
    } finally {
      setIsFetching(false);
    }
  };

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setSubCategoryData({ ...subCategoryData, [name]: value });

    if (value) {
      setErrors((prevErrors) => {
        const { [name]: removedError, ...restErrors } = prevErrors;
        return restErrors;
      });
    }
  };

  const handleImageUpload = async (e) => {
    const files = e.target.files; // Get all selected files

    if (files.length > 0) {
      for (const file of files) {
        try {
          if (file.size > MAX_FILE_SIZE) {
            toast.error(
              "File size is too large. Please upload files under 5 MB."
            );
            return;
          }
          // else {
          //   toast.info("Image is uploading...", {
          //     autoClose: 2000, // Display for 2 seconds (2000 milliseconds)
          //   });
          // }
          setUplaodImg(true);

          const formData = new FormData();
          formData.append("added_images", file);

          // Make the API call to upload the image
          const response = await makeRequest(
            "/upload-image/",
            "POST",
            formData
          );

          if (response?.uploaded_urls?.[0]) {
            toast.success("Uplaoded successfully");

            setSubCategoryData((prevState) => ({
              ...prevState,
              image: response?.uploaded_urls[0], // Replace the image field with the new URL
            }));
          }
        } catch (error) {
          toast.error("Failed to uplaod image.Please try again");
          console.log("Error uploading image:", error);
        } finally {
          setUplaodImg(false);
        }
      }
    }
  };

  const handleRemoveImage = () => {
    setSubCategoryData({ ...subCategoryData, image: "" });
    if (fileInputRef.current) {
      fileInputRef.current.value = "";
    }
  };

  const validate = () => {
    const newErrors = {};
    if (!subCategoryData.name_en)
      newErrors.name_en = "English Category Name is required.";
    if (!subCategoryData.name_ar)
      newErrors.name_ar = "Arabic Category Name is required.";
    if (!subCategoryData.name_ku)
      newErrors.name_ku = "Kurdish Category Name is required.";
    if (!subCategoryData.category)
      newErrors.category = "Category  is required.";
    if (!subCategoryData.image) newErrors.image = "Image is required.";
    setErrors(newErrors);
    return Object.keys(newErrors).length === 0;
  };

    const handleCheckboxChange = (e) => {
      setSubCategoryData({
        ...subCategoryData,
        active: e.target.checked,
      });
    };

  const saveCategory = async () => {
    if (!validate()) return;
    const formData = new FormData();
    formData.append("name_en", subCategoryData.name_en);
    formData.append("name_ar", subCategoryData.name_ar);
    formData.append("name_ku", subCategoryData.name_ku);
    // formData.append("parent_category", subCategoryData.category);
    formData.append("active", subCategoryData.active);

    if (subCategoryData?.image) {
      formData.append("image", subCategoryData.image);
    }

    try {
      setLoading(true);
      const response = await makeRequest(
        `/subcategories/${id}/`,
        "Patch",
        formData
      );
      toast.success("Updated successfully!");
      // console.log("API Response:", response.data);

      // Redirect or clear the form after success
      setSubCategoryData({
        name_en: "",
        name_ar: "",
        name_ku: "",
        category: "",
        image: "",
      });
      if (fileInputRef.current) {
        fileInputRef.current.value = "";
      }
      navigate("/dashboard/sub-categories-management"); // Redirect to categories page
    } catch (error) {
      console.error("Error adding category:", error);
      toast.error("Failed to add category. Please try again.");
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    fetchSubCategoryById();

    fetchCategories();
  }, []);

  return (
    <div className="dashCard mb-4">
      <Row className="mb-3">
        <Col>
          <h2 className="mainTitleAdd">
            <BackButton />
            Edit Sub Category
          </h2>
        </Col>
      </Row>
      {isFetching ? (
        <Spinner_component />
      ) : (
        <Form noValidate>
          {/* Name (English) */}
          <Row className="mb-3">
            <Col lg={4} md={6}>
              <Form.Group controlId="formCategoryname_en">
                <Form.Label>Sub Category Name (English)</Form.Label>
                <Form.Control
                  type="text"
                  name="name_en"
                  value={subCategoryData.name_en}
                  onChange={handleInputChange}
                  placeholder="Enter Sub Category Name in English"
                  isInvalid={!!errors.name_en}
                />
                {errors.name_en && (
                  <Form.Control.Feedback type="invalid">
                    {/* {errors.name_en} */}
                  </Form.Control.Feedback>
                )}
              </Form.Group>
            </Col>
          </Row>
          {/* Name (Arabic) */}
          <Row className="mb-3" dir="rtl">
            <Col lg={4} md={6}>
              <Form.Group controlId="formCategoryname_ar">
                <Form.Label>اسم الفئة الفرعية (بالعربية)</Form.Label>
                <Form.Control
                  type="text"
                  name="name_ar"
                  value={subCategoryData.name_ar}
                  onChange={handleInputChange}
                  placeholder="أدخل اسم الفئة الفرعية بالعربية"
                  isInvalid={!!errors.name_ar}
                />
                {errors.name_ar && (
                  <Form.Control.Feedback type="invalid">
                    {/* {errors.name_ar} */}
                  </Form.Control.Feedback>
                )}
              </Form.Group>
            </Col>
          </Row>
          {/* Name (Kurdish) */}
          <Row className="mb-3" dir="rtl">
            <Col lg={4} md={6}>
              <Form.Group controlId="formCategoryname_ku">
                <Form.Label>ناوی بەشە پارچە (بە کوردی)</Form.Label>
                <Form.Control
                  type="text"
                  name="name_ku"
                  value={subCategoryData.name_ku}
                  onChange={handleInputChange}
                  placeholder="ناوی بەشە پارچە بنوسە بە کوردی"
                  isInvalid={!!errors.name_ku}
                />
                {errors.name_ku && (
                  <Form.Control.Feedback type="invalid">
                    {/* {errors.name_ku} */}
                  </Form.Control.Feedback>
                )}
              </Form.Group>
            </Col>
          </Row>

          <Row className="mb-3">
            {/* <Col lg={4} md={6}>
            <Form.Group controlId="formactive">
              <Form.Check
                type="checkbox"
                label="Approved by Admin"
                name="active"
                checked={subCategoryData.active}
                onChange={handleCheckboxChange}
              />
            </Form.Group>
          </Col> */}
            <Col sm={12}>
              <Form.Group controlId="formCategory">
                <Form.Label>Category</Form.Label>
                <Form.Control
                  as="select"
                  name="category"
                  value={subCategoryData?.category}
                  onChange={handleInputChange}
                  style={{
                    border: errors.category
                      ? "1px solid #dc3545"
                      : "1px solid #ebedf2",
                    padding: "15px 15px",
                  }}
                >
                  <option value="">Select Category</option>
                  {categories?.map((category) => (
                    <option key={category.id} value={category.id}>
                      {category.name_en} / {category.name_ar} /{" "}
                      {category.name_ku}
                    </option>
                  ))}
                </Form.Control>
                {/* Error Message */}
                {errors.category && (
                  <small style={{ color: "#dc3545" }}>
                    {/* {errors.category} */}
                  </small>
                )}
              </Form.Group>
            </Col>
          </Row>

          <Row className="mb-3">
            <Col lg={4} md={6}>
              <Form.Group controlId="formCategoryImage">
                <Form.Label>Upload Image</Form.Label>
                <Form.Control
                  type="file"
                  ref={fileInputRef}
                  onChange={handleImageUpload}
                  isInvalid={!!errors.image}
                />
                {errors.image && (
                  <Form.Control.Feedback type="invalid">
                    {/* {errors.image} */}
                  </Form.Control.Feedback>
                )}
              </Form.Group>
              {uploadImg ? (
                <div className="mb-3 mt-3">
                  <Spinner_component />
                </div>
              ) : (
                subCategoryData.image && (
                  <div className="mt-3 text-center">
                    <img
                      src={subCategoryData?.image}
                      alt=" Preview"
                      style={{
                        width: "200px",
                        height: "200px",
                        objectFit: "contain",
                        borderRadius: "12px",
                        backgroundColor: "#F0F0F0",
                      }}
                    />
                    <div className="mt-2">
                      <FontAwesomeIcon
                        icon={faTrash}
                        style={{
                          color: "red",
                          cursor: "pointer",
                          fontSize: "1rem",
                        }}
                        title="Remove image"
                        onClick={handleRemoveImage}
                      />
                    </div>
                  </div>
                )
              )}
            </Col>
          </Row>
            {(user?.user_type === "ADMIN_USER" || user?.user_type === "SUPER_USER") && (
                    <Row className="mb-3 mx-2">
                      <Col lg={4} md={6}>
                        <Form.Group controlId="formBrandActive">
                          <Form.Check
                            type="checkbox"
                            label="Active"
                            checked={subCategoryData.active}
                            onChange={handleCheckboxChange}
                            name="active"
                          />
                        </Form.Group>
                      </Col>
                    </Row>
                  )}
          
          {/* Save Button */}
          <Row>
            <Col lg={4} md={6}>
              <Button
                disabled={loading}
                variant="primary"
                onClick={saveCategory}
              >
                {loading ? <Spinner animation="border" size="sm" /> : "Save"}
              </Button>
            </Col>
          </Row>
        </Form>
      )}
    </div>
  );
};

export default EditSubCategoryMainComp;
