import React from "react";
import SidebarComp from "../../components/sidebar/sidebar";
import NavbarComp from "../../components/navbar/navbar";
import AllDataCommonComp from "../../components/body/alldatacomponent/common/common.js";
import { faClipboardList } from "@fortawesome/free-solid-svg-icons";
import  AllReturnsMainComp  from "../../components/body/alldatacomponent/returns/allReturnMainComp.js";

const ReturnManagementScreen = () => {
  return (
    <div className="container-scroller">
      <NavbarComp />
      <div className="container-fluid page-body-wrapper">
        <SidebarComp />
        <div className="main-panel">
          <div className="content-wrapper">
            <AllDataCommonComp
              addUrl="/dashboard/return-management/add"
              headerIcon={faClipboardList}
              allemployee="All Returns"
              // downloadCall="returns"
              // search={false}
              // delete={true}
              // showButtons={false}
            />

            <AllReturnsMainComp />
          </div>
        </div>
      </div>
    </div>
  );
};

export default ReturnManagementScreen;
