import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faArrowUp, faArrowDown } from "@fortawesome/free-solid-svg-icons";
import CustomFilters from "../CustomFilters";

import "./style.css";
import CustomButton from "../CustomButton";

const CustomTable = (props) => {
  console.log("custom table props", props);
  return (
    <>
      <CustomFilters
        isFilterOpen={props?.isFilterOpen}
        // Existing filters
        filterSort={props?.filterSort}
        filterSortValue={props?.filterSortValue}
        setFilterSortValue={props?.setFilterSortValue}
        filterSortValues={props?.filterSortValues}
        filterSearch={props?.filterSearch}
        filterSearchValue={props?.filterSearchValue}
        setFilterSearchValue={props?.setFilterSearchValue}
        dateFilter={props?.dateFilter}
        filterFrom={props?.filterFrom}
        setFilterFrom={props?.setFilterFrom}
        filterTo={props?.filterTo}
        setFilterTo={props?.setFilterTo}
        // Per page filter
        perPage={props?.perPage}
        perPageValues={props?.perPageValues}
        itemsPerPage={props?.itemsPerPage}
        setItemsPerPage={props?.setItemsPerPage}
        filterPublished={props?.filterPublished}
        filterPublishedValue={props?.filterPublishedValue}
        setFilterPublishedValue={props?.setFilterPublishedValue}
        // New category filter
        filterCategory={props?.filterCategory}
        filterCategoryValue={props?.filterCategoryValue}
        setFilterCategoryValue={props?.setFilterCategoryValue}
        filterBrand={props?.filterBrand}
        filterBrandValue={props?.filterBrandValue}
        setFilterBrandValue={props?.setFilterBrandValue}
      />

      {/* Button */}
      {props?.buttonLabel && props?.onClickButton && (
        <div className="row mb-3 justify-content-end">
          <div className="col-lg-3 col-md-12 d-flex justify-content-end">
            <CustomButton
              text={props?.buttonLabel || ""}
              onClick={props?.onClickButton}
            />
          </div>
        </div>
      )}

      {/* Table */}
      <div className="customTable">
        <table>
          <thead>
            <tr>
              {props?.headers.map((header) => (
                <th key={header.key}>{header.title}</th>
              ))}
            </tr>
          </thead>
          {props?.length > 0 && props?.children}
        </table>
        {props?.length < 1 && (
          <div className="empty">
            <h6>No Data Found</h6>
          </div>
        )}
      </div>
    </>
  );
};

export default CustomTable;
