import React, { useState, useEffect } from "react";
import { Form, Button, Row, Col, Card, Modal, Spinner } from "react-bootstrap";
import { toast } from "react-toastify";
import { FaLink, FaTrash } from "react-icons/fa";
import { makeRequest } from "../../../../../api/axiosService";

const CarouselItems = () => {
  const [carouselItems, setCarouselItems] = useState([]); // Stores the list of carousel items (existing)
  const [isSaving, setIsSaving] = useState(false); // Saving spinner
  const [isDeleting, setIsDeleting] = useState(null); // Deleting spinner per message
  const [showModal, setShowModal] = useState(false); // State to control modal visibility
  const [uploadData, setUploadData] = useState({
    file: null,
    link: "",
  }); // State for modal fields
  const MAX_FILE_SIZE = 5 * 1024 * 1024;

  // Fetch existing carousel items (using dummy data for now)
  const fetchCarouselItems = async () => {
    try {
      const response = await makeRequest("/carousels/", "GET");
      setCarouselItems(response?.results[0].images || []);
    } catch (error) {
      toast.error("Failed to fetch carousel items. Please try again.");
    }
  };

  useEffect(() => {
    fetchCarouselItems();
  }, []);

  // Handle Modal Field Changes
  const handleModalChange = (e) => {
    const { name, value, files } = e.target;
    setUploadData((prevData) => ({
      ...prevData,
      [name]: files ? files[0] : value,
    }));
  };

  // Handle Image Upload
  const handleImageUpload = async () => {
    setIsSaving(true);

    if (!uploadData.file) {
      toast.error("Please select an image.");
      setIsSaving(false);
      return;
    }

    if (uploadData.file.size > MAX_FILE_SIZE) {
      toast.error("File size is too large. Please upload files under 5 MB.");
      return;
    }

    if (!uploadData.link) {
      toast.error("Please provide a link.");
      setIsSaving(false);
      return;
    }

    try {
      // Step 1: Upload the image to get its URL
      const formData = new FormData();
      formData.append("added_images", uploadData.file);

      const uploadResponse = await makeRequest(
        "/upload-image/",
        "POST",
        formData
      );

      if (!uploadResponse?.uploaded_urls?.[0]) {
        throw new Error("Image upload failed");
      }

      const uploadedImageUrl = uploadResponse.uploaded_urls[0];

      // Step 2: Add the uploaded image to the carousel
      const payload = {
        operation: "add_images",
        images: [
          {
            url: uploadedImageUrl,
            link: uploadData.link,
          },
        ],
      };

      const response = await makeRequest("/carousels/", "POST", payload);

      if (response?.images) {
        setCarouselItems(response.images);
        toast.success("Image added successfully!");
      } else {
        toast.error(
          "Failed to add the image to the carousel. Please try again."
        );
      }

      // Reset modal fields and close modal
      setUploadData({ file: null, link: "" });
      setShowModal(false);
    } catch (error) {
      console.error("Error adding image:", error);
      toast.error("Failed to add the image. Please try again.");
    } finally {
      setIsSaving(false);
    }
  };

  // Handle Removing an Image
  const handleRemoveImage = async (id) => {
    setIsDeleting(id);
    try {
      const payload = {
        operation: "remove_images",
        removed_images: [id],
      };

      await makeRequest("/carousels/", "POST", payload);

      setCarouselItems((prevItems) =>
        prevItems.filter((item) => item.id !== id)
      );

      toast.success("Image removed successfully.");
    } catch (error) {
      console.error("Error deleting image:", error);
      toast.error("Failed to delete carousel item. Please try again.");
    }
    setIsDeleting(null);
  };

  return (
    <div className="dashCard mb-4">
      <div
        style={{
          display: "flex",
          justifyContent: "space-between",
          alignItems: "center",
          marginBottom: "20px",
          marginTop: "20px",
        }}
      >
        <h2 className="mainTitleAdd">Carousel Items</h2>

        <Button variant="primary" onClick={() => setShowModal(true)}>
          Add Image
        </Button>
      </div>
      {/* Modal for Upload */}
      <Modal show={showModal} onHide={() => setShowModal(false)}>
        <Modal.Header closeButton>
          <Modal.Title>Add New Carousel Item</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Form.Group controlId="upload-image">
            <Form.Label>Upload Image</Form.Label>
            <Form.Control
              type="file"
              name="file"
              onChange={handleModalChange}
              accept="image/*"
            />
          </Form.Group>
          <Form.Group controlId="image-link" style={{ marginTop: "15px" }}>
            <Form.Label>Image Link</Form.Label>
            <Form.Control
              type="text"
              name="link"
              placeholder="Enter link for the image"
              value={uploadData.link}
              onChange={handleModalChange}
            />
          </Form.Group>
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" disabled={isSaving} onClick={() => setShowModal(false)}>
            Cancel
          </Button>
          <Button
            variant="primary"
            disabled={isSaving}
            onClick={handleImageUpload}
          >
            {isSaving === true ? (
              <Spinner animation="border" size="sm" />
            ) : (
              "Add"
            )}
          </Button>
        </Modal.Footer>
      </Modal>

      {/* Display Uploaded Images */}
      <Row>
        {carouselItems.map((item) => (
          <Col md={6} key={item.id} className="mb-4">
            <Card className="shadow">
              <Card.Img
                variant="top"
                src={item.url}
                alt="Carousel Item"
                style={{ objectFit: "cover", maxHeight: "200px" }}
              />
              <Card.Body
                style={{
                  display: "flex",
                  justifyContent: "end",
                  alignItems: "center",
                }}
              >
                <Button
                  variant="info"
                  size="sm"
                  style={{ display: "block", marginRight: "10px" }}
                >
                  <a
                    href={item.link}
                    style={{ color: "white", textDecoration: "none" }}
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    <FaLink size={20} />
                  </a>
                </Button>
                <Button
                  variant="danger"
                  size="sm"
                  onClick={() => handleRemoveImage(item.id)}
                  style={{
                    display: "block",
                  }}
                  disabled={isDeleting === item.id}
                >
                  {isDeleting === item.id ? (
                    <Spinner animation="border" size="sm" />
                  ) : (
                    <FaTrash size={20} />
                  )}
                </Button>
              </Card.Body>
            </Card>
          </Col>
        ))}
        {carouselItems.length === 0 && (
          <div
            style={{
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              height: "100%",
              marginBottom: "20px",
              marginTop: "20px",
            }}
            className="shadow"
          >
            <p className="m-0 p-4">No carousel items found.</p>
          </div>
        )}
      </Row>
    </div>
  );
};

export default CarouselItems;
