import React, { useEffect } from "react";
import { Container, Row, Col, Card, Badge, Image } from "react-bootstrap";
import BackButton from "../../../../BackButton";
import { useLocation } from "react-router-dom";

const ViewReturnMainComp = () => {
  const location = useLocation();
  const { returnItem } = location.state || {};

  useEffect(() => {
    if (returnItem?.product_name) {
      document.title = `Return Details - ${returnItem.product_name}`;
    }
  }, [returnItem]);


  return (
    <Container className="mt-4">
      {/* Back Button */}
      <Row className="mb-4">
        <Col>
          <BackButton />
        </Col>
      </Row>

      {/* Return Details */}
      <Card className="mb-4">
        <Card.Header>
          <h4>Return Details</h4>
        </Card.Header>
        <Card.Body>
          <Row className="mb-3">
            {returnItem?.return_image?.length > 0 ? (
              returnItem.return_image.map((image, index) => (
                <Col sm={4} className="d-flex justify-content-center" key={index}>
                  <Image
                    src={image || "https://via.placeholder.com/150"}
                    alt={`Product ${index + 1}`}
                    thumbnail
                  />
                </Col>
              ))
            ) : (
              <Col sm={4} className="d-flex justify-content-center">
                <Image
                  src="https://via.placeholder.com/150"
                  alt="Placeholder"
                  thumbnail
                />
              </Col>
            )}

            <Col sm={8} className="mt-3">
              <p>
                <strong>Product Name:</strong> {returnItem?.product_name || "N/A"}
              </p>
              <p>
                <strong>Quantity:</strong> {returnItem?.quantity || "N/A"}
              </p>
              <p>
                <strong>Reason for Return:</strong>{" "}
                {returnItem?.return_reason || "N/A"}
              </p>
              <p>
                <strong>Status:</strong> {returnItem?.status || "N/A"}
              </p>
              <p>
                <strong>Created At:</strong>{" "}
                {new Date(returnItem?.created_at).toLocaleDateString() || "N/A"}
              </p>
            </Col>
          </Row>
        </Card.Body>
      </Card>

      {/* Customer Details */}
      <Card className="mb-4">
        <Card.Header>
          <h4>Customer Details</h4>
        </Card.Header>
        <Card.Body>
          <Row>
            <Col md={6} sm={12}>
              <p>
                <strong>Name:</strong> {returnItem?.user?.name || "N/A"}
              </p>
              <p>
                <strong>Email:</strong> {returnItem?.user?.email || "N/A"}
              </p>
            </Col>
            <Col md={6} sm={12}>
              <p>
                <strong>Company:</strong> {returnItem?.company?.name || "N/A"}
              </p>
              <p>
                <strong>Order ID:</strong> {returnItem?.order_short_id || "N/A"}
              </p>
            </Col>
          </Row>
        </Card.Body>
      </Card>
    </Container>
  );
};

export default ViewReturnMainComp;
