import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faSearch } from "@fortawesome/free-solid-svg-icons";
import "./style.css";
import { useRef } from "react";

const CustomFilters = (props) => {
  const fromRef = useRef(null);
  const toRef = useRef(null);

  const handleClear = () => {
    props?.setFilterTo("");
    props?.setFilterFrom("");
    if (fromRef.current) {
      fromRef.current.value = "";
    }
    if (toRef.current) {
      toRef.current.value = "";
    }
  };

  return (
    <>
      <div className="tableFilters">
        {props?.isFilterOpen && (
          <div className="row">
            <div className="col-xl-6 mb-2">
              {props?.dateFilter && (
                <div className="filterWrapper d-md-flex align-items-baseline gap-2">
                  <label className="filterLabel">Filter By:</label>
                  <div className="d-flex gap-3">
                    <input
                      type="date"
                      placeholder="From"
                      name="from"
                      className="filterInput"
                      value={props?.filterFrom}
                      max={props?.filterTo}
                      onChange={(event) => {
                        props?.setFilterFrom(event.target.value);
                      }}
                    />
                    <input
                      type="date"
                      placeholder="To"
                      name="to"
                      className="filterInput"
                      min={props?.filterFrom}
                      value={props?.filterTo}
                      onChange={(event) => {
                        props?.setFilterTo(event.target.value);
                      }}
                    />
                  </div>
                  <label className="clearLabel" onClick={handleClear}>
                    Clear
                  </label>
                </div>
              )}
            </div>
            <div className="col-xl-6 mb-2">
              {props?.filterSearch && (
                <div className="filterWrapper d-md-flex align-items-baseline justify-content-end gap-2">
                  <div className="searchWrapper">
                    <input
                      type="text"
                      placeholder="Search"
                      name="search"
                      className="filterInput searchInput"
                      value={props?.filterSearchValue}
                      onChange={(event) => {
                        props?.setFilterSearchValue(event.target.value);
                      }}
                    />
                    <button className="searchButton notButton">
                      <FontAwesomeIcon icon={faSearch} />
                    </button>
                  </div>
                </div>
              )}
            </div>
            {/* Category Filter */}
            <div className="col-xl-6 mb-2">
              {props?.filterCategory && (
                <div className="filterWrapper d-md-flex align-items-baseline gap-2">
                  <label className="filterLabel">Category:</label>
                  <select
                    className="filterInput"
                    value={props.filterCategoryValue} // This should match the selected value
                    onChange={(event) => {
                      const selectedValue = event.target.value;
                      console.log("Selected Category Value:", selectedValue); // Debugging line
                      props.setFilterCategoryValue(selectedValue); // Update state
                    }}
                  >
                    <option value="">All Categories</option>
                    {props.filterCategory.map((category) => (
                      <option key={category._id} value={category._id}>
                        {category.name}
                      </option>
                    ))}
                  </select>
                </div>
              )}
            </div>
            <div className="col-xl-6 mb-2">
              {props?.filterBrand && (
                <div className="filterWrapper d-md-flex align-items-baseline gap-2">
                  <label className="filterLabel">Brands:</label>
                  <select
                    className="filterInput"
                    value={props.filterBrandValue} // This should match the selected value
                    onChange={(event) => {
                      const selectedValue = event.target.value;
                      console.log("Selected Category Value:", selectedValue); // Debugging line
                      props.setFilterBrandValue(selectedValue); // Update state
                    }}
                  >
                    <option value="">All Brands</option>
                    {props.filterBrand.map((category) => (
                      <option key={category._id} value={category._id}>
                        {category.name}
                      </option>
                    ))}
                  </select>
                </div>
              )}
            </div>

            <div className="col-xl-6 mb-2 d-flex justify-content-xl-end gap-3">
              {props?.filterSort && (
                <div className="filterWrapper d-md-flex align-items-baseline justify-content-xl-end gap-2">
                  <label className="filterLabel">Sort By:</label>
                  <select
                    className="filterInput"
                    value={props?.filterSortValue}
                    onChange={(event) => {
                      props?.setFilterSortValue(event.target.value);
                    }}
                  >
                    {props?.filterSortValues.map((item) => (
                      <option key={item.value} value={item.value}>
                        Sort by {item.text}
                      </option>
                    ))}
                  </select>
                </div>
              )}
              {props?.perPage && (
                <div className="filterWrapper d-md-flex align-items-baseline justify-content-xl-end gap-2">
                  <label className="filterLabel">Per Page</label>
                  <select
                    className="filterInput"
                    value={props?.itemsPerPage}
                    onChange={(event) => {
                      props?.setItemsPerPage(event.target.value);
                    }}
                  >
                    {props?.perPageValues.map((item) => (
                      <option key={item.value} value={item.value}>
                        {item.text}
                      </option>
                    ))}
                  </select>
                </div>
              )}
              <div className="col-xl-6 mb-2">
                {props?.filterPublished && (
                  <div className="filterWrapper d-md-flex align-items-baseline gap-2">
                    <label className="filterLabel">Published:</label>
                    <select
                      className="filterInput"
                      value={props.filterPublishedValue} // This should match the selected value
                      onChange={(event) => {
                        const selectedValue = event.target.value; // Get the selected value as string

                        // Set the state based on whether the value is empty or not
                        if (selectedValue === "") {
                          // If "All Published" is selected, set filterPublishedValue to an empty string
                          props.setFilterPublishedValue(""); // Set to empty string
                        } else {
                          // Convert the string to a boolean
                          props.setFilterPublishedValue(
                            selectedValue === "true"
                          ); // Update state with boolean value
                        }

                        console.log(
                          "Selected Published Value:",
                          props.filterPublishedValue
                        ); // Debugging line
                      }}
                    >
                      <option value="">All </option>
                      {props.filterPublished.map((option) => (
                        <option key={option.value} value={option.value}>
                          {option.text}
                        </option>
                      ))}
                    </select>
                  </div>
                )}
              </div>
            </div>
          </div>
        )}
      </div>
    </>
  );
};

export default CustomFilters;
