import React, { useEffect, useState, useRef } from "react";
import { useNavigate, useParams } from "react-router";
import { Form, Button, Row, Col, Spinner } from "react-bootstrap";
import { toast } from "react-toastify";
import BackButton from "../../../../BackButton";
import { makeRequest } from "../../../../../api/axiosService";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faTrash } from "@fortawesome/free-solid-svg-icons";

const EditCategoryMainComp = () => {
  const MAX_FILE_SIZE = 5 * 1024 * 1024; // 5 MB

  const fileInputRef = useRef(null);
  const navigate = useNavigate();
  const { id } = useParams(); // Extract ID from URL
  const user = JSON.parse(localStorage.getItem("user"));

  const [loading, setLoading] = useState(false); // For saving
  const [isFetching, setIsFetching] = useState(true); // For fetching data
  const [categoryData, setCategoryData] = useState({
    name_en: "",
    name_ar: "",
    name_ku: "",
    image: "",
    approvedByAdmin: false, // Checkbox field
  });
  const [errors, setErrors] = useState({});

  // Fetch category by ID
  const fetchCategoryById = async () => {
    try {
      setIsFetching(true);
      const response = await makeRequest(`/category/${id}`, "GET");
      const data = response;

      setCategoryData({
        name_en: data?.name_en || "",
        name_ar: data?.name_ar || "",
        name_ku: data?.name_ku || "",
        image: data?.image || null,
        approvedByAdmin: data?.approvedByAdmin || false,
      });
    } catch (error) {
      console.error("Error fetching category by ID:", error);
      toast.error("Failed to load category data.");
    } finally {
      setIsFetching(false);
    }
  };

  useEffect(() => {
    document.title = "Edit Category";
    fetchCategoryById();
  }, []);

  const handleInputChange = (e) => {
    const { name, value, type, checked } = e.target;
    const fieldValue = type === "checkbox" ? checked : value;
    setCategoryData({ ...categoryData, [name]: fieldValue });
  };

  // const handleImageUpload = (e) => {
  //   const file = e.target.files[0];
  //   if (file) {
  //     setCategoryData({ ...categoryData, Image: file });
  //   }
  // };

  const handleImageUpload = async (e) => {
    const files = e.target.files; // Get all selected files

    if (files.length > 0) {
      for (const file of files) {
        try {
          if (file.size > MAX_FILE_SIZE) {
            toast.error(
              "File size is too large. Please upload files under 5 MB."
            );
            return;
          } else {
            toast.info("Image is uploading...", {
              autoClose: 2000, // Display for 2 seconds (2000 milliseconds)
            });
          }

          const formData = new FormData();
          formData.append("added_images", file);

          // Make the API call to upload the image
          const response = await makeRequest(
            "/upload-image/",
            "POST",
            formData
          );

          if (response?.uploaded_urls?.[0]) {
            toast.success("Uplaoded successfully");

            setCategoryData((prevState) => ({
              ...prevState,
              image: response?.uploaded_urls[0], // Replace the image field with the new URL
            }));
          }
        } catch (error) {
          toast.error("Failed to uplaod image.Please try again");
          console.log("Error uploading image:", error);
        }
      }
    }
  };

  const handleRemoveImage = () => {
    setCategoryData({ ...categoryData, image: "" });
    if (fileInputRef.current) {
      fileInputRef.current.value = "";
    }
  };

  const validate = () => {
    const newErrors = {};
    if (!categoryData.name_en)
      newErrors.name_en = "English Brand Name is required.";
    if (!categoryData.name_ar)
      newErrors.name_ar = "Arabic Brand Name is required.";
    if (!categoryData.name_ku)
      newErrors.name_ku = "Kurdish Brand Name is required.";
    setErrors(newErrors);
    return Object.keys(newErrors).length === 0;
  };

  const updateCategory = async () => {
    if (!validate()) return;

    const formData = new FormData();
    formData.append("name_en", categoryData.name_en);
    formData.append("name_ar", categoryData.name_ar);
    formData.append("name_ku", categoryData.name_ku);
    formData.append("approvedByAdmin", categoryData.approvedByAdmin);

    // Append image if updated
    if (categoryData.image) {
      formData.append("Image", categoryData.image);
    }

    // else if (categoryData.image === null) {
    //   formData.append("removeImage", "true"); // Notify backend to remove the image
    // }

    try {
      setLoading(true);
      await makeRequest(`/category/${id}/`, "PUT", formData);
      toast.success("Category updated successfully!");
      navigate("/dashboard/categories-management");
    } catch (error) {
      console.error("Error updating category:", error);
      toast.error("Failed to update category. Please try again.");
    } finally {
      setLoading(false);
    }
  };

  if (isFetching) {
    return (
      <div className="text-center mt-5">
        <Spinner animation="border" role="status" />
      </div>
    );
  }

  return (
    <div className="dashCard mb-4">
      <Row className="mb-3">
        <Col>
          <h2 className="mainTitleAdd">
            <BackButton />
            Edit Category
          </h2>
        </Col>
      </Row>

      <Form noValidate>
        {/* Name (English) */}
        <Row className="mb-3">
          <Col lg={4} md={6}>
            <Form.Group controlId="formCategoryname_en">
              <Form.Label>Category Name (English)</Form.Label>
              <Form.Control
                type="text"
                name="name_en"
                value={categoryData.name_en}
                onChange={handleInputChange}
                placeholder="Enter Category Name in English"
                isInvalid={!!errors.name_en}
              />
              {errors.name_en && (
                <Form.Control.Feedback type="invalid">
                  {errors.name_en}
                </Form.Control.Feedback>
              )}
            </Form.Group>
          </Col>
        </Row>

        {/* Name (Arabic) */}
        <Row className="mb-3" dir="rtl">
          <Col lg={4} md={6}>
            <Form.Group controlId="formCategoryname_ar">
              <Form.Label>اسم الفئة (عربي)</Form.Label>
              <Form.Control
                type="text"
                name="name_ar"
                value={categoryData.name_ar}
                onChange={handleInputChange}
                placeholder="أدخل اسم الفئة باللغة العربية"
                isInvalid={!!errors.name_ar}
              />
              {errors.name_ar && (
                <Form.Control.Feedback type="invalid">
                  {errors.name_ar}
                </Form.Control.Feedback>
              )}
            </Form.Group>
          </Col>
        </Row>

        {/* Name (Kurdish) */}
        <Row className="mb-3" dir="rtl">
          <Col lg={4} md={6}>
            <Form.Group controlId="formCategoryname_ku">
              <Form.Label>ناوی فەرهەنگ (کوردی)</Form.Label>
              <Form.Control
                type="text"
                name="name_ku"
                value={categoryData.name_ku}
                onChange={handleInputChange}
                placeholder="ناوی فەرهەنگی داخل بکە بە زمانی کوردی"
                isInvalid={!!errors.name_ku}
              />
              {errors.name_ku && (
                <Form.Control.Feedback type="invalid">
                  {errors.name_ku}
                </Form.Control.Feedback>
              )}
            </Form.Group>
          </Col>
        </Row>

        {/* Approved By Admin */}
        {(user?.user_type === "ADMIN_USER" || user?.user_type === "SUPER_USER") && (
          <Row className="mb-3 mx-2">
            <Col lg={4} md={6}>
              <Form.Group controlId="formApprovedByAdmin">
                <Form.Check
                  type="checkbox"
                  label="Approved by Admin"
                  name="approvedByAdmin"
                  checked={categoryData.approvedByAdmin}
                  onChange={handleInputChange}
                />
              </Form.Group>
            </Col>
          </Row>
        )}


        <Row className="mb-3">
          <Col lg={4} md={6}>
            <Form.Group controlId="formCategoryImage">
              <Form.Label>Upload Image</Form.Label>
              <Form.Control
                type="file"
                ref={fileInputRef}
                onChange={handleImageUpload}
                isInvalid={!!errors.image}
              />
              {errors.image && (
                <Form.Control.Feedback type="invalid">
                  {/* {errors.image} */}
                </Form.Control.Feedback>
              )}
            </Form.Group>
            {categoryData.image && (
              <div className="mt-3 text-center">
                <img
                  src={categoryData.image}
                  alt="Category Preview"
                  style={{
                    width: "200px",
                    height: "200px",
                    objectFit: "contain",
                    borderRadius: "12px",
                    backgroundColor: "#F0F0F0",
                  }}
                />
                <div className="mt-2">
                  <FontAwesomeIcon
                    icon={faTrash}
                    style={{
                      color: "red",
                      cursor: "pointer",
                      fontSize: "1rem",
                    }}
                    title="Remove image"
                    onClick={handleRemoveImage}
                  />
                </div>
              </div>
            )}
          </Col>
        </Row>

        <Row className="mt-4">
          <Col>
            <Button
              variant="primary"
              onClick={updateCategory}
              disabled={loading}
            >
              {loading ? <Spinner animation="border" size="sm" /> : "Update"}
            </Button>
          </Col>
        </Row>
      </Form>
    </div>
  );
};

export default EditCategoryMainComp;
