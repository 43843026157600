import axios from "axios";
import { toast } from "react-toastify";

// Create an Axios instance
const axiosInstance = axios.create({
  baseURL: process.env.REACT_APP_BASE_URL, // Set your base URL
  timeout: 100000,
});

// Add a request interceptor to include the token dynamically
axiosInstance.interceptors.request.use(
  (config) => {
    const token = localStorage.getItem("access"); // Get token from localStorage
    if (token) {
      config.headers.Authorization = `Bearer ${token}`; // Attach token
    }
    return config;
  },
  (error) => {
    // Handle request errors
    return Promise.reject(error);
  }
);

// Add a response interceptor if needed
axiosInstance.interceptors.response.use(
  (response) => response,
  (error) => {
    if (error?.response?.status === 401 || error?.status === 401) {
      console.error("Unauthorized! Token might be expired.");

      toast.error("Session expired", {
        autoClose: 5000,
      });

      setTimeout(() => {
        localStorage.clear();
        window.location.href = "/";
      }, 5000);
    }
    return Promise.reject(error);
  }
);

export const makeRequest = async (url, method, data = null, params = null) => {
  try {
    const response = await axiosInstance({
      url,
      method,
      data,
      params, // Query parameters if needed
    });
    return response.data;
  } catch (error) {
    console.error("Error making request:", error);
    throw error;
  }
};
