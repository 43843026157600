import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { faEdit, faTags, faTrash } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import "./style.css";
import { placeholderImage } from "../../../../BrandAssets/images";
import { dateFormatter } from "../../../../utils";
import CustomInput from "../../../CustomInput";
import CustomModal from "../../../CustomModal";
import CustomTable from "../../../CustomTable";
import { makeRequest } from "../../../../api/axiosService";
import Spinner_component from "../../../profile/Spinner";
import PaginationComponent from "../../../CustomTable/Pagination";
import { Switch, CircularProgress } from "@mui/material";

const perPageValues = [
  { text: "8", value: 8 },
  { text: "15", value: 15 },
  { text: "30", value: 30 },
];

export const AllCategoryMainComp = () => {
  const navigate = useNavigate();

  const [itemsPerPage, setItemsPerPage] = useState(perPageValues[0].value);
  const [inputValue, setInputValue] = useState("");
  const [categories, setCategories] = useState([]);
  const [isLoading, setIsLoading] = useState(true);
  const [isDeleteLoading, setIsDeleteLoading] = useState(false);
  const [isStatusLoading, setIsStatusLoading] = useState(null); // For tracking status update

  const [deleteModal, setDeleteModal] = useState(false);
  const [confirmDelete, setConfirmDelete] = useState(false);
  const [categoryToDelete, setCategoryToDelete] = useState(null);
  const [from, setFrom] = useState("");
  const [to, setTo] = useState("");

  const [pagination, setPagination] = useState({
    currentPage: 1,
    totalCount: 0,
    next: null,
    previous: null,
  });

  const handlePageChange = (pageNumber) => {
    if (pageNumber > 0 && pageNumber <= Math.ceil(pagination.totalCount / 10)) {
      setPagination((prev) => ({
        ...prev,
        currentPage: pageNumber,
      }));
    }
  };

  const handleChange = (e) => {
    setInputValue(e.target.value);
  };

  const fetchCategories = async () => {
    try {
      setIsLoading(true);
      const resp = await makeRequest("/category/", "GET");
      setCategories(resp?.results || []);
    } catch (error) {
      console.error("Error fetching categories:", error);
    } finally {
      setIsLoading(false);
    }
  };

  useEffect(() => {
    fetchCategories();
    document.title = "Categories";
  }, []);

  const categoryHeaders = [
    { key: "id", title: "S.No" },
    { key: "image", title: "Image" },
    { key: "name", title: "Name" },
    { key: "registered", title: "Created At" },
    { key: "status", title: "Status" },
    { key: "actions", title: "Actions" },
  ];

  const deleteHandler = (item) => {
    setCategoryToDelete(item);
    setDeleteModal(true);
  };

  const onDelete = async () => {
    if (categoryToDelete) {
      try {
        setIsDeleteLoading(true);
        await makeRequest(`/category/${categoryToDelete?.id}/`, "DELETE");
        setCategories(categories.filter((cat) => cat.id !== categoryToDelete.id)); // Remove deleted category
        setConfirmDelete(true);
      } catch (error) {
        console.error("Error deleting category:", error);
      } finally {
        setDeleteModal(false);
        setIsDeleteLoading(false);
      }
    }
  };

  const toggleCategoryStatus = async (item) => {
    setIsStatusLoading(item.id); // Start spinner for this specific category
    try {
      const updatedStatus = !item.active;
      await makeRequest(`/category/${item.id}/`, "PATCH", { active: updatedStatus });

      // Update local state without refetching
      setCategories((prevCategories) =>
        prevCategories.map((cat) =>
          cat.id === item.id ? { ...cat, active: updatedStatus } : cat
        )
      );
    } catch (error) {
      console.error("Error updating category status:", error);
    } finally {
      setIsStatusLoading(null); // Stop spinner
    }
  };

  return (
    <div>
      <div className="row mb-3">
        <div className="col-12">
          <div className="dashCard">
            <div className="row mb-3 justify-content-between">
              <div className="col-md-6 mb-2 brand_text_icon">
                <i className="icon_bg">
                  <FontAwesomeIcon icon={faTags} />
                </i>
                <h3 className="mainTitle">All Categories</h3>
              </div>
              <div className="col-md-6 mb-2">
                <div className="addUser">
                  <CustomInput
                    type="text"
                    placeholder="Search Here..."
                    value={inputValue}
                    inputClass="mainInput"
                    onChange={handleChange}
                  />
                </div>
              </div>
            </div>
            <div className="row mb-3">
              {isLoading ? (
                <div className="col-12 text-center">
                  <Spinner_component />
                </div>
              ) : (
                <div className="col-12 data-table">
                  <CustomTable
                    headers={categoryHeaders}
                    dateFilter={false}
                    perPage
                    perPageValues={perPageValues}
                    filterFrom={from}
                    setFilterFrom={setFrom}
                    filterTo={to}
                    setFilterTo={setTo}
                    itemsPerPage={itemsPerPage}
                    setItemsPerPage={setItemsPerPage}
                    length={categories.length}
                    buttonLabel="Add Category"
                    onClickButton={() =>
                      navigate("/dashboard/categories-management/add")
                    }
                  >
                    <tbody>
                      {categories?.map((item, index) => (
                        <tr key={item.id}>
                          <td>{index + 1}</td>
                          <td>
                            <img
                              src={item.Image ? item.Image : placeholderImage}
                              className="thumbnail"
                            />
                          </td>
                          <td className="text-capitalize">{item.name_en}</td>
                          <td>{dateFormatter(item.created_at)}</td>
                          <td>
                            {isStatusLoading === item.id ? (
                              <CircularProgress size={20} />
                            ) : (
                              <Switch
                                checked={item.active}
                                onChange={() => toggleCategoryStatus(item)}
                                color={item.active ? "primary" : "error"}
                              />
                            )}
                          </td>
                          <td style={{ cursor: "pointer" }}>
                            <FontAwesomeIcon
                              onClick={() =>
                                navigate(`/dashboard/categories-management/edit/${item.id}`)
                              }
                              icon={faEdit}
                              title="Edit"
                              className="tableActionIcon"
                            />
                            <FontAwesomeIcon
                              onClick={() => deleteHandler(item)}
                              icon={faTrash}
                              title="Delete"
                              className="tableActionIcon"
                            />
                          </td>
                        </tr>
                      ))}
                    </tbody>
                  </CustomTable>
                </div>
              )}
            </div>
            <PaginationComponent
              pagination={pagination}
              handlePageChange={handlePageChange}
            />
          </div>
        </div>
      </div>

      <CustomModal
        loading={isDeleteLoading}
        show={deleteModal}
        close={() => setDeleteModal(false)}
        action={onDelete}
        heading="Are you sure you want to delete this category? By deleting, you will lose all related products."
      />
      <CustomModal
        show={confirmDelete}
        close={() => setConfirmDelete(false)}
        success
        heading="Category Deleted Successfully"
      />
    </div>
  );
};
