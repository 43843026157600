import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import Select from "react-select";
import CustomTable from "../../../CustomTable";
import CustomModal from "../../../CustomModal";
import { makeRequest } from "../../../../api/axiosService";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faEdit,
  faEye,
  faTrash,
  faCheckCircle,
} from "@fortawesome/free-solid-svg-icons";
import Spinner_component from "../../../profile/Spinner";
import PaginationComponent from "../../../CustomTable/Pagination";

import "./style.css";
import StatusModal from "../../../StatusModal/StatusModal";
import { toast } from "react-toastify";

const AllProductMainComp = ({
  selectedProducts,
  setSelectedProducts,
  products,
  selectedFilter,
  setSelectedFilter,
  pagination,
  setPagination,
  companyData,
  company,
  setCompany,
  isLoading,
  fetchProducts,
}) => {
  const navigate = useNavigate();
  // const [products, setProducts] = useState([]);
  // const [companyData, setCompanyData] = useState([]);
  // const [isLoading, setIsLoading] = useState(false);
  const [deleteModal, setDeleteModal] = useState(false);
  const [selectedProductId, setSelectedProductId] = useState();
  // const [selectedFilter, setSelectedFilter] = useState("accept");
  const [statusModal, setStatusModal] = useState(false);
  const [selectedStatus, setSelectedStatus] = useState(""); // Default to item's current status

  const [isDeleteLoading, setIsDeleteLoading] = useState(false);
  const [confirmDelete, setConfirmDelete] = useState(false);
  // const [company, setCompany] = useState(null);

  // const [pagination, setPagination] = useState({
  //   currentPage: 1,
  //   totalCount: 0,
  //   next: null,
  //   previous: null,
  // });

  const customStyles = {
    control: (base) => ({
      ...base,
      borderRadius: "10px",
      padding: "5px",
    }),
    option: (provided) => ({
      ...provided,
      padding: "10px",
      fontSize: "14px",
    }),
  };

  const openStatusModal = () => setStatusModal(true);
  const closeStatusModal = () => setStatusModal(false);

  const handleStatusChange = (item) => {
    setSelectedProductId(item?.id);
    setSelectedStatus(item?.status);
    openStatusModal();
  };

  // const fetchCompanyData = async () => {
  //   try {
  //     const resp = await makeRequest("/company/", "GET");
  //     setCompanyData(resp?.results);
  //   } catch (error) {
  //     console.log(error);
  //   }
  // };

  const tableCols = [
    {
      key: "checkbox",
      title: (
        <input
          type="checkbox"
          onChange={() => {
            if (selectedProducts.length === products.length) {
              setSelectedProducts([]); // Deselect all
            } else {
              setSelectedProducts(products?.map((product) => product.id)); // Select all
            }
          }}
          checked={selectedProducts.length === products.length}
        />
      ),
    },
    {
      key: "id",
      title: "ID",
    },
    {
      key: "product_image",
      title: "Product Image",
    },
    {
      key: "item_name",
      title: "Product Name",
    },
    {
      key: "brand",
      title: "Brand",
    },
    {
      key: "status",
      title: "Status",
    },
    {
      key: "item_category",
      title: "Category",
    },
    {
      key: "actions",
      title: "Actions",
    },
  ];

  // Function to fetch products from API with pagination
  // const fetchProducts = async (page = 1, filter = "", company = "") => {
  //   try {
  //     setIsLoading(true);

  //     const statusQuery = filter ? `&status=${filter}` : "";
  //     const companyQuery = company ? `&company=${company}` : "";

  //     const resp = await makeRequest(
  //       `/products/?page=${page}${statusQuery}${companyQuery}`,
  //       "GET"
  //     );

  //     setProducts(resp?.results || []);
  //     setPagination({
  //       currentPage: page,
  //       totalCount: resp?.count || 0,
  //       next: resp?.next,
  //       previous: resp?.previous,
  //     });
  //   } catch (error) {
  //     console.error("Error fetching products:", error);
  //   } finally {
  //     setIsLoading(false); // Set loading state to false after API call
  //   }
  // };

  // Fetch products on component mount or when pagination changes
  // useEffect(() => {
  //   fetchCompanyData();
  //   fetchProducts(pagination.currentPage, selectedFilter, company);
  // }, [pagination.currentPage, selectedFilter, company]);

  const handleCheckboxChange = (productId) => {
    setSelectedProducts((prevSelected) => {
      if (prevSelected.includes(productId)) {
        return prevSelected.filter((id) => id !== productId);
      } else {
        return [...prevSelected, productId];
      }
    });
  };

  const handleFilterChange = (filter) => {
    setSelectedFilter(filter);
    setPagination((prev) => ({ ...prev, currentPage: 1 }));
  };

  const deleteHandler = (item) => {
    setSelectedProductId(item?.id);
    setDeleteModal(true);
  };
  const productEditHandler = (productId) => {
    navigate(`/dashboard/product-management/edit/${productId}`);
  };

  const productViewHandler = (productId) => {
    navigate(`/dashboard/product-management/${productId}`);
  };

  const formattedCompanyData = companyData?.map((company) => ({
    value: company.id,
    label: company.name_en,
  }));

  const onDelete = async () => {
    if (selectedProductId) {
      try {
        setIsDeleteLoading(true);
        await makeRequest(`/products/${selectedProductId}/`, "DELETE");
        await fetchProducts(pagination?.currentPage);
        setConfirmDelete(true);
      } catch (error) {
        console.log("Error deleting product:", error);
        toast.error(
          error?.response?.data?.detail || " Unable to delete the product"
        );
      } finally {
        setDeleteModal(false);
        setIsDeleteLoading(false);
      }
    }
  };

  // Handle pagination navigation
  const handlePageChange = (pageNumber) => {
    if (pageNumber > 0 && pageNumber <= Math.ceil(pagination.totalCount / 10)) {
      setPagination((prev) => ({
        ...prev,
        currentPage: pageNumber,
      }));
    }
  };

  return (
    <>
      {/* Product Table */}
      <div className="row mb-3">
        <div className="col-12">
          <div className="dashCard">
            <div className="row">
              {/* Button Container */}
              <div className="col-lg-12 col-xl-9 mb-2">
                <div className="row mt-0">
                  <div className="col-md-6 col-sm-12 col-xs-12 col-lg-3 mb-2">
                    <div
                      className={`status-button  ${
                        selectedFilter === "accept"
                          ? "active-filter"
                          : "published"
                      }`}
                      onClick={() => handleFilterChange("accept")}
                    >
                      ACCEPT
                    </div>
                  </div>
                  <div className="col-md-6 col-sm-12 col-xs-12 col-lg-3 mb-2">
                    <div
                      onClick={() => handleFilterChange("save")}
                      className={`status-button  ${
                        selectedFilter === "save"
                          ? "active-filter"
                          : "unpublished"
                      }`}
                    >
                      SAVE
                    </div>
                  </div>
                  <div className="col-md-6 col-sm-12 col-xs-12 col-lg-3 mb-2">
                    <div
                      onClick={() => handleFilterChange("underobservation")}
                      className={`status-button  ${
                        selectedFilter === "underobservation"
                          ? "active-filter"
                          : "under-check"
                      }`}
                    >
                      UNDER OBSERVATION
                    </div>
                  </div>
                  <div className="col-md-6 col-sm-12 col-xs-12 col-lg-3 mb-2">
                    <div
                      onClick={() => handleFilterChange("reject")}
                      className={`status-button  ${
                        selectedFilter === "reject"
                          ? "active-filter"
                          : "refused"
                      }`}
                    >
                      REJECT
                    </div>
                  </div>
                </div>
              </div>
              {/* Select Component Container */}
              <div className="col-lg-12 col-xl-3 col-md-12 mb-2">
                <Select
                  options={formattedCompanyData}
                  value={formattedCompanyData.find(
                    (option) => option.value === company
                  )}
                  onChange={(selectedOption) =>
                    setCompany(selectedOption.value)
                  }
                  placeholder="Select Company"
                  styles={customStyles}
                />
              </div>
            </div>

            {isLoading ? (
              <div className="col-12 text-center mt-5">
                <Spinner_component />
              </div>
            ) : (
              <div className="col-12">
                <div className="row d-flex">
                  <CustomTable
                    headers={tableCols}
                    itemsPerPage={10}
                    isFilterOpen={false}
                    length={products?.length}
                  >
                    <tbody>
                      {products?.map((item, index) => (
                        <tr key={item.id}>
                          <td>
                            <input
                              type="checkbox"
                              checked={selectedProducts.includes(item.id)}
                              onChange={() => handleCheckboxChange(item.id)}
                            />
                          </td>
                          <td>{index + 1}</td>
                          <td>
                            <img
                              height="70"
                              src={
                                item.images
                                  ? item.images[0]
                                  : "https://via.placeholder.com/200"
                              }
                              alt="Product"
                            />
                          </td>
                          <td>{item.name_en}</td>
                          <td>{item.brand_name}</td>
                          <td style={{ textTransform: "uppercase" }}>
                            {item.status}
                          </td>
                          <td>{item.category_name}</td>
                          <td style={{ cursor: "pointer" }}>
                            <FontAwesomeIcon
                              onClick={() => productEditHandler(item.id)}
                              icon={faEdit}
                              title="View details"
                              className="tableActionIcon"
                            />
                            <FontAwesomeIcon
                              onClick={() => productViewHandler(item.id)}
                              icon={faEye}
                              title="View details"
                              className="tableActionIcon"
                            />
                            <FontAwesomeIcon
                              onClick={() => deleteHandler(item)}
                              icon={faTrash}
                              title="Delete the product"
                              className="tableActionIcon"
                            />
                            {/* <FontAwesomeIcon
                              onClick={() => handleStatusChange(item)}
                              icon={faCheckCircle}
                              title="Change product status"
                              className="tableActionIcon"
                            /> */}
                          </td>
                        </tr>
                      ))}
                    </tbody>
                  </CustomTable>
                </div>
              </div>
            )}

            <PaginationComponent
              pagination={pagination}
              handlePageChange={handlePageChange}
            />
          </div>
        </div>
      </div>

      <StatusModal
        show={statusModal}
        onClose={closeStatusModal}
        heading="Change Product Status"
        currentStatus={selectedStatus}
        selectedProductId={selectedProductId}
        fetchProducts={fetchProducts}
        selectedFilter={selectedFilter}
      />

      <CustomModal
        loading={isDeleteLoading}
        show={deleteModal}
        close={() => setDeleteModal(false)}
        action={onDelete}
        heading="Are you sure you want to delete this product?"
      />
      <CustomModal
        show={confirmDelete}
        close={() => setConfirmDelete(false)}
        success
        heading="Product Deleted Successfully"
      />
    </>
  );
};

export default AllProductMainComp;
