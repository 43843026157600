import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Grid,
  Typography,
  IconButton,
  Paper,
  Divider,
  Box,
  CircularProgress,
  Tooltip,
} from "@mui/material";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import VisibilityIcon from "@mui/icons-material/Visibility";
import { makeRequest } from "../../../../api/axiosService";
import Spinner_component from "../../../profile/Spinner";
import { Badge } from "react-bootstrap";

export const AllOrderMainComp = () => {
  const navigate = useNavigate();

  const [data, setData] = useState([]);
  const [isLoading, setIsLoading] = useState(true);

  const fetchOrders = async () => {
    try {
      setIsLoading(true);
      const resp = await makeRequest("/parent-orders/", "GET");
      setData(resp.results);
    } catch (error) {
      console.error("Error fetching orders:", error);
    } finally {
      setIsLoading(false);
    }
  };

  useEffect(() => {
    fetchOrders();
  }, []);

  const viewOrderDetails = (subOrder) => {
    navigate(`/dashboard/order-management/view`, { state: { subOrder } });
  };

  const statusColors = {
    received: "primary",
    confirmed: "success",
    cancelled: "danger",
    preparation: "warning",
    out_for_delivery: "info",
    delivered: "success",
    return: "secondary",
  };

  return (
    <div>
      {isLoading ? (
        <Spinner_component />
      ) : (
        <>
          {data?.map((order) => (
            <Paper
              key={order?.id}
              elevation={3}
              sx={{
                marginBottom: 3,
                padding: 2,
                borderRadius: 2,
                backgroundColor: "#f9f9f9",
              }}
            >
              <Typography
                variant="h6"
                sx={{
                  fontWeight: "bold",
                  fontSize: { xs: "1rem", sm: "1.25rem" },
                  marginBottom: 1,
                }}
              >
                Order ID: {order?.orderShortId}
              </Typography>
              <Grid container spacing={2} sx={{ mb: 2 }}>
                <Grid item xs={12} sm={6}>
                  <Typography sx={{ fontSize: { xs: "0.9rem", sm: "1rem" } }}>
                    <strong>Total Amount:</strong> ${order?.total_amount}
                  </Typography>
                </Grid>
                <Grid item xs={12} sm={6}>
                  <Typography
                    sx={{
                      fontSize: { xs: "0.9rem", sm: "1rem", float: "right" },
                    }}
                  >
                    <strong>Created At:</strong>{" "}
                    {new Date(order?.created_at).toLocaleString()}
                  </Typography>
                </Grid>
              </Grid>

              <Divider sx={{ marginBottom: 2 }} />

              <Typography
                variant="h6"
                sx={{
                  fontWeight: "bold",
                  fontSize: { xs: "1rem", sm: "1.25rem" },
                  marginBottom: 2,
                }}
              >
                Sub Orders
              </Typography>

              {order?.sub_orders?.map((subOrder) => (
                <Accordion
                  key={subOrder?.id}
                  sx={{
                    marginBottom: 2,
                    "& .MuiAccordionSummary-content": {
                      flexDirection: { xs: "column", sm: "row" },
                      alignItems: "center",
                    },
                  }}
                >
                  <AccordionSummary
                    expandIcon={<ExpandMoreIcon />}
                    aria-controls={`panel-${subOrder?.id}-content`}
                    id={`panel-${subOrder?.id}-header`}
                  >
                    <Box sx={{ flexGrow: 1 }}>
                      <Typography
                        sx={{
                          fontWeight: "bold",
                          fontSize: { xs: "0.9rem", sm: "1rem" },
                        }}
                      >
                        {subOrder?.company_name}
                      </Typography>
                      <Typography
                        sx={{ fontSize: { xs: "0.8rem", sm: "0.9rem" } }}
                      >
                        {/* Status: {subOrder?.status} */}
                        <strong>Order Status: </strong>
                        <Badge bg={statusColors[subOrder.status] || "info"}>
                          {subOrder.status}
                        </Badge>
                      </Typography>
                    </Box>
                  </AccordionSummary>

                  <AccordionDetails>
                    <Typography
                      sx={{ fontSize: { xs: "0.8rem", sm: "0.9rem" } }}
                    >
                      <strong>Total Amount:</strong> ${subOrder?.total_amount}
                    </Typography>
                    <Typography
                      sx={{ fontSize: { xs: "0.8rem", sm: "0.9rem" } }}
                    >
                      <strong>Payment Status:</strong>{" "}
                      {subOrder?.payment_status}
                    </Typography>
                    <Typography
                      sx={{ fontSize: { xs: "0.8rem", sm: "0.9rem" } }}
                    >
                      <strong>Created At:</strong>{" "}
                      {new Date(subOrder?.created_at).toLocaleString()}
                    </Typography>
                  </AccordionDetails>
                  <Box
                    sx={{
                      display: "flex",
                      justifyContent: "flex-end",
                      paddingRight: 2,
                      paddingBottom: 1,
                    }}
                  >
                    <Tooltip title="View Details" arrow>
                      <IconButton
                        color="info"
                        onClick={() => viewOrderDetails(subOrder)}
                      >
                        <VisibilityIcon />
                      </IconButton>
                    </Tooltip>
                  </Box>
                </Accordion>
              ))}
            </Paper>
          ))}
        </>
      )}
    </div>
  );
};
