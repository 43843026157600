import React, { useState, useEffect } from "react";
import { useParams } from "react-router-dom";
import Select from "react-select";
import { toast } from "react-toastify";
import { faArrowLeft, faUserEdit } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Link } from "react-router-dom";
import { TextField } from "@mui/material";
import PhoneInput from "react-phone-number-input";
import { makeRequest } from "../../api/axiosService";
import { Spinner } from "react-bootstrap";

const EditUserForm = () => {
  const { id } = useParams(); // Get the user ID from the route parameters
  const [formData, setFormData] = useState({
    phone: "",
    email: "",
    firstName: "",
    lastName: "",
    username: "",
    address: "",
    userType: "",
    profileImage: null,
    isVerified: true,
  });

  const [errors, setErrors] = useState({});
  const [loading, setLoading] = useState(false);
  const [fetching, setFetching] = useState(true);

  const userTypeOptions = [
    { value: "END_USER", label: "End User" },
    // Add more user types as needed
  ];

  const customStyles = {
    control: (base) => ({
      ...base,
      height: 53,
      minHeight: 35,
    }),
  };

  const handleChange = (field, value) => {
    setFormData((prev) => ({
      ...prev,
      [field]: value,
    }));
  };

  const validateForm = () => {
    let formErrors = {};

    if (!formData.phone.trim()) {
      formErrors.phone = "Phone number is required.";
    }
    if (!formData.firstName.trim()) {
      formErrors.firstName = "First Name must not be blank.";
    }
    if (!formData.lastName.trim()) {
      formErrors.lastName = "Last Name must not be blank.";
    }
    if (!formData.email.trim()) {
      formErrors.email = "Enter a valid email address.";
    }
    if (!formData.userType) {
      formErrors.userType = "Please select a valid user type.";
    }

    setErrors(formErrors);
    return Object.keys(formErrors).length === 0;
  };

  const fetchUserData = async () => {
    try {
      const response = await makeRequest(`/users/${id}`, "GET");
      const data = response;

      setFormData({
        phone: data?.phone || "",
        email: data?.email || "",
        firstName: data?.first_name || "",
        lastName: data?.last_name || "",
        username: data?.username || "",
        address: data?.address || "",
        userType: data?.user_type || "",
        profileImage: data?.profile_image || null,
        isVerified: data?.is_verified || false,
      });
    } catch (error) {
      toast.error("Failed to fetch user data.");
    } finally {
      setFetching(false);
    }
  };

  const handleUpdateUser = async () => {
    if (!validateForm()) {
      return;
    }

    const data = {
      phone: formData.phone,
      email: formData.email,
      first_name: formData.firstName,
      last_name: formData.lastName,
      username: formData.username,
      address: formData.address,
      user_type: formData.userType,
      is_verified: formData.isVerified,
    };

    try {
      setLoading(true);
      await makeRequest(`/users/${id}/`, "PUT", data);
      toast.success("User Updated Successfully!");
    } catch (error) {
      if (error.response && error.response.data) {
        const backendErrors = error.response.data;
        setErrors(backendErrors); // Dynamically set errors from backend
        toast.error("Error updating user. Check the form for issues.");
      } else {
        toast.error("Something went wrong. Please try again.");
      }
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    fetchUserData();
  }, [id]);

  if (fetching) {
    return <Spinner animation="border" />;
  }

  return (
    <>
      <div className="d-sm-flex align-items-center justify-content-between my-3">
        <h3 className="page-title">
          <span className="page-title-icon bg-gradient-primary text-white me-2">
            <i>
              <FontAwesomeIcon icon={faUserEdit} />
            </i>
          </span>
          Edit User
        </h3>
        <Link
          className="p-2 rounded bg-gradient-success text-white me-2 shadow-sm"
          to="/dashboard/users-management"
        >
          <FontAwesomeIcon icon={faArrowLeft} />
        </Link>
      </div>

      <div className="col-12">
        <div className="card shadow" style={{ padding: "1rem" }}>
          <div className="card-body">
            <form>
              <div className="row mb-3">
                <div className=" col-sm-12 col-md-6">
                  <TextField
                    fullWidth
                    value={formData.firstName}
                    onChange={(e) => handleChange("firstName", e.target.value)}
                    label="First Name"
                    helperText={
                      <div style={{ color: "#d32f2f" }}>{errors.firstName}</div>
                    }
                  />
                </div>
                <div className=" col-sm-12 col-md-6">
                  <TextField
                    fullWidth
                    value={formData.lastName}
                    onChange={(e) => handleChange("lastName", e.target.value)}
                    label="Last Name"
                    helperText={
                      <div style={{ color: "#d32f2f" }}>{errors.lastName}</div>
                    }
                  />
                </div>
              </div>

              <div className="row mb-3">
                <div className=" col-sm-12 col-md-6">
                  <TextField
                    fullWidth
                    value={formData.email}
                    onChange={(e) => handleChange("email", e.target.value)}
                    label="Email"
                    helperText={
                      <div style={{ color: "#d32f2f" }}>{errors.email}</div>
                    }
                  />
                </div>
                <div className=" col-sm-12 col-md-6">
                  <PhoneInput
                    className="form-control"
                    defaultCountry="US"
                    value={formData.phone}
                    onChange={(value) => handleChange("phone", value)}
                    placeholder="Mobile Number"
                  />
                  {errors.phone && (
                    <div
                      style={{
                        color: "#d32f2f",
                        fontSize: "0.75rem",
                        marginTop: "4px",
                      }}
                    >
                      {errors.phone}
                    </div>
                  )}
                </div>
              </div>

              <div className="row mb-3">
                <div className=" col-sm-12 col-md-6">
                  <TextField
                    fullWidth
                    value={formData.username}
                    onChange={(e) => handleChange("username", e.target.value)}
                    label="Username"
                  />
                </div>
                <div className=" col-sm-12 col-md-6">
                  <TextField
                    fullWidth
                    value={formData.address}
                    onChange={(e) => handleChange("address", e.target.value)}
                    label="Address"
                  />
                </div>
              </div>

              <div className="row mb-3">
                <div className=" col-sm-12 col-md-6">
                  <Select
                    options={userTypeOptions}
                    value={userTypeOptions.find(
                      (opt) => opt.value === formData.userType
                    )}
                    onChange={(option) =>
                      handleChange("userType", option.value)
                    }
                    placeholder="Select User Type"
                    styles={customStyles}
                  />
                  {errors.userType && (
                    <div
                      style={{
                        color: "#d32f2f",
                        fontSize: "0.75rem",
                        marginTop: "4px",
                      }}
                    >
                      {errors.userType}
                    </div>
                  )}
                </div>
              </div>

              <div className="d-flex justify-content-center mt-5">
                <button
                  type="button"
                  onClick={handleUpdateUser}
                  className="btn btn-gradient-primary"
                  disabled={loading}
                >
                  {loading ? (
                    <Spinner animation="border" size="sm" />
                  ) : (
                    "Update"
                  )}
                </button>
              </div>
            </form>
          </div>
        </div>
      </div>
    </>
  );
};

export default EditUserForm;
