import React from "react";
import { Col, Form, Row } from "react-bootstrap";

const AddCommentFormKr = ({ commentData, handleInputChange }) => {
  return (
    <div dir="rtl">
      <Row className="mb-3">
        <Col sm={12}>
          <h4 className="text-left mb-4 fw-bold ">
            زانیاریەکانی لێدوان هەڵبژێنە (کوردی)
          </h4>
        </Col>

        <Col sm={12}>
          <Form.Group controlId="formComment">
            <Form.Label>لێدوان</Form.Label>
            <Form.Control
              type="text"
              name="comment_ku"
              value={commentData?.comment_ku}
              onChange={handleInputChange}
              placeholder="لێدوانەکەت بنووسە"
            />
          </Form.Group>
        </Col>
      </Row>
    </div>
  );
};

export default AddCommentFormKr;
