import React, { useState, useEffect } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faEdit, faEye, faTrash } from "@fortawesome/free-solid-svg-icons";
import CircularProgress from "@mui/material/CircularProgress";
import { useNavigate } from "react-router-dom";
// import { makeRequest } from "../../../api/axiosService";
// import CustomTable from "../../CustomTable";
// import { placeholderImage } from "../../../BrandAssets/images";
// import PaginationComponent from "../../CustomTable/Pagination";
// import CustomModal from "../../CustomMo/dal";
import { toast } from "react-toastify";
import { makeRequest } from "../../../../api/axiosService";
import CustomTable from "../../../CustomTable";
import CustomModal from "../../../CustomModal";
import PaginationComponent from "../../../CustomTable/Pagination";

const AllReturnsMainComp = () => {
  const [returns, setReturns] = useState([]);
  const [isLoading, setIsLoading] = useState(true);
  const [error, setError] = useState(null);
  const navigate = useNavigate();

  const perPageValues = [10, 20, 50];
  const [itemsPerPage, setItemsPerPage] = useState(10);
  const [pagination, setPagination] = useState({
    currentPage: 1,
    totalCount: 0,
    next: null,
    previous: null,
  });

  const [deleteModal, setDeleteModal] = useState(false);
  const [returnToDelete, setReturnToDelete] = useState(null);
  const [isDeleteLoading, setIsDeleteLoading] = useState(false);
  const [confirmDelete, setConfirmDelete] = useState(false);

  const fetchReturns = async (page = 1) => {
    try {
      setIsLoading(true);

      const response = await makeRequest(`/returns/?page=${page}`, "GET"); // Replace with actual API endpoint
      setReturns(response?.results || []);
      setPagination((prev) => ({
        ...prev,
        currentPage: page,
        totalCount: response?.status_counts?.pending || 0,
        next: response?.next,
        previous: response?.previous,
      }));
    } catch (error) {
      console.error("Error fetching returns:", error);
      setError("Failed to fetch returns.");
    } finally {
      setIsLoading(false);
    }
  };

  useEffect(() => {
    fetchReturns(pagination.currentPage);
  }, [pagination.currentPage]);

  const deleteHandler = (returnItem) => {
    setReturnToDelete(returnItem);
    setDeleteModal(true);
  };

  const onDelete = async () => {
    if (returnToDelete) {
      try {
        setIsDeleteLoading(true);
        await makeRequest(`/returns/${returnToDelete?.id}/`, "DELETE");
        await fetchReturns();
        setConfirmDelete(true);
      } catch (error) {
        console.log("Error deleting return:", error);
        toast.error(error?.response?.data?.detail || "Failed to delete return.");
      } finally {
        setDeleteModal(false);
        setIsDeleteLoading(false);
      }
    }
  };

  const handlePageChange = (pageNumber) => {
    if (pageNumber > 0 && pageNumber <= Math.ceil(pagination.totalCount / 10)) {
      setPagination((prev) => ({
        ...prev,
        currentPage: pageNumber,
      }));
    }
  };

  const returnHeaders = [
    { title: "#", sortable: false },
    { title: "Product Name", sortable: true },
    { title: "User", sortable: true },
    { title: "Email", sortable: true },
    { title: "Company", sortable: true },
    { title: "Order ID", sortable: true },
    { title: "Quantity", sortable: true },
    { title: "Reason", sortable: false },
    { title: "Status", sortable: true },
    { title: "Created At", sortable: true },
    { title: "Actions", sortable: false },
  ];

  return (
    <div className="col-12 data-table dashCard">
      {isLoading ? (
        <div className="text-center">
          <CircularProgress />
        </div>
      ) : error ? (
        <div className="text-danger text-center">{error}</div>
      ) : (
        <div>
          <CustomTable
            headers={returnHeaders}
            dateFilter={false}
            perPage
            perPageValues={perPageValues}
            itemsPerPage={itemsPerPage}
            setItemsPerPage={setItemsPerPage}
            length={returns.length}
          >
            <tbody>
              {returns?.map((returnItem, index) => (
                <tr key={returnItem.id}>
                  <td>{index + 1}</td>
                  <td>{returnItem?.product_name || "N/A"}</td>
                  <td>{returnItem?.user?.name || "N/A"}</td>
                  <td>{returnItem?.user?.email || "N/A"}</td>
                  <td>{returnItem?.company?.name || "N/A"}</td>
                  <td>{returnItem?.order_short_id || "N/A"}</td>
                  <td>{returnItem?.quantity || "N/A"}</td>
                  <td>{returnItem?.return_reason || "N/A"}</td>
                  <td>{returnItem?.status || "N/A"}</td>
                  <td>{new Date(returnItem?.created_at).toLocaleDateString()}</td>
                  <td style={{ cursor: "pointer" }}>
                    <FontAwesomeIcon
                      onClick={() =>
                        navigate(`/dashboard/return-management/${returnItem?.id}`, {
                          state: { returnItem },
                        })
                      }
                      icon={faEye}
                      title="View"
                      className="tableActionIcon"
                    />

                    <FontAwesomeIcon
                      onClick={() => deleteHandler(returnItem)}
                      icon={faTrash}
                      title="Delete"
                      className="tableActionIcon"
                    />
                  </td>
                </tr>
              ))}
            </tbody>
          </CustomTable>
        </div>
      )}
      <CustomModal
        loading={isDeleteLoading}
        show={deleteModal}
        close={() => setDeleteModal(false)}
        action={onDelete}
        heading="Are you sure you want to delete this return?"
      />
      <CustomModal
        show={confirmDelete}
        close={() => setConfirmDelete(false)}
        success
        heading="Return Deleted Successfully"
      />
      <PaginationComponent
        pagination={pagination}
        handlePageChange={handlePageChange}
      />
    </div>
  );
};

export default AllReturnsMainComp;
