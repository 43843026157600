import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router";
// import { DashboardLayout } from "../../Components/Layout/DashboardLayout";
import { Container, Card, Col, Spinner, Button } from "react-bootstrap";
// import { useSelector } from "react-redux";

import ReactQuill from "react-quill";
import "react-quill/dist/quill.snow.css";

// import { toast } from "react-toastify";
// import { useEditEmailTemplateMutation, useGetEmailTemplateQuery } from "../../Redux/Apis/Email";

const SignupTemplateMainComp = () => {
  // Simulated API data
  const fakeEmailData = {
    data: [
      {
        _id: "12345",
        signupTemplate:
          "<p>Welcome to our platform! Please verify your email to continue.</p>",
        updatedAt: new Date().toISOString(),
      },
    ],
  };

  // Uncomment these lines for API integration
  // const { data: emailData, isLoading, error, refetch } = useGetEmailTemplateQuery();
  // const [editNewsletter] = useEditEmailTemplateMutation();
  const navigate = useNavigate();
  // const { user } = useSelector((state) => state.authSlice);

  const email = fakeEmailData?.data?.[0];
  const signupTemplate = email?.signupTemplate;

  const [content, setContent] = useState("");

  useEffect(() => {
    if (signupTemplate) {
      setContent(signupTemplate);
    }
  }, [signupTemplate]);

  const handleEditorChange = (value) => {
    setContent(value);
  };

  const handleSave = async () => {
    if (email?._id && content) {
      try {
        // Simulated save response
        console.log("Simulated save: Template updated.");
        // navigate("/email");
        // Uncomment for toast notification
        // toast.success("Email Template successfully updated");
      } catch (err) {
        console.error("Error saving email template", err);
      }
    }
  };

  const handleBack = () => {
    navigate(-1);
  };

  return (
    <Container>
      <Button onClick={handleBack} style={{ marginBottom: "1rem" }}>
        Go back
      </Button>
      <Col md={8} className="mt-2 mx-auto">
        <Card>
          <Card.Header>Edit and Preview Email Content</Card.Header>
          <Card.Body>
            {false ? ( // Simulating `isLoading` for future use
              <Spinner animation="border" />
            ) : null ? ( // Simulating `error` for future use
              <p>Error loading data</p>
            ) : (
              <>
                <ReactQuill
                  value={content}
                  onChange={handleEditorChange}
                  theme="snow"
                  modules={{
                    toolbar: [
                      [{ header: "1" }, { header: "2" }],
                      ["bold", "italic", "underline"],
                      [{ list: "ordered" }, { list: "bullet" }],
                      ["link"],
                      ["clean"],
                    ],
                  }}
                />
                <hr />
                <h5 className="text-center">Preview:</h5>
                <div
                  style={{
                    fontFamily: "Arial, sans-serif",
                    color: "#333",
                    maxWidth: "500px",
                    margin: "auto",
                    padding: "20px",
                    border: "1px solid #ddd",
                    borderRadius: "8px",
                  }}
                >
                  <div dangerouslySetInnerHTML={{ __html: content }} />
                  <div className="text-center my-3">
                    <a href="#" className="btn btn-primary btn-lg">
                      Verify Your Account
                    </a>
                  </div>
                </div>
              </>
            )}
          </Card.Body>
          {email && (
            <Card.Footer>
              <small className="text-muted">
                Updated: {new Date(email.updatedAt).toLocaleDateString()}
              </small>
            </Card.Footer>
          )}
        </Card>
        <Button variant="primary" onClick={handleSave} className="mt-3">
          Save Changes
        </Button>
      </Col>
    </Container>
  );
};

export default SignupTemplateMainComp;
