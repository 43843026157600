import React from "react";
import SidebarComp from "../../components/sidebar/sidebar";
import NavbarComp from "../../components/navbar/navbar";
import AllSubscribersMainComp from "../../components/body/alldatacomponent/subscribers/allSubscribers";

const allSubscribers = () => {
  return (
    <div className="container-scroller">
      <NavbarComp />
      <div className="container-fluid page-body-wrapper">
        <SidebarComp />
        <div className="main-panel">
          <div className="content-wrapper">
            <AllSubscribersMainComp />
          </div>
        </div>
      </div>
    </div>
  );
};

export default allSubscribers;
