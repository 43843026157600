import React, { useState, useEffect } from "react";
import Select from "react-select";
import { toast } from "react-toastify";
import { faArrowLeft, faUsers } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Link, useParams } from "react-router-dom";
import { TextField } from "@mui/material";
import PhoneInput from "react-phone-number-input";
import { makeRequest } from "../../api/axiosService";
import { Spinner } from "react-bootstrap";
import Spinner_component from "../profile/Spinner";

const EditAdminForm = () => {
  const { id } = useParams();
  const [isFetching, setIsFetching] = useState(true);

  const [formData, setFormData] = useState({
    phone: "",
    email: "",
    firstName: "",
    lastName: "",
    username: "",
    password: "",
    address: "",
    userType: "",
    profileImage: null,
  });

  const [errors, setErrors] = useState({});
  const [loading, setLoading] = useState(false);

  const userTypeOptions = [
    { value: "ADMIN_USER", label: "Admin User" },
    { value: "COMPANY_USER", label: "Company User" },
  ];

  const handleChange = (field, value) => {
    setFormData((prev) => ({
      ...prev,
      [field]: value,
    }));
  };

  const validateForm = () => {
    let formErrors = {};

    if (formData.phone.length < 10) {
      formErrors.phone = "Phone number should contain at least 10 characters.";
    }
    if (!formData.firstName.trim()) {
      formErrors.firstName = "First Name must not be blank.";
    }
    if (!formData.lastName.trim()) {
      formErrors.lastName = "Last Name must not be blank.";
    }
    if (!formData.email.trim()) {
      formErrors.email = "Enter a valid email address.";
    }
    // if (!formData.password.trim()) {
    //   formErrors.password = "Password is required.";
    // }
    if (formData.userType === "") {
      formErrors.userType = "Please select a valid user type.";
    }

    setErrors(formErrors);
    return Object.keys(formErrors).length === 0;
  };

  const customStyles = {
    control: (base) => ({
      ...base,
      height: 53,
      minHeight: 35,
    }),
  };

  const fetchAdminData = async () => {
    try {
      const response = await makeRequest(`/admin-users/${id}`, "GET");
      if (response) {
        setFormData({
          phone: response.phone || "",
          email: response.email || "",
          firstName: response.first_name || "",
          lastName: response.last_name || "",
          username: response.username || "",
          password: "", // Keep empty; should not be pre-filled for security reasons
          address: response.address || "",
          userType: response.user_type || "",
          profileImage: response.profile_image || null,
        });
      }
      setIsFetching(false);
    } catch (error) {
      console.error("Error fetching admin data:", error);
      toast.error("Failed to fetch admin data. Please try again.");
      setIsFetching(false);
    }
  };

  const handleAddUser = async () => {
    if (!validateForm()) {
      return;
    }

    const data = {
      phone: formData.phone,
      email: formData.email,
      first_name: formData.firstName,
      last_name: formData.lastName,
      username: formData.username,
      address: formData.address,
      user_type: formData.userType,
      ...(formData.password !== "" && { password: formData.password }),
    };

    try {
      setLoading(true);
      const response = await makeRequest(`/admin-users/${id}/`, "PATCH", data);
      console.log(response, "response");
      toast.success("User updated Successfully!");

      // Reset form fields
      setFormData({
        phone: "",
        email: "",
        firstName: "",
        lastName: "",
        username: "",
        password: "",
        address: "",
        userType: "END_USER",
        profileImage: null,
      });
      setErrors({});
    } catch (error) {
      if (error?.response && error?.response?.data) {
        const backendErrors = error.response.data;
        setErrors(backendErrors); // Dynamically set errors from backend
        toast.error(error?.response?.data?.detail || "Error while updating");
      } else {
        toast.error("Something went wrong. Please try again.");
      }
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    fetchAdminData();
  }, []);

  useEffect(() => {
    if (
      formData.phone &&
      !formData.phone.startsWith("+") &&
      formData.phone.length > 4
    ) {
      toast.warn("Phone number should contain the country code, e.g., +46...");
    }
  }, [formData.phone]);

  return (
    <>
      <div className="d-sm-flex align-items-center justify-content-between my-3">
        <h3 className="page-title">
          <span className="page-title-icon bg-gradient-primary text-white me-2">
            <i>
              <FontAwesomeIcon icon={faUsers} />
            </i>
          </span>
          Edit Admin
        </h3>
        <Link
          className="p-2 rounded bg-gradient-success text-white me-2 shadow-sm"
          to="/dashboard/admins-management"
        >
          <FontAwesomeIcon icon={faArrowLeft} />
        </Link>
      </div>
      {isFetching ? (
        <Spinner_component />
      ) : (
        <div className="col-12">
          <div className="card shadow" style={{ padding: "1rem" }}>
            <div className="card-body">
              <form>
                <div className="row mb-3">
                  <div className="col-md-6">
                    <TextField
                      fullWidth
                      value={formData.firstName}
                      onChange={(e) =>
                        handleChange("firstName", e.target.value)
                      }
                      label="First Name"
                      helperText={
                        <div style={{ color: "#d32f2f" }}>
                          {errors.firstName}
                        </div>
                      }
                    />
                  </div>
                  <div className="col-md-6">
                    <TextField
                      fullWidth
                      value={formData.lastName}
                      onChange={(e) => handleChange("lastName", e.target.value)}
                      label="Last Name"
                      helperText={
                        <div style={{ color: "#d32f2f" }}>
                          {errors.lastName}
                        </div>
                      }
                    />
                  </div>
                </div>

                <div className="row mb-3">
                  <div className="col-md-6">
                    <TextField
                      fullWidth
                      value={formData.email}
                      onChange={(e) => handleChange("email", e.target.value)}
                      label="Email"
                      helperText={
                        <div style={{ color: "#d32f2f" }}>{errors.email}</div>
                      }
                    />
                  </div>
                  <div className="col-md-6">
                    <PhoneInput
                      className="form-control"
                      defaultCountry="SE"
                      value={formData.phone}
                      onChange={(value) => handleChange("phone", value)}
                      placeholder="Mobile Number"
                    />
                    {errors.phone && (
                      <div
                        style={{
                          color: "#d32f2f",
                          fontSize: "0.75rem",
                          marginTop: "4px",
                        }}
                      >
                        {errors.phone}
                      </div>
                    )}
                  </div>
                </div>

                <div className="row mb-3">
                  <div className="col-md-6">
                    <TextField
                      fullWidth
                      value={formData.username}
                      onChange={(e) => handleChange("username", e.target.value)}
                      label="Username"
                      helperText={
                        <div style={{ color: "#d32f2f" }}>
                          {errors.username}
                        </div>
                      }
                    />
                  </div>
                  <div className="col-md-6">
                    <TextField
                      fullWidth
                      value={formData.password}
                      onChange={(e) => handleChange("password", e.target.value)}
                      type="password"
                      label="Password"
                      helperText={
                        <div style={{ color: "#d32f2f" }}>
                          {errors.password}
                        </div>
                      }
                    />
                  </div>
                </div>

                <div className="row mb-3">
                  <div className="col-md-6">
                    <TextField
                      fullWidth
                      value={formData.address}
                      onChange={(e) => handleChange("address", e.target.value)}
                      label="Address"
                    />
                  </div>
                  <div className="col-md-6">
                    <Select
                      options={userTypeOptions}
                      value={userTypeOptions.find(
                        (opt) => opt.value === formData.userType
                      )}
                      onChange={(option) =>
                        handleChange("userType", option.value)
                      }
                      placeholder="Select User Type"
                      styles={customStyles}
                    />
                    {errors.userType && (
                      <div
                        style={{
                          color: "#d32f2f",
                          fontSize: "0.75rem",
                          marginTop: "4px",
                        }}
                      >
                        {errors.userType}
                      </div>
                    )}
                  </div>
                </div>

                <div className="d-flex justify-content-center mt-5">
                  <button
                    type="button"
                    onClick={handleAddUser}
                    className="btn btn-gradient-primary"
                    disabled={loading}
                  >
                    {loading ? (
                      <Spinner animation="border" size="sm" />
                    ) : (
                      "Save"
                    )}
                  </button>
                </div>
              </form>
            </div>
          </div>
        </div>
      )}
    </>
  );
};

export default EditAdminForm;
