import React from "react";
import { Col, Form, Row } from "react-bootstrap";

const AddCommentFormAr = ({ commentData, handleInputChange }) => {
  return (
    <div dir="rtl">
      <Row className="mb-3">
        <Col sm={12}>
          <h4 className="text-right mb-4 fw-bold">
            إضافة تعليق (بالعربية)
          </h4>
        </Col>

        <Col sm={12}>
          <Form.Group controlId="formComment">
            <Form.Label>التعليق</Form.Label>
            <Form.Control
              type="text"
              name="comment_ar"
              value={commentData?.comment_ar}
              onChange={handleInputChange}
              placeholder="أدخل التعليق"
              // required
            />
          </Form.Group>
        </Col>
      </Row>
    </div>
  );
};

export default AddCommentFormAr;
