import React, { useState, useEffect } from "react";
import { Modal, Button, Spinner } from "react-bootstrap";
import "./StatusModal.css"; // Import the CSS file
import { makeRequest } from "../../api/axiosService";
import { toast } from "react-toastify";

const StatusModal = ({
  show,
  onClose,
  currentStatus,
  heading,
  selectedProductId,
  fetchProducts,
  selectedFilter,
}) => {
  const [selectedStatus, setSelectedStatus] = useState(currentStatus || "");
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    // Set the default status whenever currentStatus changes
    setSelectedStatus(currentStatus || "");
  }, [currentStatus]);

  const handleStatusChange = async (status) => {
    try {
      if (status === currentStatus) return;
      setLoading(true);
      setSelectedStatus(status);
      const resp = await makeRequest(
        `/products/${selectedProductId}/update_status/`,
        "PATCH",
        {
          status,
        }
      );

      fetchProducts(1, selectedFilter);
      toast.success(resp?.detail || "Status Updated Successfully");
      onClose();
    } catch (error) {
      toast.error(
        error?.response?.data?.detail || " Unable to change the product status"
      );
    } finally {
      setLoading(false);
    }
  };

  return (
    <Modal show={show} onHide={onClose} centered>
      <Modal.Header className="status-modal-header">
        <Modal.Title className="status-modal-title">{heading}</Modal.Title>
      </Modal.Header>
      <Modal.Body className="status-modal-body">
        <form className="status-modal-form">
          {[
            { value: "accept", label: "Accept" },
            { value: "save", label: "Save" },
            { value: "underobservation", label: "Under Observation" },
            { value: "reject", label: "Reject" },
          ].map((option) => (
            <div
              key={option.value}
              className={`status-option ${
                selectedStatus === option.value ? "active-option" : ""
              }`}
              onClick={() => handleStatusChange(option.value)}
            >
              <input
                type="radio"
                value={option.value}
                checked={selectedStatus === option.value}
                onChange={(e) => handleStatusChange(e.target.value)}
              />
              <span className="status-label">{option.label}</span>
            </div>
          ))}
        </form>
      </Modal.Body>
      <Modal.Footer className="status-modal-footer">
        <Button
          variant="secondary"
          className="status-close-btn"
          onClick={onClose}
          disabled={loading}
        >
          {loading ? <Spinner size="sm" /> : "Close"}
        </Button>
      </Modal.Footer>
    </Modal>
  );
};

export default StatusModal;
