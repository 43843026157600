import React, { useState, useEffect } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faEdit, faTrash } from "@fortawesome/free-solid-svg-icons";
import CircularProgress from "@mui/material/CircularProgress";
import { useNavigate } from "react-router-dom";
import { makeRequest } from "../../../api/axiosService";
import CustomTable from "../../CustomTable";
import { placeholderImage } from "../../../BrandAssets/images";
import PaginationComponent from "../../CustomTable/Pagination";
import CustomModal from "../../CustomModal";
import { toast } from "react-toastify";

const AllUsers = () => {
  const [users, setUsers] = useState([]);
  const [isLoading, setIsLoading] = useState(true);
  const [error, setError] = useState(null);
  const navigate = useNavigate();

  const perPageValues = [10, 20, 50];
  const [itemsPerPage, setItemsPerPage] = useState(10);
  const [pagination, setPagination] = useState({
    currentPage: 1,
    totalCount: 0,
    next: null,
    previous: null,
  });

  const [deleteModal, setDeleteModal] = useState(false);
  const [userToDelete, setUserToDelete] = useState(null);
  const [isDeleteLoading, setIsDeleteLoading] = useState(false);
  const [confirmDelete, setConfirmDelete] = useState(false);

  const fetchUsers = async (page = 1) => {
    try {
      setIsLoading(true);

      const response = await makeRequest(`/end-users/?page=${page}`, "GET"); // Replace with actual API endpoint
      setUsers(response?.results || []);
      setPagination((prev) => ({
        ...prev,
        // totalCount: response?.count || 0,

        currentPage: page,
        totalCount: response?.count || 0,
        next: response?.next,
        previous: response?.previous,
      }));
    } catch (error) {
      console.error("Error fetching users:", error);
      setError("Failed to fetch users.");
    } finally {
      setIsLoading(false);
    }
  };

  useEffect(() => {
    fetchUsers(pagination.currentPage);
  }, [pagination.currentPage]);

  const deleteHandler = (user) => {
    setUserToDelete(user);
    setDeleteModal(true);
  };

  const onDelete = async () => {
    if (userToDelete) {
      try {
        setIsDeleteLoading(true);
        await makeRequest(`/users/${userToDelete?.id}/`, "DELETE");
        await fetchUsers();
        setConfirmDelete(true);
      } catch (error) {
        console.log("Error deleting user:", error);
        toast.error(error?.response?.data?.detail || "Failed to delete user.");
      } finally {
        setDeleteModal(false);
        setIsDeleteLoading(false);
      }
    }
  };

  const handlePageChange = (pageNumber) => {
    if (pageNumber > 0 && pageNumber <= Math.ceil(pagination.totalCount / 10)) {
      setPagination((prev) => ({
        ...prev,
        currentPage: pageNumber,
      }));
    }
  };
  const userHeaders = [
    { title: "#", sortable: false },
    { title: "Image", sortable: false },
    { title: "Username", sortable: true },
    { title: "Email", sortable: true },
    { title: "Phone", sortable: true },
    { title: "First Name", sortable: true },
    { title: "Last Name", sortable: true },
    { title: "Verified", sortable: true },
    { title: "Type", sortable: true },
    { title: "Address", sortable: false },
    { title: "Actions", sortable: false },
  ];

  return (
    <div className="col-12 data-table dashCard">
      {isLoading ? (
        <div className="text-center">
          <CircularProgress />
        </div>
      ) : error ? (
        <div className="text-danger text-center">{error}</div>
      ) : (
        <div>
          <CustomTable
            headers={userHeaders}
            dateFilter={false}
            perPage
            perPageValues={perPageValues}
            itemsPerPage={itemsPerPage}
            setItemsPerPage={setItemsPerPage}
            length={users.length}
          >
            <tbody>
              {users?.map((user, index) => (
                <tr key={user.id}>
                  <td>{index + 1}</td>
                  <td>
                    <img
                      src={user?.image || placeholderImage}
                      alt="User"
                      className="thumbnail"
                    />
                  </td>
                  <td>{user?.username || "N/A"}</td>
                  <td>{user?.email || "N/A"}</td>
                  <td>{user?.phone || "N/A"}</td>
                  <td>{user?.first_name || "N/A"}</td>
                  <td>{user?.last_name || "N/A"}</td>
                  <td>{user?.is_verified ? "Yes" : "No"}</td>
                  <td>
                    {(user?.user_type && user?.user_type.replace(/_/g, " ")) ||
                      "N/A"}
                  </td>
                  <td>{user?.address || "N/A"}</td>
                  <td style={{ cursor: "pointer" }}>
                    <FontAwesomeIcon
                      onClick={() =>
                        navigate(`/dashboard/users-management/edit/${user?.id}`)
                      }
                      icon={faEdit}
                      title="Edit"
                      className="tableActionIcon"
                    />
                    <FontAwesomeIcon
                      onClick={() => deleteHandler(user)}
                      icon={faTrash}
                      title="Delete"
                      className="tableActionIcon"
                    />
                  </td>
                </tr>
              ))}
            </tbody>
          </CustomTable>
        </div>
      )}
      <CustomModal
        loading={isDeleteLoading}
        show={deleteModal}
        close={() => setDeleteModal(false)}
        action={onDelete}
        heading="Are you sure you want to delete this user?"
      />
      <CustomModal
        show={confirmDelete}
        close={() => setConfirmDelete(false)}
        success
        heading="User Deleted Successfully"
      />
      <PaginationComponent
        pagination={pagination}
        handlePageChange={handlePageChange}
      />
    </div>
  );
};

export default AllUsers;
