import React, { useState, useEffect } from "react";
import {
  Container,
  Card,
  Form,
  Button,
  Row,
  Col,
  Image,
  Spinner,
} from "react-bootstrap";
import { FaPlus, FaTrash } from "react-icons/fa";
import BackButton from "../../../../BackButton";
import "./style.css";
import AddProductFormEng from "../../../../forms/addProductFormEng";
import AddProductFormAr from "../../../../forms/addProductFormAr";
import AddProductFormKr from "../../../../forms/addProductFormKr";
import { makeRequest } from "../../../../../api/axiosService";
import { toast } from "react-toastify";
import { useNavigate, useParams } from "react-router-dom";
import Spinner_component from "../../../../profile/Spinner";
import AddCommentFormEng from "../../../../forms/addCommentFormEng";
import AddCommentFormAr from "../../../../forms/addCommentFormAr";
import AddCommentFormKr from "../../../../forms/addCommentFormKr";

const getDefaultProductData = () => ({
  sku: "",
  name_en: "",
  name_ar: "",
  name_ku: "",
  comment_en: "",
  comment_ar: "",
  comment_ku: "",
  description_en: "",
  description_ar: "",
  description_ku: "",
  images: [],
  brand: "",
  category: "",
  subcategory: "",
  trending: false,
  best_selling: false,
  hot: false,
  featured: false,
  new_arrival: false,
  out_of_stock: false,
  active: false,
  taxonomy: 0,
  weight: 0,
  height: 0,
  width: 0,
  depth: 0,
  rating: 0,
  company: "",
  price_variations: [
    {
      scale: "",
      price: 0,
      basePrice: 0,
      profit: 0,
    },
  ],
  variants: [
    {
      name: "",
      options: [{ option: "", quantity: 0, image: [] }],
    },
  ],
  discount: {
    discount_percentage: 0,
    start_date: "",
    end_date: "",
  },
  quantity_discounts: [{ quantity: 0, discount: 0 }],
  stock: {
    quantity: 0,
    minHandlingDays: 0,
    maxHandlingDays: 0,
    warehouse: 0,
  },
});

const EditProductMainComp = () => {
  const { id } = useParams();
  const MAX_FILE_SIZE = 5 * 1024 * 1024; // 5 MB
  const [loading, setLoadinig] = useState(false);
  const user = JSON.parse(localStorage.getItem("user"));


  const [isFetching, setIsFetching] = useState(true);
  const [productData, setProductData] = useState(getDefaultProductData());

  const [brands, setBrands] = useState([]);
  const [categories, setCategories] = useState([]);
  const [subCategories, setSubCategories] = useState([]);
  const [companies, setCompanies] = useState([]);
  const [uploadImg, setUplaodImg] = useState(false);
  const [action, setAction] = useState(""); // Tracks the selected action: 'Accept' or 'Reject'
  const [comment, setComment] = useState(false); // Tracks the comment input


  const navigate = useNavigate();

  const setProductDataFromResponse = (response) => {
    const convertToDateTimeLocalFormat = (dateString) => {
      if (!dateString) return "";
      const date = new Date(dateString);
      return date.toISOString().slice(0, 16); // Format to 'YYYY-MM-DDTHH:mm'
    };
    // const selectedCategory = categories?.find(
    //   (cat) => cat.id.toString() === response?.category
    // );
    // const subcategories = selectedCategory?.subcategories || [];
    const defaultProductData = getDefaultProductData();
    const mergedData = {
      ...defaultProductData,
      ...response,
      price_variations:
        response?.price_variations || defaultProductData.price_variations,
      variants: response?.variants || defaultProductData.variants,
      // subcategory: response?.subcategory || defaultProductData.subcategory,

      discount: {
        ...defaultProductData.discount,
        ...response?.discount,
        start_date: convertToDateTimeLocalFormat(
          response?.discount?.start_date
        ),
        end_date: convertToDateTimeLocalFormat(response?.discount?.end_date),
      },

      quantity_discounts:
        response?.quantity_discounts || defaultProductData.quantity_discounts,
      stock: { ...defaultProductData.stock, ...response?.stock },
    };
    // setSubCategories(subcategories); // Set the subcategories for the selected category

    setProductData(mergedData);
  };

  const fetchProductData = async () => {
    try {
      const response = await makeRequest(`/products/${id}`, "GET");

      // Set state with response values or fall back to defaults
      setIsFetching(false);

      return response;
    } catch (error) {
      console.error("Error fetching product data:", error);
      toast.error("Failed to fetch product data. Please reload the page.");
      setIsFetching(false);
    }
  };

  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await fetchProductData(); // Assume this is an API call
        setProductDataFromResponse(response || {});
      } catch (error) {
        console.error("Failed to fetch product data:", error);
      }
    };

    fetchData();
  }, []);

  // const handleInputChange = (e) => {
  //   const { name, value } = e.target;
  //   setProductData((prevState) => ({
  //     ...prevState,
  //     [name]: value,
  //   }));
  // };
  const handleInputChange = (e) => {
    const { name, value } = e.target;

    setProductData((prevState) => ({
      ...prevState,
      [name]: value,
    }));

    // If the category is selected, set the subcategories
    if (name === "category") {
      const selectedCategory = categories?.find(
        (cat) => cat?.id.toString() === value
      );
      setSubCategories(selectedCategory?.subcategories || []);
    }
  };

  const handlePriceVariationChange = (index, field, value) => {
    const updatedprice_variations = [...productData.price_variations];
    updatedprice_variations[index][field] = value;
    setProductData((prevState) => ({
      ...prevState,
      price_variations: updatedprice_variations,
    }));
  };

  const handleAddPriceVariation = () => {
    setProductData((prevState) => ({
      ...prevState,
      price_variations: [
        ...prevState.price_variations,
        {
          scale: "",
          price: 0,
          basePrice: 0,
          // VAT: 0,
          // VATamount: 0,
          profit: 0,
          updatedAt: new Date().toISOString(),
        },
      ],
    }));
  };

  const handleRemovePriceVariation = (index) => {
    const updatedprice_variations = productData.price_variations.filter(
      (_, i) => i !== index
    );
    setProductData((prevState) => ({
      ...prevState,
      price_variations: updatedprice_variations,
    }));
  };

  const handleImageChange = async (e) => {
    const files = e.target.files; // Get all selected files

    if (files.length > 0) {
      for (const file of files) {
        if (file.size > MAX_FILE_SIZE) {
          toast.error(
            "File size is too large. Please upload files under 5 MB."
          );
          return;
        }
        try {
          setUplaodImg(true);
          // Prepare form data for the API request
          const formData = new FormData();
          formData.append("added_images", file);

          // Make the API call to upload the image
          const response = await makeRequest(
            "/upload-image/",
            "POST",
            formData
          );

          if (response?.uploaded_urls) {
            setProductData((prevState) => {
              const updatedImages = [
                ...prevState?.images,
                ...response?.uploaded_urls,
              ];
              return { ...prevState, images: updatedImages };
            });
          }
        } catch (error) {
          console.error("Error uploading image:", error);
        } finally {
          setUplaodImg(false);
        }
      }

      // Update the state with the new image paths
      // setProductData((prevState) => {
      //   const updatedImages = [...prevState.images, ...uploadedImagePaths];
      //   return { ...prevState, images: updatedImages };
      // });
    }
  };

  const handleRemoveImage = (index) => {
    setProductData((prevState) => {
      const updatedImages = prevState?.images.filter((_, i) => i !== index); // Remove the image at the given index
      return { ...prevState, images: updatedImages };
    });
  };

  const handleVariantImageChange = async (variantIndex, optionIndex, e) => {
    const file = e.target.files[0]; // Get the selected file
    const MAX_FILE_SIZE = 5 * 1024 * 1024; // 5 MB size limit

    if (file) {
      if (file.size > MAX_FILE_SIZE) {
        toast.error("File size is too large. Please upload files under 5 MB.");
        return;
      }

      try {
        setUplaodImg(true);

        // Prepare form data for the API request
        const formData = new FormData();
        formData.append("added_images", file);

        // Make the API call to upload the image
        const response = await makeRequest("/upload-image/", "POST", formData);

        if (response?.uploaded_urls) {
          const imageUrl = response.uploaded_urls[0]; // Get the uploaded image URL

          // Update the images array for the specific variant option
          setProductData((prevState) => {
            const updatedVariants = [...prevState.variants];
            const currentImages =
              updatedVariants[variantIndex].options[optionIndex].image || [];
            updatedVariants[variantIndex].options[optionIndex].image = [
              ...currentImages,
              imageUrl,
            ];
            return { ...prevState, variants: updatedVariants };
          });
        }
      } catch (error) {
        console.error("Error uploading variant image:", error);
      } finally {
        setUplaodImg(false);
      }
    }
  };

  const handleVariantChange = (variantIndex, optionIndex, field, value) => {
    setProductData((prevState) => {
      const updatedVariants = [...prevState.variants]; // Create a shallow copy of the variants
      if (field === "image") {
        updatedVariants[variantIndex].options[optionIndex].image = value;
      } else if (field === "name") {
        updatedVariants[variantIndex].name = value;
      } else if (field === "option" || field === "quantity") {
        updatedVariants[variantIndex].options[optionIndex][field] = value;
      }

      return { ...prevState, variants: updatedVariants };
    });
  };

  const handleAddVariant = () => {
    setProductData((prevState) => ({
      ...prevState,
      variants: [
        ...prevState.variants,
        { name: "", options: [{ option: "", quantity: 0, image: null }] },
      ],
    }));
  };

  const handleCheckboxChange = (e) => {
    const value = e.target.value;
    setAction(value);
    if (value === "Reject") {
      setComment(true);
    } else {
      setComment(false);

    }
  };

  const handleDraftCheckboxChange = (e) => {
    const isChecked = e.target.checked;
    if (isChecked) {
      setAction("underobservation"); // Set to "underobservation" when the checkbox is checked
    } else {
      setAction("save"); // Set to "save" when unchecked
    }
  };


  // const handleRemoveVariant = (variantIndex) => {
  //   const updatedVariants = productData.variants.filter(
  //     (_, i) => i !== variantIndex
  //   );
  //   setProductData((prevState) => ({
  //     ...prevState,
  //     variants: updatedVariants,
  //   }));
  // };


  const handleAddOption = (variantIndex) => {
    setProductData((prevState) => {
      const updatedVariants = [...prevState.variants];

      // Ensure that the variant has an 'options' array before adding an option
      if (!updatedVariants[variantIndex].options) {
        updatedVariants[variantIndex].options = [];
      }

      updatedVariants[variantIndex].options.push({
        option: "", // Default option name
        quantity: 0, // Default quantity
        image: null, // Default image (null)
      });

      return { ...prevState, variants: updatedVariants };
    });
  };

  const handleRemoveOption = (variantIndex, optionIndex) => {
    setProductData((prevState) => {
      const updatedVariants = [...prevState.variants];

      // Remove the option at the specified index
      updatedVariants[variantIndex].options = updatedVariants[
        variantIndex
      ].options.filter((_, index) => index !== optionIndex);

      return { ...prevState, variants: updatedVariants };
    });
  };

  const fetchBrands = async () => {
    try {
      // setIsLoading(true);
      const resp = await makeRequest("/brands/", "GET");
      setBrands(resp?.results || []);
    } catch (error) {
      console.error("Error fetching brands:", error);
    } finally {
      // setIsLoading(false);
    }
  };

  useEffect(() => {
    if (productData?.category !== "") {
      let re = categories?.find((item) => item.id == productData?.category);
      setSubCategories(re?.subcategories);
    }
  }, [productData?.category, categories]);

  const fetchCategories = async () => {
    try {
      //  setIsLoading(true);
      const resp = await makeRequest("/category/", "GET");
      setCategories(resp?.results || []);

      // setSubCategories(

      // );
    } catch (error) {
      console.error("Error fetching categories:", error);
    } finally {
      //  setIsLoading(false);
    }
  };

  const fetchCompany = async () => {
    try {
      //  setIsLoading(true);
      const resp = await makeRequest("/company/", "GET");
      setCompanies(resp?.results || []);
    } catch (error) {
      console.error("Error fetching companies:", error);
    } finally {
      //  setIsLoading(false);
    }
  };
  // Mock fetching dropdown data (Brand, Category, Company)
  useEffect(() => {
    fetchBrands();
    fetchCategories();
    fetchCompany();
  }, []);

  const appendData = (obj, formData, prefix = "") => {
    // If obj is an array, process each item with an index
    if (Array.isArray(obj)) {
      obj.forEach((item, index) => {
        // If item is a File, append it as a file
        if (item instanceof File) {
          // formData.append(`${prefix}[${index}]`, item);
        } else {
          // Otherwise, recurse into the item (nested object)
        }
        appendData(item, formData, `${prefix}[${index}]`);
      });
    }
    // If obj is an object (not an array), process each key-value pair
    else if (typeof obj === "object" && obj !== null) {
      Object.keys(obj).forEach((key) => {
        const value = obj[key];

        // Recursively call appendData for nested objects
        if (typeof value === "object" && value !== null) {
          appendData(value, formData, `${prefix}${key}[`);
        } else {
          // For primitive values (strings, numbers, etc.), append directly
          formData.append(`${prefix}${key}`, value);
        }
      });
    }
    // If obj is not an array or object, just append it (for primitive types)
    else {
      formData.append(prefix, obj);
    }
  };

  const handleOptionChange = (variantIndex, optionIndex, field, value) => {
    setProductData((prevState) => {
      const updatedVariants = [...prevState.variants];

      // Ensure the variant and options exist before trying to update them
      if (
        updatedVariants[variantIndex] &&
        updatedVariants[variantIndex].options[optionIndex]
      ) {
        updatedVariants[variantIndex].options[optionIndex][field] = value;
      }

      return { ...prevState, variants: updatedVariants };
    });
  };
  // Function to handle updates to the discount object
  const handleDiscountChange = (field, value) => {
    setProductData((prevState) => ({
      ...prevState,
      discount: {
        ...prevState.discount,
        [field]: value,
      },
    }));
  };

  // Function to handle updates to a specific quantity discount
  const handleQuantityDiscountChange = (index, field, value) => {
    setProductData((prevState) => {
      const updatedQuantityDiscounts = [...prevState.quantity_discounts];
      updatedQuantityDiscounts[index][field] = value;
      return { ...prevState, quantity_discounts: updatedQuantityDiscounts };
    });
  };

  // Function to add a new quantity discount
  const handleAddQuantityDiscount = () => {
    setProductData((prevState) => ({
      ...prevState,
      quantity_discounts: [
        ...prevState.quantity_discounts,
        { quantity: 0, discount: 0 },
      ],
    }));
  };

  // Function to remove a specific quantity discount
  const handleRemoveQuantityDiscount = (index) => {
    setProductData((prevState) => ({
      ...prevState,
      quantity_discounts: prevState.quantity_discounts.filter(
        (_, i) => i !== index
      ),
    }));
  };

  // Function to handle updates to the stock object
  const handleStockChange = (field, value) => {
    setProductData((prevState) => ({
      ...prevState,
      stock: {
        ...prevState.stock,
        [field]: value,
      },
    }));
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    const formData = new FormData();

    appendData(productData, formData);
    try {
      setLoadinig(true);
      await makeRequest(`/products/${id}/`, "PUT", { ...productData, status: action.toLocaleLowerCase() });
      toast.success("Product updated successfully");
      navigate("/dashboard/product-management");
    } catch (error) {
      if (error?.response?.data?.detail) {
        toast.error(`Error: ${error.response.data.detail}`, {
          position: "top-right",
          autoClose: 5000,
        });
      } else if (error?.response?.data) {
        const errors = error.response.data;

        Object.keys(errors).forEach((key) => {
          const formattedKey = key
            .replace(/_/g, " ")
            .replace(/\b\w/g, (char) => char.toUpperCase());

          const errorMessages = errors[key];

          if (Array.isArray(errorMessages) && errorMessages.length > 0) {
            errorMessages.forEach((msg) => {
              toast.error(`${formattedKey}: ${msg}`, {
                position: "top-right",
                autoClose: 5000,
              });
            });
          } else {
            toast.error("Something went wrong", {
              position: "top-right",
              autoClose: 5000,
            });
          }
        });
      } else {
        toast.error("An unexpected error occurred", {
          position: "top-right",
          autoClose: 5000,
        });
      }
    } finally {
      setLoadinig(false);
    }
  };

  return (
    <Container className="mt-4">
      <div className="d-flex mb-2">
        <BackButton />
        <h4>Edit Product</h4>
      </div>
      {isFetching ? (
        <Spinner_component />
      ) : (
        <Card className="shadow-lg">
          <Card.Body>
            <Form onSubmit={handleSubmit}>
              {/* Product information in english */}
              <AddProductFormEng
                productData={productData}
                handleInputChange={handleInputChange}
              />
              {/* Product information in english */}
              {/* Product information in arabic */}
              <AddProductFormAr
                productData={productData}
                handleInputChange={handleInputChange}
              />
              {/* Product information in arabic */}

              {/* Product information in kurdish */}
              <AddProductFormKr
                productData={productData}
                handleInputChange={handleInputChange}
              />
              {/* Product information in kurdish */}

              <Row>
                <Col md={3} sm={12}>
                  <Form.Group controlId="formTrending">
                    <Form.Check
                      type="checkbox"
                      label="Trending"
                      name="trending"
                      checked={productData.trending}
                      onChange={(e) =>
                        setProductData((prevState) => ({
                          ...prevState,
                          trending: e.target.checked, // Directly set the value to true or false
                        }))
                      }
                      className="checkbox-left-margin"
                    />
                  </Form.Group>
                </Col>
                <Col md={3} sm={12}>
                  <Form.Group controlId="formHot">
                    <Form.Check
                      type="checkbox"
                      label="Hot"
                      name="hot"
                      checked={productData.hot}
                      onChange={(e) =>
                        setProductData((prevState) => ({
                          ...prevState,
                          hot: e.target.checked, // Directly set the value to true or false
                        }))
                      }
                      className="checkbox-left-margin"
                    />
                  </Form.Group>
                </Col>
                <Col md={3} sm={12}>
                  <Form.Group controlId="formFeatured">
                    <Form.Check
                      type="checkbox"
                      label="Featured"
                      name="featured"
                      checked={productData.featured}
                      onChange={(e) =>
                        setProductData((prevState) => ({
                          ...prevState,
                          featured: e.target.checked, // Directly set the value to true or false
                        }))
                      }
                      className="checkbox-left-margin"
                    />
                  </Form.Group>
                </Col>
                <Col md={3} sm={12}>
                  <Form.Group controlId="formBestSelling">
                    <Form.Check
                      type="checkbox"
                      label="Best Selling"
                      name="best_selling"
                      checked={productData.best_selling}
                      onChange={(e) =>
                        setProductData((prevState) => ({
                          ...prevState,
                          best_selling: e.target.checked, // Directly set the value to true or false
                        }))
                      }
                      className="checkbox-left-margin"
                    />
                  </Form.Group>
                </Col>
                <Col md={3} sm={12}>
                  <Form.Group controlId="formNewArrival">
                    <Form.Check
                      type="checkbox"
                      label="New Arrival"
                      name="new_arrival"
                      checked={productData.new_arrival}
                      onChange={(e) =>
                        setProductData((prevState) => ({
                          ...prevState,
                          new_arrival: e.target.checked, // Directly set the value to true or false
                        }))
                      }
                      className="checkbox-left-margin"
                    />
                  </Form.Group>
                </Col>
                <Col md={3} sm={12}>
                  <Form.Group controlId="formOutOfStock">
                    <Form.Check
                      type="checkbox"
                      label="Out of stock"
                      name="out_of_stock"
                      checked={productData.out_of_stock}
                      onChange={(e) =>
                        setProductData((prevState) => ({
                          ...prevState,
                          out_of_stock: e.target.checked, // Directly set the value to true or false
                        }))
                      }
                      className="checkbox-left-margin"
                    />
                  </Form.Group>
                </Col>
                <Col md={3} sm={12}>
                  <Form.Group controlId="formActive">
                    <Form.Check
                      type="checkbox"
                      label="Active"
                      name="active"
                      checked={productData.active}
                      onChange={(e) =>
                        setProductData((prevState) => ({
                          ...prevState,
                          active: e.target.checked, // Directly set the value to true or false
                        }))
                      }
                      className="checkbox-left-margin"
                    />
                  </Form.Group>
                </Col>
              </Row>

              <Row className="mb-2">
                <Col md={6} sm={12}>
                  <Form.Group controlId="formCategory">
                    <Form.Label>Category</Form.Label>
                    <Form.Control
                      as="select"
                      name="category"
                      value={productData.category}
                      onChange={handleInputChange}
                      style={{
                        border: "1px solid #ebedf2",
                        padding: "15px 15px",
                      }}
                    // required
                    >
                      <option value="">Select Category</option>
                      {categories?.map((category) => (
                        <option key={category.id} value={category.id}>
                          {category.name_en} / {category.name_ar} /{" "}
                          {category.name_ku}
                        </option>
                      ))}
                    </Form.Control>
                  </Form.Group>
                </Col>

                <Col md={6} sm={12}>
                  <Form.Group controlId="formSubCategory">
                    <Form.Label>Subcategory</Form.Label>
                    <Form.Control
                      as="select"
                      name="subcategory"
                      value={productData?.subcategory || ""}
                      onChange={handleInputChange}
                      style={{
                        border: "1px solid #ebedf2",
                        padding: "15px 15px",
                      }}
                    // disabled={subCategories?.length === 0}
                    >
                      <option value="">Select Subcategory</option>
                      {subCategories?.map((subcategory) => (
                        <option key={subcategory.id} value={subcategory.id}>
                          {subcategory.name_en} / {subcategory.name_ar} /{" "}
                          {subcategory.name_ku}
                        </option>
                      ))}
                    </Form.Control>
                  </Form.Group>
                </Col>
              </Row>

              <Row>
                <Col md={6} sm={12}>
                  <Form.Group controlId="formBrand">
                    <Form.Label>Brand</Form.Label>
                    <Form.Control
                      as="select"
                      name="brand"
                      value={productData.brand}
                      onChange={handleInputChange}
                      style={{
                        border: "1px solid #ebedf2",
                        padding: "15px 15px",
                      }}
                    // required
                    >
                      <option value="">Select Brand</option>
                      {brands?.map((brand) => (
                        <option key={brand.id} value={brand.id}>
                          {brand.name_en} / {brand.name_ar} / {brand.name_ku}
                        </option>
                      ))}
                    </Form.Control>
                  </Form.Group>
                </Col>

                <Col md={6} sm={12}>
                  <Form.Group controlId="formCompany">
                    <Form.Label>Company</Form.Label>
                    <Form.Control
                      as="select"
                      name="company"
                      value={productData.company}
                      onChange={handleInputChange}
                      style={{
                        border: "1px solid #ebedf2",
                        padding: "15px 15px",
                      }}
                    // required
                    >
                      <option value="">Select Company</option>
                      {companies?.map((company) => (
                        <option key={company.id} value={company.id}>
                          {company.name_en} / {company.name_ar} /{" "}
                          {company.name_ku}
                        </option>
                      ))}
                    </Form.Control>
                  </Form.Group>
                </Col>
              </Row>

              <Row className="mt-3">
                <Col md={6} sm={12}>
                  <Form.Group controlId="formProductSKU">
                    <Form.Label>SKU</Form.Label>
                    <Form.Control
                      type="text"
                      name="sku"
                      value={productData.sku}
                      onChange={handleInputChange}
                      style={{
                        border: "1px solid #ebedf2",
                        padding: "15px 15px",
                      }}
                      placeholder="Enter SKU"
                    // required
                    />
                  </Form.Group>
                </Col>
                <Col md={6} sm={12}>
                  <Form.Group controlId="taxonomy">
                    <Form.Label>Taxonomy</Form.Label>
                    <Form.Control
                      type="text"
                      name="taxonomy"
                      value={productData.taxonomy}
                      onChange={handleInputChange}
                      style={{
                        border: "1px solid #ebedf2",
                        padding: "15px 15px",
                      }}
                      placeholder="Enter Taxonomy"
                    // required
                    />
                  </Form.Group>
                </Col>
              </Row>

              <Row className="mt-3">
                <Col md={6} sm={12}>
                  <Form.Group controlId="weight">
                    <Form.Label>Weight</Form.Label>
                    <Form.Control
                      type="text"
                      name="weight"
                      value={productData.weight}
                      onChange={handleInputChange}
                      style={{
                        border: "1px solid #ebedf2",
                        padding: "15px 15px",
                      }}
                      placeholder="Enter Weight"
                    // required
                    />
                  </Form.Group>
                </Col>
                <Col md={6} sm={12}>
                  <Form.Group controlId="height">
                    <Form.Label>Height</Form.Label>
                    <Form.Control
                      type="text"
                      name="height"
                      value={productData.height}
                      onChange={handleInputChange}
                      style={{
                        border: "1px solid #ebedf2",
                        padding: "15px 15px",
                      }}
                      placeholder="Enter Height"
                    // required
                    />
                  </Form.Group>
                </Col>
              </Row>
              <Row className="mt-3">
                <Col md={6} sm={12}>
                  <Form.Group controlId="width">
                    <Form.Label>Width</Form.Label>
                    <Form.Control
                      type="text"
                      name="width"
                      value={productData.width}
                      onChange={handleInputChange}
                      style={{
                        border: "1px solid #ebedf2",
                        padding: "15px 15px",
                      }}
                      placeholder="Enter Width"
                    // required
                    />
                  </Form.Group>
                </Col>
                <Col md={6} sm={12}>
                  <Form.Group controlId="depth">
                    <Form.Label>Depth</Form.Label>
                    <Form.Control
                      type="text"
                      name="depth"
                      value={productData.depth}
                      onChange={handleInputChange}
                      style={{
                        border: "1px solid #ebedf2",
                        padding: "15px 15px",
                      }}
                      placeholder="Enter Depth"
                    // required
                    />
                  </Form.Group>
                </Col>
              </Row>

              <Row className="mt-3">
                <div>
                  <h5>Variants</h5>
                  {productData?.variants?.map((variant, variantIndex) => (
                    <div key={variantIndex} className="mb-3">
                      <Row className="mb-3">
                        <Col sm={12}>
                          <Form.Group
                            controlId={`formVariantName-${variantIndex}`}
                          >
                            <Form.Label>Variant Name</Form.Label>
                            <Form.Control
                              type="text"
                              value={variant.name}
                              onChange={(e) =>
                                handleVariantChange(
                                  variantIndex,
                                  null,
                                  "name",
                                  e.target.value
                                )
                              }
                              placeholder="Enter variant name (e.g., Color, Size)"
                            />
                          </Form.Group>
                        </Col>

                        {/* Render Options for this Variant */}
                        {variant?.options?.map((option, optionIndex) => (
                          <div key={optionIndex} className="mb-3">
                            <Row className="mt-3">
                              <Col md={6} sm={12}>
                                <Form.Group
                                  controlId={`formOption-${variantIndex}-${optionIndex}`}
                                >
                                  <Form.Label>Option</Form.Label>
                                  <Form.Control
                                    type="text"
                                    value={option.option}
                                    onChange={(e) =>
                                      handleOptionChange(
                                        variantIndex,
                                        optionIndex,
                                        "option",
                                        e.target.value
                                      )
                                    }
                                    placeholder="Option (e.g., Red, Small)"
                                  />
                                </Form.Group>
                              </Col>

                              <Col md={6} sm={12}>
                                <Form.Group
                                  controlId={`formQuantity-${variantIndex}-${optionIndex}`}
                                >
                                  <Form.Label>Quantity</Form.Label>
                                  <Form.Control
                                    type="number"
                                    value={option.quantity}
                                    onChange={(e) =>
                                      handleOptionChange(
                                        variantIndex,
                                        optionIndex,
                                        "quantity",
                                        e.target.value
                                      )
                                    }
                                    placeholder="Quantity"
                                  />
                                </Form.Group>
                              </Col>
                            </Row>
                            <Row className="mt-3">
                              <Col md={11} sm={8}>
                                <Form.Group
                                  controlId={`formImage-${variantIndex}-${optionIndex}`}
                                >
                                  <Form.Label>Image</Form.Label>

                                  <Form.Control
                                    type="file"
                                    accept="image/*"
                                    onChange={(e) =>
                                      handleVariantImageChange(
                                        variantIndex,
                                        optionIndex,
                                        e
                                      )
                                    }
                                  />
                                  <div className="image-preview-container mt-3">
                                    <Image
                                      src={
                                        Array.isArray(option.image) &&
                                          option.image.length > 0
                                          ? option.image[0]
                                          : null
                                      }
                                      thumbnail
                                      className="image-preview"
                                    />
                                  </div>
                                </Form.Group>
                              </Col>

                              <Col md={1} sm={4}>
                                <Button
                                  variant="danger"
                                  onClick={() =>
                                    handleRemoveOption(
                                      variantIndex,
                                      optionIndex
                                    )
                                  }
                                  size="sm"
                                  style={{ marginTop: "35px" }}
                                >
                                  <FaTrash />
                                </Button>
                              </Col>
                            </Row>
                          </div>
                        ))}
                      </Row>
                      <Row>
                        <Col sm={12} className="d-flex justify-content-end">
                          <Button
                            size="sm"
                            variant="outline-primary"
                            onClick={() => handleAddOption(variantIndex)}
                          >
                            <FaPlus /> Add Option
                          </Button>
                        </Col>
                      </Row>
                    </div>
                  ))}

                  <Button
                    size="sm"
                    variant="outline-primary"
                    onClick={handleAddVariant}
                  >
                    <FaPlus /> Add Variant
                  </Button>
                </div>
                <div className="mt-3">
                  <h5>Price Variations</h5>
                  {productData?.price_variations?.map((variation, index) => (
                    <div key={index} className="mb-3">
                      <Row>
                        <Col lg={5} md={12} sm={12}>
                          <Form.Group controlId={`formScale-${index}`}>
                            <Form.Label>Scale</Form.Label>
                            <Form.Control
                              type="text"
                              value={variation.scale}
                              onChange={(e) =>
                                handlePriceVariationChange(
                                  index,
                                  "scale",
                                  e.target.value
                                )
                              }
                              placeholder="Scale (e.g., small, medium)"
                            />
                          </Form.Group>
                        </Col>
                        <Col lg={2} md={12} sm={12}>
                          <Form.Group controlId={`formPrice-${index}`}>
                            <Form.Label>Price</Form.Label>
                            <Form.Control
                              type="number"
                              value={variation.price}
                              onChange={(e) =>
                                handlePriceVariationChange(
                                  index,
                                  "price",
                                  e.target.value
                                )
                              }
                              placeholder="Price"
                            />
                          </Form.Group>
                        </Col>
                        <Col lg={2} md={12} sm={12}>
                          <Form.Group controlId={`formBasePrice-${index}`}>
                            <Form.Label>Base Price</Form.Label>
                            <Form.Control
                              type="number"
                              value={variation.basePrice}
                              onChange={(e) =>
                                handlePriceVariationChange(
                                  index,
                                  "basePrice",
                                  e.target.value
                                )
                              }
                              placeholder="Base Price"
                            />
                          </Form.Group>
                        </Col>
                        {/* <Col md={2}>
                        <Form.Group controlId={`formVAT-${index}`}>
                          <Form.Label>VAT</Form.Label>
                          <Form.Control
                            type="number"
                            value={variation.VAT}
                            onChange={(e) =>
                              handlePriceVariationChange(
                                index,
                                "VAT",
                                e.target.value
                              )
                            }
                            placeholder="VAT"
                          />
                        </Form.Group>
                      </Col> */}
                        <Col lg={2} md={12} sm={12}>
                          <Form.Group controlId={`formProfit-${index}`}>
                            <Form.Label>Profit</Form.Label>
                            <Form.Control
                              type="number"
                              value={variation.profit}
                              onChange={(e) =>
                                handlePriceVariationChange(
                                  index,
                                  "profit",
                                  e.target.value
                                )
                              }
                              placeholder="Profit"
                            />
                          </Form.Group>
                        </Col>
                        <Col lg={1} md={12} sm={12}>
                          <Button
                            variant="danger"
                            onClick={() => handleRemovePriceVariation(index)}
                            size="sm"
                            style={{ marginTop: "35px" }}
                          >
                            <FaTrash />
                          </Button>
                        </Col>
                      </Row>
                    </div>
                  ))}
                  <Button
                    size="sm"
                    variant="outline-danger"
                    onClick={handleAddPriceVariation}
                  >
                    <FaPlus /> Price Variation
                  </Button>
                </div>
                {/* Image Upload */}
                <Row className="mb-3">
                  <Col sm={12}>
                    <Form.Group controlId="formImages">
                      <Form.Label className="mt-3">Product Images</Form.Label>
                      <Form.Control
                        type="file"
                        accept="image/*"
                        onChange={handleImageChange}
                        multiple
                      />
                      <div className="mt-2">
                        {uploadImg ? (
                          <Spinner_component />
                        ) : (
                          productData?.images?.map((image, index) => (
                            <div
                              key={index}
                              className="image-preview-container mt-2"
                            >
                              <Image
                                src={image}
                                thumbnail
                                className="image-preview"
                              />
                              <div className="delete-button-container">
                                <Button
                                  variant="danger"
                                  onClick={() => handleRemoveImage(index)}
                                  size="sm"
                                >
                                  <FaTrash /> Delete
                                </Button>
                              </div>
                            </div>
                          ))
                        )}
                        {/* {} */}
                      </div>
                    </Form.Group>
                  </Col>
                </Row>{" "}
              </Row>
              {/* zaka */}

              <h5>Discount</h5>
              <Form.Group controlId="formDiscountPercentage">
                <Form.Label>Discount Percentage</Form.Label>
                <Form.Control
                  type="number"
                  value={productData.discount.discount_percentage}
                  onChange={(e) =>
                    handleDiscountChange("discount_percentage", e.target.value)
                  }
                  placeholder="Enter discount percentage"
                />
              </Form.Group>

              <Row className="mt-3">
                <Col md={6} sm={12}>
                  <Form.Group controlId="formDiscountStartDate">
                    <Form.Label>Discount Start Date</Form.Label>
                    <Form.Control
                      type="datetime-local"
                      value={productData.discount.start_date}
                      onChange={(e) =>
                        handleDiscountChange("start_date", e.target.value)
                      }
                    />
                  </Form.Group>
                </Col>
                <Col md={6} sm={12}>
                  <Form.Group controlId="formDiscountEndDate">
                    <Form.Label>Discount End Date</Form.Label>
                    <Form.Control
                      type="datetime-local"
                      value={productData.discount.end_date}
                      onChange={(e) =>
                        handleDiscountChange("end_date", e.target.value)
                      }
                    />
                  </Form.Group>
                </Col>
              </Row>

              <h5 className="mt-3">Quantity Discounts</h5>
              {productData.quantity_discounts.map((qd, index) => (
                <Row key={index} className="mb-3">
                  <Col md={5} sm={12}>
                    <Form.Group controlId={`formQuantity-${index}`}>
                      <Form.Label>Quantity</Form.Label>
                      <Form.Control
                        type="number"
                        value={qd.quantity}
                        onChange={(e) =>
                          handleQuantityDiscountChange(
                            index,
                            "quantity",
                            e.target.value
                          )
                        }
                        placeholder="Enter quantity"
                      />
                    </Form.Group>
                  </Col>
                  <Col md={5} sm={10}>
                    <Form.Group controlId={`formDiscount-${index}`}>
                      <Form.Label>Discount</Form.Label>
                      <Form.Control
                        type="number"
                        value={qd.discount}
                        onChange={(e) =>
                          handleQuantityDiscountChange(
                            index,
                            "discount",
                            e.target.value
                          )
                        }
                        placeholder="Enter discount percentage"
                      />
                    </Form.Group>
                  </Col>
                  <Col md={2} sm={2} className="d-flex align-items-end mb-2">
                    <Button
                      variant="danger"
                      onClick={() => handleRemoveQuantityDiscount(index)}
                      size="sm"
                    >
                      <FaTrash />
                    </Button>
                  </Col>
                </Row>
              ))}

              {/* <Button variant="primary" onClick={handleAddQuantityDiscount}>
              Add Quantity Discount
            </Button> */}

              <Button
                size="sm"
                variant="outline-primary"
                onClick={handleAddQuantityDiscount}
              >
                <FaPlus /> Quantity Discount
              </Button>

              <h5 className="mt-3">Stock</h5>
              <Form.Group controlId="formStockQuantity">
                <Form.Label>Stock Quantity</Form.Label>
                <Form.Control
                  type="number"
                  value={productData.stock.quantity}
                  onChange={(e) =>
                    handleStockChange("quantity", e.target.value)
                  }
                  placeholder="Enter stock quantity"
                />
              </Form.Group>

              <Row className="mt-3">
                <Col md={6} sm={12}>
                  <Form.Group controlId="formMinHandlingDays">
                    <Form.Label>Min Handling Days</Form.Label>
                    <Form.Control
                      type="number"
                      value={productData.stock.minHandlingDays}
                      onChange={(e) =>
                        handleStockChange("minHandlingDays", e.target.value)
                      }
                      placeholder="Enter minimum handling days"
                    />
                  </Form.Group>
                </Col>
                <Col md={6} sm={12}>
                  <Form.Group controlId="formMaxHandlingDays">
                    <Form.Label>Max Handling Days</Form.Label>
                    <Form.Control
                      type="number"
                      value={productData.stock.maxHandlingDays}
                      onChange={(e) =>
                        handleStockChange("maxHandlingDays", e.target.value)
                      }
                      placeholder="Enter maximum handling days"
                    />
                  </Form.Group>
                </Col>
              </Row>

              <Row className="mt-3">
                <Form.Group controlId="formWarehouse">
                  <Form.Label>Warehouse ID</Form.Label>
                  <Form.Control
                    type="number"
                    value={productData.stock.warehouse}
                    onChange={(e) =>
                      handleStockChange("warehouse", e.target.value)
                    }
                    placeholder="Enter warehouse ID"
                  />
                </Form.Group>
              </Row>
              {comment && <Row className="mt-3">
                <Col sm={12}>
                  <AddCommentFormEng
                    productData={productData}
                    handleInputChange={handleInputChange}
                  />
                </Col>

                <Col sm={12}>
                  <AddCommentFormAr
                    productData={productData}
                    handleInputChange={handleInputChange}
                  />
                </Col>

                <Col sm={12}>
                  <AddCommentFormKr
                    productData={productData}
                    handleInputChange={handleInputChange}
                  />
                </Col>
              </Row>}

              {(user?.user_type === "ADMIN_USER" || user?.user_type === "SUPER_USER") && <div className="mt-3  d-flex justify-content-around">
                <div className="form-check">
                  <input
                    className="form-check-input"
                    type="checkbox"
                    id="accept"
                    value="Accept"
                    checked={action === "Accept"}
                    onChange={handleCheckboxChange}
                    disabled={loading}
                  />
                  <label className="form-check-label" htmlFor="accept">
                    Accept
                  </label>
                </div>
                <div className="form-check">
                  <input
                    className="form-check-input"
                    type="checkbox"
                    id="reject"
                    value="Reject"
                    checked={action === "Reject"}
                    onChange={handleCheckboxChange}
                    disabled={loading}
                  />
                  <label className="form-check-label" htmlFor="reject">
                    Reject
                  </label>
                </div>

              </div>}

              {productData?.status === "save" && (
                <div className="mt-3 mx-4 d-flex justify-content-start">
                  <div className="form-check">
                    <input
                      className="form-check-input"
                      type="checkbox"
                      id="save"
                      value="save"
                      checked={action === "underobservation"} // Checkbox is checked if action is "underobservation"
                      onChange={handleDraftCheckboxChange} // Toggles state on change
                      disabled={loading} // Disables interaction when loading
                    />
                    <label className="form-check-label" htmlFor="save">
                      Ready for submission
                    </label>
                  </div>
                </div>
              )}


              <Button
                disabled={loading}
                variant="success"
                type="submit"
                className="mt-3"
              >
                {loading ? <Spinner animation="border" size="sm" /> : "Submit"}
              </Button>

            </Form>
          </Card.Body>
        </Card>
      )}
    </Container>
  );
};

export default EditProductMainComp;
