import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router";
// import { DashboardLayout } from "../../Components/Layout/DashboardLayout";
import { Container, Card, Col, Spinner, Button } from "react-bootstrap";
import ReactQuill from "react-quill";
import "react-quill/dist/quill.snow.css";
import { toast } from "react-toastify";

const FooterTemplateMainComp = () => {
  const navigate = useNavigate();

  // Fake data for testing
  const emailData = {
    data: [
      {
        _id: "fakeId123",
        signupTemplate: "<p>Welcome to our service!</p>",
        orderConfirmationTemplate: "<p>Your order is confirmed.</p>",
        footerTemplate: "<p>Thank you for choosing us!</p>",
        updatedAt: new Date(),
      },
    ],
  };

  const isLoading = false;
  const error = null;

  const email = emailData?.data?.[0];
  const footerTemplate = email?.footerTemplate;

  // Content state for the editor
  const [content, setContent] = useState("");

  useEffect(() => {
    if (footerTemplate) {
      setContent(footerTemplate);
    }
  }, [footerTemplate]);

  // Handle editor changes
  const handleEditorChange = (value) => {
    setContent(value);
  };

  const handleSave = () => {
    if (email?._id && content) {
      toast.success("Changes saved successfully!");
      console.log("Saved Content:", content);
    }
  };

  const handleBack = () => {
    navigate(-1);
  };

  return (
    <Container>
      <Button onClick={handleBack}>Go Back</Button>
      <Col md={8} className="mt-2 mx-auto">
        <Card>
          <Card.Header>Edit and Preview Email Content</Card.Header>
          <Card.Body>
            {isLoading ? (
              <Spinner animation="border" />
            ) : error ? (
              <p>Error loading data</p>
            ) : (
              <>
                <ReactQuill
                  value={content}
                  onChange={handleEditorChange}
                  theme="snow"
                  modules={{
                    toolbar: [
                      [{ header: "1" }, { header: "2" }],
                      ["bold", "italic", "underline"],
                      [{ list: "ordered" }, { list: "bullet" }],
                      ["link"],
                      ["clean"],
                    ],
                  }}
                />
                <hr />
                <h5>Preview:</h5>
                <div
                  style={{
                    fontFamily: "Arial, sans-serif",
                    color: "#333",
                    maxWidth: "500px",
                    margin: "auto",
                    padding: "20px",
                    border: "1px solid #ddd",
                    borderRadius: "8px",
                  }}
                  dangerouslySetInnerHTML={{
                    __html: `
                                                <p>${
                                                  email?.orderConfirmationTemplate ||
                                                  "No Order Template Found"
                                                }</p>
                                                <footer>${content}</footer>
                                            `,
                  }}
                />
              </>
            )}
          </Card.Body>
          {email && (
            <Card.Footer>
              <small className="text-muted">
                Updated: {new Date(email.updatedAt).toLocaleDateString()}
              </small>
            </Card.Footer>
          )}
        </Card>
        <Button variant="primary" onClick={handleSave} className="mt-3">
          Save Changes
        </Button>
      </Col>
    </Container>
  );
};

export default FooterTemplateMainComp;
