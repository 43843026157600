/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from "react";
import axios from "axios";
import { Link } from "react-router-dom";
import CircularProgress from "@mui/material/CircularProgress";
// import PaginationComponent from "../PaginationComponent";
import { toast } from "react-toastify";
import DeleteConfirmationModel from "../deleteConfirmationModel";
import { Rating } from "@mui/material";
import PaginationComponent from "../../CustomTable/Pagination";

import { makeRequest } from "../../../api/axiosService";
import CustomModal from "../../CustomModal";

const CompanyCards = () => {
  const userType = localStorage.getItem("user_type");
  const [IsLoading, setIsLoading] = useState(false);
  const [companyData, setCompanyData] = useState([]);
  const [currentPage, setCurrentPage] = useState(1);
  const [update, setupdate] = useState(false);
  const isSuperAdmin = false;
  // const handlePageChange = (pageNumber) => {
  //   setCurrentPage(pageNumber);
  // };
  const [showModel, setShowModel] = useState({
    show: false,
    id: null,
  });
  const [isDeleteLoading, setIsDeleteLoading] = useState(false);
  const [deleteModal, setDeleteModal] = useState(false);
  const [confirmDelete, setConfirmDelete] = useState(false);
  const [companyToDelete, setComapnyToDelete] = useState(null);

  const [pagination, setPagination] = useState({
    currentPage: 1,
    totalCount: 0,
    next: null,
    previous: null,
  });

  const handlePageChange = (pageNumber) => {
    if (pageNumber > 0 && pageNumber <= Math.ceil(pagination.totalCount / 10)) {
      setPagination((prev) => ({
        ...prev,
        currentPage: pageNumber,
      }));
    }
  };

  const handleShowModel = (id) => {
    setShowModel({
      show: true,
      id: id,
    });
  };
  const handleCloseModel = () => {
    setShowModel({
      show: false,
      id: null,
    });
  };

  const accessToken = localStorage.getItem("Key");
  const handleGetCompanyData = async () => {
    setIsLoading(true);
    try {
      // if (userType === "ADMIN_USER") {
      const resp = await makeRequest("/company/", "GET");

      setCompanyData(resp?.results);
      // }
    } catch (error) {
      console.log(error);
    }
    setIsLoading(false);
  };

  useEffect(() => {
    handleGetCompanyData();
  }, [update, currentPage]);

  // const handleDeleteCompany = async (id) => {
  //   try {
  //     setIsDeleteLoading(true);
  //     await makeRequest(`/company/${id}/`, "DELETE");
  //     handleGetCompanyData;
  //     setConfirmDelete(true);
  //   } catch (error) {
  //     console.error("Error deleting brand:", error);
  //   } finally {
  //     setDeleteModal(false);
  //     setIsDeleteLoading(false);
  //   }
  // };

  const onDelete = async () => {
    try {
      setIsDeleteLoading(true);
      await makeRequest(`/company/${companyToDelete?.id}/`, "DELETE");
      await handleGetCompanyData();
      // setConfirmDelete(true);
    } catch (error) {
      console.error("Error deleting brand:", error);
    } finally {
      setDeleteModal(false);
      setIsDeleteLoading(false);
    }
  };
  const deleteHandler = (item) => {
    setComapnyToDelete(item);
    setDeleteModal(true);
  };

  return (
    <>
      <CustomModal
        loading={isDeleteLoading}
        show={deleteModal}
        close={() => setDeleteModal(false)}
        action={onDelete}
        heading="Are you sure you want to delete this company?"
      />

      {IsLoading ? (
        <div className="d-flex justify-content-center align-items-center my-5 py-5">
          <CircularProgress />
        </div>
      ) : (
        <>
          {companyData?.map((el, i) => {
            return (
              <div
                className="card shadow mb-3 grid-margin stretch-card"
                key={i}
              >
                <div className="card-body">
                  <h5 className="mb-3">
                    <strong>Company {el.id}</strong>
                  </h5>
                  <div className="row">
                    <div className="col-md-4">
                      <p>
                        <strong>Name</strong>
                        <br /> <span className="text-dark">{el.name_en}</span>
                      </p>
                      <p>
                        <strong>About</strong>
                        <br /> <span className="text-dark">{el.about_en}</span>
                      </p>
                      <p>
                        <strong>Email</strong>
                        <br />{" "}
                        <span className="text-dark">
                          {" "}
                          {el.organization_email}
                        </span>
                      </p>
                    </div>
                    <div className="col-md-4">
                      <p>
                        <strong>Phone</strong>
                        <br />{" "}
                        <span className="text-dark">{el.phone_number}</span>
                      </p>
                      <p>
                        <strong>Address</strong>
                        <br />{" "}
                        <span className="text-dark"> {el.address_en}</span>
                      </p>
                      <p>
                        <strong>Organization Number</strong>
                        <br />{" "}
                        <span className="text-dark">
                          {" "}
                          {el.organization_number}
                        </span>
                      </p>
                    </div>
                    <div className="col-md-4">
                      <p>
                        <strong>Website</strong>
                        <br />{" "}
                        <span className="text-dark">
                          <a href="{el.website}" target="_blank">
                            link
                          </a>
                        </span>
                      </p>
                      <p>
                        <strong>Rating</strong>
                        <br />
                        <span className="text-dark">
                          <Rating defaultValue={el.rating} readOnly />
                        </span>
                      </p>
                    </div>
                  </div>
                  <div className="mt-3">
                    {/* <Link
                      to={`/dashboard/all-company-earning/${el.id}`}
                      className="btn btn-gradient-success btn-sm mx-2 my-1"
                    >
                      View Earning
                    </Link> */}
                    <Link
                      to={"/dashboard/edit-company/" + el.id}
                      className="btn btn-gradient-primary btn-sm mx-2 my-1"
                    >
                      Edit
                    </Link>
                    {true && (
                      <button
                        className="btn btn-gradient-danger btn-sm mx-2 my-1"
                        onClick={() => deleteHandler(el)}
                      >
                        Delete
                      </button>
                    )}
                  </div>
                </div>
              </div>
            );
          })}
        </>
      )}

      <div className="my-3">
        <PaginationComponent
          pagination={pagination}
          handlePageChange={handlePageChange}
        />
      </div>
    </>
  );
};

export default CompanyCards;
