import React from "react";
import EditUserForm from "../../../../forms/editUserForm";

const EditUserMainComp = () => {
  return (
    <div class="container-fluid">
      <EditUserForm />
    </div>
  );
};

export default EditUserMainComp;
