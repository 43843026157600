import React, { useEffect, useState, useRef } from "react";
import { useNavigate } from "react-router-dom";
import { Form, Button, Row, Col, Spinner } from "react-bootstrap";
import { toast } from "react-toastify";
import PhoneInput from "react-phone-number-input";

// import BackButton from "../../../../BackButton";
// import { makeRequest } from "../../../../../api/axiosService";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faTrash } from "@fortawesome/free-solid-svg-icons";
// import Spinner_component from "../../../../profile/Spinner";
import BackButton from "../BackButton";
import { makeRequest } from "../../api/axiosService";
import Spinner_component from "./Spinner";

const ProfileContainer = () => {
  const MAX_FILE_SIZE = 5 * 1024 * 1024; // 5 MB
  const user = JSON.parse(localStorage.getItem("user"));
  const fileInputRef = useRef(null);
  const [uploadImg, setUploadImg] = useState(false);
  const [isFetching, setIsFetching] = useState(true);

  const navigate = useNavigate();
  const [loading, setLoading] = useState(false);
  const [profileData, setProfileData] = useState({
    // id: 14,
    // phone: "+923460474874",
    // username: "zaka",
    // email: "zakaullahjavaid@gmail.com",
    // first_name: "Zaka",
    // last_name: "zak",
    // user_type: "ADMIN_USER",
    // address: "123 Admin St, City, Country",
    // profile_image: null,
    // is_verified: false,
  });

  const [errors, setErrors] = useState({});

  useEffect(() => {
    document.title = "Edit Profile";
  }, []);

  const fetchAdminData = async () => {
    try {
      const response = await makeRequest(`/admin-users/${user.id}`, "GET");
      if (response) {
        setProfileData({
          phone: response?.phone || "",
          email: response?.email || "",
          first_name: response?.first_name || "",
          last_name: response?.last_name || "",
          username: response?.username || "",
          address: response?.address || "",
          user_type: response?.user_type || "",
          profile_image: response?.profile_image || null,
        });
      }
      setIsFetching(false);
    } catch (error) {
      console.log("Error fetching admin data:", error);
      toast.error("Failed to fetch admin data. Please try again.");
      setIsFetching(false);
    }
  };
  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setProfileData({ ...profileData, [name]: value });

    if (value) {
      setErrors((prevErrors) => {
        const { [name]: removedError, ...restErrors } = prevErrors;
        return restErrors;
      });
    }
  };

  const handleImageUpload = async (e) => {
    const files = e.target.files; // Get all selected files

    if (files.length > 0) {
      for (const file of files) {
        try {
          if (file.size > MAX_FILE_SIZE) {
            toast.error(
              "File size is too large. Please upload files under 5 MB."
            );
            return;
          }
          setUploadImg(true);

          const formData = new FormData();
          formData.append("added_images", file);

          // Make the API call to upload the image
          const response = await makeRequest(
            "/upload-image/",
            "POST",
            formData
          );

          if (response?.uploaded_urls?.[0]) {
            toast.success("Uploaded successfully");

            setProfileData((prevState) => ({
              ...prevState,
              profile_image: response?.uploaded_urls[0], // Replace the image field with the new URL
            }));
          }
        } catch (error) {
          toast.error("Failed to upload image. Please try again.");
          console.log("Error uploading image:", error);
        } finally {
          setUploadImg(false);
        }
      }
    }
  };

  const handleRemoveImage = () => {
    setProfileData({ ...profileData, profile_image: "" });
    if (fileInputRef.current) {
      fileInputRef.current.value = "";
    }
  };

  const validate = () => {
    const newErrors = {};
    if (!profileData?.username) newErrors.username = "Username is required.";
    if (!profileData?.email) newErrors.email = "Email is required.";
    if (!profileData?.first_name)
      newErrors.first_name = "First name is required.";
    if (!profileData?.last_name) newErrors.last_name = "Last name is required.";
    if (!profileData?.phone) newErrors.phone = "Phone number is required.";
    if (!profileData?.address) newErrors.address = "Address is required.";
    if (!profileData?.profile_image)
      newErrors.profile_image = "Profile image is required.";
    setErrors(newErrors);
    return Object.keys(newErrors).length === 0;
  };

  const saveProfile = async () => {
    if (!validate()) return;

    const formData = new FormData();
    formData.append("id", profileData?.id);
    formData.append("phone", profileData?.phone);
    formData.append("username", profileData?.username);
    // formData.append("email", profileData?.email);
    formData.append("first_name", profileData?.first_name);
    formData.append("last_name", profileData?.last_name);
    // formData.append("user_type", profileData?.user_type);
    formData.append("address", profileData?.address);
    if (profileData?.profile_image) {
      formData.append("profile_image", profileData?.profile_image);
    }

    try {
      setLoading(true);
      const { email, user_type, ...profileUpdateData } = profileData;

      const response = await makeRequest(
        `/admin-users/${user?.id}/`,
        "PATCH",
        profileUpdateData
      );
      toast.success("Profile updated successfully!");

      // Redirect or clear the form after success
      navigate("/dashboard/profile"); // Redirect to profile page
    } catch (error) {
      // Log the error to the console
      console.error("Error details:", error.response?.data);

      // Map over all the errors and store them in state
      const errorMessages = error.response?.data || {};

      // Set the errors to the state, making sure each error is associated with the corresponding field
      const newErrors = {};
      Object.keys(errorMessages).forEach((key) => {
        newErrors[key] = errorMessages[key].join(", "); // Join array of errors into a single string if there are multiple messages
      });

      setErrors(newErrors);

      // Display the error message to the user
      toast.error(
        "There were some errors with your input. Please check the form."
      );
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    fetchAdminData();
  }, [user?.id]);

  return (
    <div className="dashCard mb-4">
      <Row className="mb-3">
        <Col>
          <h2 className="mainTitleAdd">
            <BackButton />
            Edit Profile
          </h2>
        </Col>
      </Row>

      {isFetching ? (
        <Spinner_component />
      ) : (
        <Form noValidate>
          {/* Username */}

          <Row className="mb-3">
            <Col sm={12} md={6}>
              <Form.Group controlId="formFirstName">
                <Form.Label>First Name</Form.Label>
                <Form.Control
                  type="text"
                  name="first_name"
                  value={profileData?.first_name}
                  onChange={handleInputChange}
                  placeholder="Enter your first name"
                  isInvalid={!!errors.first_name}
                />
                {errors.first_name && (
                  <Form.Control.Feedback type="invalid">
                    {errors.first_name}
                  </Form.Control.Feedback>
                )}
              </Form.Group>
            </Col>
            <Col sm={12} md={6}>
              <Form.Group controlId="formLastName">
                <Form.Label>Last Name</Form.Label>
                <Form.Control
                  type="text"
                  name="last_name"
                  value={profileData?.last_name}
                  onChange={handleInputChange}
                  placeholder="Enter your last name"
                  isInvalid={!!errors.last_name}
                />
                {errors.last_name && (
                  <Form.Control.Feedback type="invalid">
                    {errors.last_name}
                  </Form.Control.Feedback>
                )}
              </Form.Group>
            </Col>
          </Row>

          <Row className="mb-3">
            <Col sm={12} md={6}>
              <Form.Group controlId="formUsername">
                <Form.Label>Username</Form.Label>
                <Form.Control
                  type="text"
                  name="username"
                  value={profileData?.username}
                  onChange={handleInputChange}
                  placeholder="Enter your username"
                  isInvalid={!!errors.username}
                />
                {errors.username && (
                  <Form.Control.Feedback type="invalid">
                    {errors.username}
                  </Form.Control.Feedback>
                )}
              </Form.Group>
            </Col>
            <Col sm={12} md={6}>
              <Form.Group controlId="formEmail">
                <Form.Label>Email</Form.Label>
                <Form.Control
                  type="email"
                  name="email"
                  value={profileData?.email}
                  onChange={handleInputChange}
                  placeholder="Enter your email"
                  isInvalid={!!errors.email}
                  disabled
                />
                {errors.email && (
                  <Form.Control.Feedback type="invalid">
                    {errors.email}
                  </Form.Control.Feedback>
                )}
              </Form.Group>
            </Col>
          </Row>

          {/* Last Name */}
          <Row className="mb-3">
            <Col sm={12} md={6}>
              <Form.Group controlId="formPhone">
                <Form.Label>Phone</Form.Label>
                <PhoneInput
                  className="form-control"
                  defaultCountry="SE"
                  value={profileData?.phone}
                  handleInputChange
                  placeholder="Mobile Number"
                />
                {errors.phone && (
                  <div
                    style={{
                      color: "#d32f2f",
                      fontSize: "0.75rem",
                      marginTop: "4px",
                    }}
                  >
                    {errors.phone}
                  </div>
                )}
              </Form.Group>
            </Col>
            <Col sm={12} md={6}>
              <Form.Group controlId="formAddress">
                <Form.Label>Address</Form.Label>
                <Form.Control
                  type="text"
                  name="address"
                  value={profileData?.address}
                  onChange={handleInputChange}
                  placeholder="Enter your address"
                  isInvalid={!!errors.address}
                />
                {errors.address && (
                  <Form.Control.Feedback type="invalid">
                    {errors.address}
                  </Form.Control.Feedback>
                )}
              </Form.Group>
            </Col>
          </Row>
          {/* Address */}
          <Row className="mb-3"></Row>
          {/* Profile Image */}
          <Row className="mb-3">
            <Col sm={12} md={6}>
              <Form.Group controlId="formProfileImage">
                <Form.Label>Upload Profile Image</Form.Label>
                <Form.Control
                  type="file"
                  ref={fileInputRef}
                  onChange={handleImageUpload}
                  isInvalid={!!errors.profile_image}
                />
                {errors.profile_image && (
                  <Form.Control.Feedback type="invalid">
                    {errors.profile_image}
                  </Form.Control.Feedback>
                )}
              </Form.Group>
              {uploadImg ? (
                <div className="mb-3 mt-3">
                  <Spinner_component />
                </div>
              ) : (
                profileData?.profile_image && (
                  <div className="mt-3 text-center">
                    <img
                      src={profileData?.profile_image}
                      alt="Profile Preview"
                      style={{
                        width: "200px",
                        height: "200px",
                        objectFit: "contain",
                        borderRadius: "12px",
                        backgroundColor: "#F0F0F0",
                      }}
                    />
                    <div className="mt-2">
                      <FontAwesomeIcon
                        icon={faTrash}
                        style={{
                          color: "red",
                          cursor: "pointer",
                          fontSize: "1rem",
                        }}
                        title="Remove image"
                        onClick={handleRemoveImage}
                      />
                    </div>
                  </div>
                )
              )}
            </Col>
            {/* <Col sm={12} md={6}>
              User Typ: {profileData?.user_type}
            </Col> */}

            <Col sm={12} md={6}>
              <Form.Group controlId="formUserType">
                <Form.Label>User Type</Form.Label>
                <Form.Control
                  type="text"
                  name="user_type"
                  value={profileData?.user_type.replace("_", " ")}
                  //   onChange={handleInputChange}
                  //   placeholder="Enter your email"
                  //   isInvalid={!!errors.email}
                  disabled
                />
                {/* {errors.email && (
                  <Form.Control.Feedback type="invalid">
                    {errors.email}
                  </Form.Control.Feedback>
                )} */}
              </Form.Group>
            </Col>
          </Row>

          {/* Save Button */}
          <Row>
            <Col lg={4} md={6}>
              <Button
                disabled={loading}
                variant="primary"
                onClick={saveProfile}
              >
                {loading ? <Spinner animation="border" size="sm" /> : "Save"}
              </Button>
            </Col>
          </Row>
        </Form>
      )}
    </div>
  );
};

export default ProfileContainer;
