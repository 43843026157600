import React from "react";
import SidebarComp from "../../components/sidebar/sidebar";
import NavbarComp from "../../components/navbar/navbar";
import AddCarMainComp from "../../components/body/car/addcarcomp/addcarcomp";
import AddBrandMainComp from "../../components/body/alldatacomponent/brands/addBrand/addBrandComp";

const AddBrandScreen = () => {
  return (
    <div className="container-scroller">
      <NavbarComp />
      <div className="container-fluid page-body-wrapper">
        <SidebarComp />
        <div className="main-panel">
          <div className="content-wrapper">
            <AddBrandMainComp />
          </div>
        </div>
      </div>
    </div>
  );
};

export default AddBrandScreen;
