import React, { useEffect, useState } from "react";
import axios from "axios";
import CircularProgress from "@mui/material/CircularProgress";
import CircleImg from "../../../../static/images/dashboard/circle.svg";
import "./style.css";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faCar,
  faBuilding,
  faUser,
  faCalendar,
  faUserTie,
} from "@fortawesome/free-solid-svg-icons";

const DashboardTopMidComp = () => {
  const userType = localStorage.getItem("A_usertype");
  const accessToken = localStorage.getItem("Key");
  const [allCountData, setallCountData] = useState({
    all_rides: 0,
    all_cars: 0,
    all_drivers: 0,
    all_companies: 0,
    all_employees: 0,
  });
  const [IsLoading, setIsLoading] = useState(false);
  const [getDashboardCount, setGetDashboardCount] = useState(false);

  const handleAllDashbaordCount = async () => {
    setIsLoading(true);
    try {
      if (userType === "ADMIN_USER" || userType === "COMPANY_USER") {
        const resp = await axios
          .get(`${process.env.REACT_APP_BASE_URL}all-dashboard-count/`, {
            headers: {
              Authorization: "Bearer " + accessToken,
            },
          })
          .then((response) => {
            setallCountData(response.data);
          });
      }
    } catch (error) {
      console.log(error);
    }
    setIsLoading(false);
  };

  useEffect(() => {
    handleAllDashbaordCount();
  }, [getDashboardCount]);

  return (
    <>
      {IsLoading ? (
        <div
          style={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            marginBottom: 20,
          }}
        >
          <CircularProgress />
        </div>
      ) : (
        <div class="row">
          <div class="col-md-3 stretch-card grid-margin">
            <div class="card bg-gradient-danger card-img-holder text-white">
              <div class="card-body custom-padding">
                <img
                  src={CircleImg}
                  class="card-img-absolute"
                  alt="circle-image"
                />
                <h4 class="font-weight-normal mb-3">
                  Total Rides{" "}
                  <span className="mx-2">
                    <FontAwesomeIcon icon={faCalendar} />
                  </span>
                </h4>
                <h2 class="mb-5"> {allCountData.all_rides}</h2>
              </div>
            </div>
          </div>
          <div class="col-md-3 stretch-card grid-margin">
            <div class="card bg-gradient-info card-img-holder text-white">
              <div class="card-body custom-padding">
                <img
                  src={CircleImg}
                  class="card-img-absolute"
                  alt="circle-image"
                />
                <h4 class="font-weight-normal mb-3">
                  {" "}
                  Total Drivers{" "}
                  <span className="mx-2">
                    <FontAwesomeIcon icon={faUser} />
                  </span>
                </h4>
                <h2 class="mb-5"> {allCountData.all_drivers}</h2>
              </div>
            </div>
          </div>

          <div class="col-md-3 stretch-card grid-margin">
            <div class="card bg-gradient-success card-img-holder text-white">
              <div class="card-body custom-padding">
                <img
                  src={CircleImg}
                  class="card-img-absolute"
                  alt="circle-image"
                />
                <h4 class="font-weight-normal mb-3">
                  {" "}
                  Total Car{" "}
                  <span className="mx-2">
                    <FontAwesomeIcon icon={faCar} />
                  </span>
                </h4>
                <h2 class="mb-5"> {allCountData.all_cars}</h2>
              </div>
            </div>
          </div>

          {userType === "ADMIN_USER" ? (
            <div class="col-md-3 stretch-card grid-margin">
              <div class="card bg-gradient-warning card-img-holder text-white">
                <div class="card-body custom-padding">
                  <img
                    src={CircleImg}
                    class="card-img-absolute"
                    alt="circle-image"
                  />
                  <h4 class="font-weight-normal mb-3">
                    {" "}
                    Total Comapanies{" "}
                    <span className="mx-2">
                      <FontAwesomeIcon icon={faBuilding} />
                    </span>
                  </h4>
                  <h2 class="mb-5"> {allCountData.all_companies}</h2>
                </div>
              </div>
            </div>
          ) : (
            <div class="col-md-3 stretch-card grid-margin">
              <div class="card bg-gradient-warning card-img-holder text-white">
                <div class="card-body custom-padding">
                  <img
                    src={CircleImg}
                    class="card-img-absolute"
                    alt="circle-image"
                  />
                  <h4 class="font-weight-normal mb-3">
                    Total Employees
                    <span className="mx-2">
                      <FontAwesomeIcon icon={faUserTie} />
                    </span>
                  </h4>
                  <h2 class="mb-5">{allCountData.all_employees}</h2>
                </div>
              </div>
            </div>
          )}
        </div>
      )}
    </>
  );
};

export default DashboardTopMidComp;
