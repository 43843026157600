import React from "react";
import DasboarTopComp from "../../main/dashboardtop/dashboardtop";
import DashboardTopMidComp from "../../main/dashboardtopmid/dashboardtopmid";
import AddUserMidComp from "../addusermidcomp/addusermidcomp";

const AddUserMainComp = () => {
  return (
    <div class="container-fluid">
      <AddUserMidComp />
    </div>
  );
};

export default AddUserMainComp;
