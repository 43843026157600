import React, { useEffect, useRef, useState, useContext } from "react";
import axios from "axios";
import logo from "../../static/images/logo.png";
import { useNavigate } from "react-router-dom";
// import { FaBell, FaCog, FaList, FaSignOutAlt } from "react-icons/fa";
// import faceIcon from "../../static/images/faces/face1.jpg";
import "./styles.css";
import { WebSocketContext } from "../../app/App";
import NavProfileImage from "../sidebar/NavProfileImage";

const NavbarComp = () => {
  const { notificationSocket, setSideBarActive, sideBarActive } =
    useContext(WebSocketContext);
  const [notifications, setNotifications] = useState([]);
  const nav = useNavigate();
  const fname = localStorage.getItem("A_firstname");
  const lname = localStorage.getItem("A_lastname");
  const profile = localStorage.getItem("A_profile_image");

  const onHamBurgerClick = (e) => {
    setSideBarActive(!sideBarActive);
  };

  const markNotificationsAsRead = () => {
    // sessionStorage.removeItem('notifications');
    // setNotifications([]);/
    nav("/dashboard/notifications");
  };

  const handleLogout = async () => {
    try {
      const sessionKey = localStorage.getItem("Key");
      if (sessionKey) {
        const options = {
          headers: {
            Authorization: `Bearer ${sessionKey}`,
            "Content-Type": "application/json",
          },
        };
        const response = await axios.get(
          `${process.env.REACT_APP_BASE_URL}signout/`,
          options
        );
        if (response.status === 200) {
          localStorage.clear();
          nav("/");
        } else {
          console.error("Logout failed:", response);
        }
      }
      //TODO
      localStorage.clear();
      nav("/");
    } catch (error) {
      console.error("Logout error:", error);
      if (error?.response?.status === 401) {
        // Handle unauthorized error
      } else {
        // Handle other errors
      }
    }
  };

  useEffect(() => {
    const existingNotifications =
      JSON.parse(sessionStorage.getItem("notifications")) || [];
    setNotifications(existingNotifications);
  }, []);

  useEffect(() => {
    if (notificationSocket) {
      notificationSocket.addEventListener("message", (event) => {
        const { message } = JSON.parse(event.data);
        const timestampedNotification = {
          info: message.info,
          timestamp: Date.now(),
        };
        const updatedNotifications = [
          ...notifications,
          timestampedNotification,
        ];
        setNotifications(updatedNotifications);
        sessionStorage.setItem(
          "notifications",
          JSON.stringify(updatedNotifications)
        );
      });
    }
  }, [notifications]);
  console.log(notifications);

  return (
    //         <li className="nav-item dropdown no-arrow">
    //
    //             {showNotifications && (
    //                 <div className="notification-dropdown">
    //                     <ul className="notification-list">
    //
    //                     </ul>
    //                     <div className="d-flex justify-content-between">
    //                         <button className="close-button" onClick={toggleNotifications}>
    //                             Close
    //                         </button>
    //                         <button className="mark-read-button" onClick={markNotificationsAsRead}>
    //                             Mark All as Read
    //                         </button>
    //                     </div>
    //                 </div>
    //             )}
    //         </li>
    //     </ul>
    // </nav>
    <nav className="fixed-top navbar default-layout-navbar col-lg-12 col-12 p-0 fixed-top d-flex flex-row">
      <div className="text-center navbar-brand-wrapper d-flex align-items-center justify-content-center">
        <img style={{ width: "60px" }} src={logo} alt="logo" />
      </div>
      <div className="navbar-menu-wrapper d-flex align-items-stretch">
        {/* <button className="navbar-toggler navbar-toggler align-self-center" type="button" data-toggle="minimize">
                    <span className="mdi mdi-menu"></span>
                </button> */}

        <ul className="navbar-nav navbar-nav-right">
          {/* <li className="nav-item d-none d-lg-block full-screen-link">
                        <a className="nav-link">
                            <i className="mdi mdi-fullscreen" id="fullscreen-button"></i>
                        </a>
                    </li> */}

          <li className="nav-item dropdown">
            <a
              className="nav-link count-indicator dropdown-toggle"
              id="notificationDropdown"
              href="#"
              data-bs-toggle="dropdown"
            >
              <i className="mdi mdi-bell-outline"></i>
              {notifications.length > 0 && (
                <span className="count-symbol bg-danger"></span>
              )}
            </a>
            <div
              className="dropdown-menu dropdown-menu-right navbar-dropdown preview-list"
              style={{ minWidth: "18rem" }}
              aria-labelledby="notificationDropdown"
            >
              <h6 className="p-3 mb-0">Notifications</h6>
              <div className="dropdown-divider"></div>
              {notifications.length > 0 ? (
                <>
                  <a className="dropdown-item preview-item">
                    <div className=" d-flex align-items-start flex-column justify-content-center">
                      {notifications.map((notification, index) => (
                        <p
                          className="text-gray ellipsis mb-0"
                          onClick={() => {
                            window.location.href = "/dashboard";
                          }}
                          key={index}
                        >
                          {notification.info}
                        </p>
                      ))}
                    </div>
                  </a>
                  <div className="dropdown-divider"></div>
                </>
              ) : (
                <a className="dropdown-item preview-item">
                  <div className="preview-item-content d-flex align-items-start flex-column justify-content-center">
                    <p className="text-gray ellipsis mb-0">No Notifications</p>
                  </div>
                </a>
              )}

              <h6
                onClick={() => markNotificationsAsRead()}
                style={{ cursor: "pointer" }}
                className="p-3 mb-0 text-center"
              >
                All Notifications
              </h6>
            </div>
          </li>
          <li className="nav-item nav-profile dropdown">
            <a
              className="nav-link dropdown-toggle"
              id="profileDropdown"
              href="#"
              data-bs-toggle="dropdown"
              aria-expanded="false"
            >
              {/* <div className="nav-profile-img">
                <img src={profile} alt="image" />
                <span className="availability-status online"></span>
              </div> */}
              <NavProfileImage
                profile={profile || null}
                userName={fname || lname}
              />

              <div className="nav-profile-text">
                <p className="mb-1 text-black">
                  {fname} {lname}
                </p>
              </div>
            </a>
            <div
              className="dropdown-menu navbar-dropdown"
              aria-labelledby="profileDropdown"
            >
              <a className="dropdown-item-nav" href="#" onClick={handleLogout}>
                <i className="mdi mdi-logout me-2 text-primary"></i> Signout{" "}
              </a>
            </div>
          </li>
        </ul>
        <button
          className="navbar-toggler navbar-toggler-right d-lg-none align-self-center"
          onClick={(e) => onHamBurgerClick(e)}
          type="button"
          data-toggle="offcanvas"
        >
          <span className="mdi mdi-menu"></span>
        </button>
      </div>
    </nav>
  );
};

export default NavbarComp;
