import React, { useState, useEffect } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faEdit, faTrash } from "@fortawesome/free-solid-svg-icons";
import CircularProgress from "@mui/material/CircularProgress";
import { useNavigate } from "react-router-dom";
import { makeRequest } from "../../../api/axiosService";
// import CustomTable from "../../components/CustomTable"; // Assuming CustomTable is in components
// import placeholderImage from "../../../assets/placeholder.png";
import DeleteConfirmationModel from "../deleteConfirmationModel";
import CustomTable from "../../CustomTable";
import { placeholderImage } from "../../../BrandAssets/images";
// import PaginationComponent from "../PaginationComponent";
import PaginationComponent from "../../CustomTable/Pagination";
import CustomModal from "../../CustomModal";
import { toast } from "react-toastify";

const AdminCards = () => {
  const [admins, setAdmins] = useState([]);
  const [isLoading, setIsLoading] = useState(true);
  const [error, setError] = useState(null);
  const navigate = useNavigate();
  const [showModel, setShowModel] = useState({
    show: false,
    id: null,
  });

  const perPageValues = [10, 20, 50];
  const [itemsPerPage, setItemsPerPage] = useState(10);
  const [from, setFrom] = useState("");
  const [to, setTo] = useState("");
  const [isDeleteLoading, setIsDeleteLoading] = useState(false);

  const [deleteModal, setDeleteModal] = useState(false);
  const [adminToDelete, setAdminToDelete] = useState(null);
  const [confirmDelete, setConfirmDelete] = useState(false);

  const [pagination, setPagination] = useState({
    currentPage: 1,
    totalCount: 0,
    next: null,
    previous: null,
  });

  const handlePageChange = (pageNumber) => {
    if (pageNumber > 0 && pageNumber <= Math.ceil(pagination.totalCount / 10)) {
      setPagination((prev) => ({
        ...prev,
        currentPage: pageNumber,
      }));
    }
  };

  // Fetch admin data from API
  const fetchAdmins = async () => {
    try {
      setIsLoading(true);
      const response = await makeRequest("/admin-users/", "GET");
      setAdmins(response?.results || []);
    } catch (error) {
      console.error("Error fetching admins:", error);
      setError("Failed to fetch data.");
    } finally {
      setIsLoading(false);
    }
  };

  useEffect(() => {
    fetchAdmins();
  }, []);

  const deleteHandler = (item) => {
    setAdminToDelete(item);
    setDeleteModal(true);
  };

  // const deleteHandler = (admin) => {
  //   setShowModel({
  //     show: true,
  //     id: admin?.id,
  //   });
  // };

  const onDelete = async () => {
    if (adminToDelete) {
      try {
        setIsDeleteLoading(true);
        await makeRequest(`/admin-users/${adminToDelete?.id}/`, "DELETE");
        await fetchAdmins();
        setConfirmDelete(true);
      } catch (error) {
        console.log("Error deleting brand:", error);
        toast.error(error?.response?.data?.detail || "Failed to delete");
      } finally {
        setDeleteModal(false);
        setIsDeleteLoading(false);
      }
    }
  };

  const categoryHeaders = [
    { title: "#", sortable: false },
    { title: "Image", sortable: false },
    { title: "Name", sortable: true },
    { title: "Created At", sortable: true },
    { title: "Status", sortable: true },
    { title: "Type", sortable: true },
    { title: "Actions", sortable: false },
  ];

  return (
    <div className="col-12 data-table dashCard">
      {/* <DeleteConfirmationModel
        showModel={showModel}
        onHandleDelete={handleDelete}
        handleCloseModel={() => setShowModel({ show: false, id: null })}
      /> */}
      {isLoading ? (
        <div className="text-center">
          <CircularProgress />
        </div>
      ) : error ? (
        <div className="text-danger text-center ">{error}</div>
      ) : (
        <div>
          <CustomTable
            headers={categoryHeaders}
            dateFilter={false}
            perPage
            perPageValues={perPageValues}
            filterFrom={from}
            setFilterFrom={setFrom}
            filterTo={to}
            setFilterTo={setTo}
            itemsPerPage={itemsPerPage}
            setItemsPerPage={setItemsPerPage}
            length={admins.length}
            // buttonLabel="Add Admin"
            onClickButton={() => navigate("/dashboard/admin-management/add")}
          >
            <tbody>
              {admins?.map((admin, index) => (
                <tr key={admin.id}>
                  <td>{admin.id}</td>
                  <td>
                    <img
                      src={admin?.image || placeholderImage}
                      alt="Admin"
                      className="thumbnail"
                    />
                  </td>
                  <td className="text-capitalize">
                    {admin?.username || "N/A"}
                  </td>
                  <td>{admin?.created_at || "N/A"}</td>
                  <td className={admin?.active ? "greenColor" : "redColor"}>
                    {admin?.active ? "Active" : "Inactive"}
                  </td>
                  <td>
                    {(admin?.user_type &&
                      admin?.user_type.replace(/_/g, " ")) ||
                      "N/A"}
                  </td>
                  <td style={{ cursor: "pointer" }}>
                    <FontAwesomeIcon
                      onClick={() =>
                        navigate(
                          `/dashboard/admins-management/edit/${admin?.id}`
                        )
                      }
                      icon={faEdit}
                      title="Edit"
                      className="tableActionIcon"
                    />
                    <FontAwesomeIcon
                      onClick={() => deleteHandler(admin)}
                      icon={faTrash}
                      title="Delete"
                      className="tableActionIcon"
                    />
                  </td>
                </tr>
              ))}
            </tbody>
          </CustomTable>
        </div>
      )}
      <CustomModal
        loading={isDeleteLoading}
        show={deleteModal}
        close={() => setDeleteModal(false)}
        action={onDelete}
        heading="Are you sure you want to delete this admin?"
      />
      <CustomModal
        show={confirmDelete}
        close={() => setConfirmDelete(false)}
        success
        heading="Admin Deleted Successfully"
      />
      <PaginationComponent
        pagination={pagination}
        handlePageChange={handlePageChange}
      />
    </div>
  );
};

export default AdminCards;
