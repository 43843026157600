import React from "react";
import { Image } from "react-bootstrap";

const NavProfileImage = ({ profile, userName }) => {
  // Extract the first character of the username
  const avatarInitial = userName ? userName.charAt(0).toUpperCase() : "?";

  return (
    <div className="nav-profile-image">
      {profile ? (
        <Image
          src={profile}
          alt="profile"
          roundedCircle
          fluid
          style={{ width: "50px", height: "50px", objectFit: "cover" }}
        />
      ) : (
        <div
          className="avatar-placeholder"
          style={{
            width: "50px",
            height: "50px",
            borderRadius: "50%",
            backgroundColor: "#007bff",
            color: "#fff",
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            fontSize: "20px",
            fontWeight: "bold",
          }}
        >
          {avatarInitial}
        </div>
      )}
      <span className="login-status online"></span>
    </div>
  );
};

export default NavProfileImage;
