import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faChartLine } from "@fortawesome/free-solid-svg-icons";
import CustomTable from "../../../CustomTable";
import CustomModal from "../../../CustomModal";
import Spinner_component from "../../../profile/Spinner";
import PaginationComponent from "../../../CustomTable/Pagination";
import "./style.css";
import { makeRequest } from "../../../../api/axiosService";
import { Form } from "react-bootstrap";

const FinanceMainComp = () => {
  const navigate = useNavigate();

  const [summary, setSummary] = useState({
    total_amount: 0,
    total_profit: 0,
    total_orders: 0,
  });

  const [dailyRecords, setDailyRecords] = useState([]);
  const [companies, setCompanies] = useState([]);
  const [selectedCompany, setSelectedCompany] = useState("");
  const [startDate, setStartDate] = useState("");
  const [endDate, setEndDate] = useState("");

  const [isLoading, setIsLoading] = useState(true);
  const [pagination, setPagination] = useState({
    currentPage: 1,
    totalCount: 0,
    next: null,
    previous: null,
  });

  const fetchCompany = async () => {
    try {
      const resp = await makeRequest("/company/", "GET");
      setCompanies(resp?.results || []);
    } catch (error) {
      console.error("Error fetching companies:", error);
    }
  };

  const fetchFinanceData = async () => {
    try {
      setIsLoading(true);
      const params = new URLSearchParams();
      if (selectedCompany) params.append("company", selectedCompany);
      if (startDate) params.append("start_date", startDate);
      if (endDate) params.append("end_date", endDate);

      const endpoint = `/finances/?${params.toString()}`;
      const resp = await makeRequest(endpoint, "GET");
      setSummary(resp?.results?.summary || {});
      setDailyRecords(resp?.results?.daily_records || []);
      setPagination((prev) => ({
        ...prev,
        totalCount: resp?.results?.daily_records?.length || 0,
      }));
    } catch (error) {
      console.error("Error fetching finance data:", error);
    } finally {
      setIsLoading(false);
    }
  };

  const handlePageChange = (pageNumber) => {
    if (pageNumber > 0 && pageNumber <= Math.ceil(pagination.totalCount / 10)) {
      setPagination((prev) => ({
        ...prev,
        currentPage: pageNumber,
      }));
    }
  };

  const handleCompanyChange = (event) => {
    setSelectedCompany(event.target.value);
    fetchFinanceData();
  };

  const handleStartDateChange = (event) => {
    setStartDate(event.target.value);
    fetchFinanceData();
  };

  const handleEndDateChange = (event) => {
    setEndDate(event.target.value);
    fetchFinanceData();
  };

  useEffect(() => {
    fetchCompany();
    fetchFinanceData();
    document.title = "Finance";
  }, []);

  const financeHeaders = [
    { key: "date", title: "Date" },
    { key: "amount", title: "Amount" },
    { key: "profit", title: "Profit" },
    { key: "orders", title: "Orders" },
    { key: "company", title: "Company" },
  ];

  return (
    <div>
      <div className="row mb-3">
        <div className="col-12">
          <div className="dashCard">
            <div className="row mb-3 justify-content-between">
              <div className="col-md-6 mb-2 d-flex">
                <i className="icon_bg">
                  <FontAwesomeIcon icon={faChartLine} />
                </i>
                <h3 className="mainTitle">Finance Summary</h3>
              </div>
              <div className="col-md-6 mb-2 d-flex ">
                <Form.Group style={{ width: "100%" }} controlId="formCompany">
                  <Form.Control
                    as="select"
                    name="company"
                    value={selectedCompany}
                    onChange={handleCompanyChange}
                    style={{
                      border: "1px solid #ebedf2",
                      padding: "15px 15px",
                      width: "100%",
                    }}
                  >
                    <option value="">Select Company</option>
                    {companies?.map((company) => (
                      <option key={company.id} value={company.id}>
                        {company.name_en} / {company.name_ar} /{" "}
                        {company.name_ku}
                      </option>
                    ))}
                  </Form.Control>
                </Form.Group>
              </div>
              <div className="col-md-6 mb-2 mt-3">
                <Form.Group controlId="startDate">
                  <Form.Label className="custom-label">Start Date</Form.Label>
                  <Form.Control
                    type="date"
                    value={startDate}
                    onChange={handleStartDateChange}
                  />
                </Form.Group>
              </div>
              <div className="col-md-6 mb-2 mt-3">
                <Form.Group controlId="endDate">
                  <Form.Label className="custom-label">End Date</Form.Label>
                  <Form.Control
                    type="date"
                    value={endDate}
                    onChange={handleEndDateChange}
                  />
                </Form.Group>
              </div>
            </div>
            <div className="row mb-3">
              <div className="col-md-4">
                <div className="summaryCard shadow-sm p-3 mb-5 bg-white rounded">
                  <h5 className="summaryTitle">Total Amount</h5>
                  <p className="summaryValue">{summary?.total_amount || 0}</p>
                </div>
              </div>
              <div className="col-md-4">
                <div className="summaryCard shadow-sm p-3 mb-5 bg-white rounded">
                  <h5 className="summaryTitle">Total Profit</h5>
                  <p className="summaryValue">{summary?.total_profit || 0}</p>
                </div>
              </div>
              <div className="col-md-4">
                <div className="summaryCard shadow-sm p-3 mb-5 bg-white rounded">
                  <h5 className="summaryTitle">Total Orders</h5>
                  <p className="summaryValue">{summary?.total_orders || 0}</p>
                </div>
              </div>
            </div>
            <div className="row mb-3">
              {isLoading ? (
                <div className="col-12 text-center">
                  <Spinner_component />
                </div>
              ) : (
                <div className="col-12 data-table">
                  <CustomTable
                    headers={financeHeaders}
                    itemsPerPage={10}
                    length={dailyRecords.length}
                    pagination={pagination}
                    handlePageChange={handlePageChange}
                  >
                    <tbody>
                      {dailyRecords
                        .slice(
                          (pagination.currentPage - 1) * 10,
                          pagination.currentPage * 10
                        )
                        .map((record, index) => (
                          <tr key={index}>
                            <td>{record.date}</td>
                            <td>${record.amount.toFixed(2)}</td>
                            <td>${record.profit.toFixed(2)}</td>
                            <td>{record.orders}</td>
                          </tr>
                        ))}
                    </tbody>
                  </CustomTable>
                </div>
              )}
            </div>
            <PaginationComponent
              pagination={pagination}
              handlePageChange={handlePageChange}
            />
          </div>
        </div>
      </div>
    </div>
  );
};

export default FinanceMainComp;
