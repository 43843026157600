import React from "react";
import SidebarComp from "../../components/sidebar/sidebar";
import NavbarComp from "../../components/navbar/navbar";
// import AddUserMainComp from "../../components/body/user/addusermaincomp/addusermaincomp";
import AddAdminMainComp from "../../components/body/admins/addAdminMainComp/addAdminMainComp";

const AddAdminScreen = () => {
  return (
    <div className="container-scroller">
      <NavbarComp />
      <div className="container-fluid page-body-wrapper">
        <SidebarComp />
        <div className="main-panel">
          <div className="content-wrapper">
            <AddAdminMainComp />
          </div>
        </div>
      </div>
    </div>
  );
};

export default AddAdminScreen;
