import React, { useEffect, useState } from "react";
import {
  Container,
  Row,
  Col,
  Card,
  Badge,
  Button,
  Table,
  Modal,
  Form,
} from "react-bootstrap";
import { useNavigate, useLocation } from "react-router-dom";
import { FaCheck, FaEye } from "react-icons/fa"; // Import eye icon from react-icons
import { toast } from "react-toastify"; // Toast notifications
import BackButton from "../../../../BackButton";
import { makeRequest } from "../../../../../api/axiosService";

const ViewOrderMainComp = () => {
  const location = useLocation();
  const navigate = useNavigate();
  const { subOrder } = location.state || {}; // Access the subOrder data


  const [showModal, setShowModal] = useState(false);
  const [selectedStatus, setSelectedStatus] = useState("");
  const [isUpdating, setIsUpdating] = useState(false);

  const [showPaymentModal, setShowPaymentModal] = useState(false);
  const [selectedPaymentStatus, setSelectedPaymentStatus] = useState("");

  const statusOptions = [
    { value: "received", label: "Order Received" },
    { value: "confirmed", label: "Order Confirmed" },
    { value: "preparation", label: "Order Preparation" },
    { value: "out_for_delivery", label: "Order Out for Delivery" },
    { value: "delivered", label: "Order Delivered" },
    { value: "cancelled", label: "Order Cancel" },
  ];

  const filteredStatusOptions = subOrder.status !== "delivered"
    ? statusOptions
    : statusOptions.filter((status) => status.value !== "cancelled");

  const paymentStatusOptions = [
    { value: "pending", label: "Payment Pending" },
    { value: "paid_to_transport", label: "Paid to Transport" },
    { value: "paid", label: "Payment Completed" },
    // { value: "refunded", label: "Payment Refunded" },
  ];

  // const PAYMENT_STATUS_FLOW = [
  //   "pending",
  //   "paid_to_transport",
  //   "paid",
  //   // "refunded",
  // ];

  const STATUS_FLOW = [
    "received",
    "confirmed",
    "preparation",
    "out_for_delivery",
    "delivered",
  ];

  const isValidTransition = (currentStatus, newStatus) => {
    const currentIndex = STATUS_FLOW.indexOf(currentStatus);
    const newIndex = STATUS_FLOW.indexOf(newStatus);

    // Allow same status or the very next status only
    return newIndex === currentIndex || newIndex === currentIndex + 1;
  };

  const handlePaymentStatusUpdate = async () => {
    if (!selectedPaymentStatus) {
      toast.error("Please select a payment status to update.");
      return;
    }

    if (selectedPaymentStatus === subOrder.payment_status) {
      toast.warning("Payment status is already set to the selected value.");
      return;
    }

    try {
      setIsUpdating(true);
      await makeRequest(`/sub-orders/${subOrder.id}/update-status/`, "PATCH", {
        payment_status: selectedPaymentStatus,
      });

      // setSubOrder((prev) => ({
      //   ...prev,
      //   payment_status: selectedPaymentStatus,
      // }));
      toast.success("Payment status updated successfully!");
      setShowPaymentModal(false);
    } catch (error) {
      console.error("Error updating payment status:", error);
      toast.error("Failed to update payment status.");
    } finally {
      setIsUpdating(false);
    }
  };


  useEffect(() => {
    if (subOrder) {
      document.title = `Order Details - ${subOrder.company_name}`;
    }
  }, [subOrder]);

  useEffect(() => {
    if (subOrder) {
      setSelectedStatus(subOrder.status); // Initialize selectedStatus with current order status
      setSelectedPaymentStatus(subOrder.payment_status);
    }
  }, [subOrder]);

  const handleStatusUpdate = async () => {
    if (!selectedStatus) {
      toast.error("Please select a status to update.");
      return;
    }

    if (selectedStatus === subOrder.status) {
      toast.warning("Status is already set to the selected value.");
      return;
    }

    // if (!isValidTransition(subOrder.status, selectedStatus)) {
    //   toast.error(
    //     `Cannot move from "${subOrder.status}" to "${selectedStatus}". You can only update to the current, the next status, or cancel the order.`
    //   );
    //   return;
    // }

    try {
      setIsUpdating(true);
      await makeRequest(`/sub-orders/${subOrder.id}/update-status/`, "PATCH", {
        status: selectedStatus,
      });

      subOrder.status = selectedStatus; // Update subOrder's status locally
      toast.success("Order status updated successfully!");
      setShowModal(false);
    } catch (error) {
      console.error("Error updating status:", error);
      toast.error("Failed to update order status.");
    } finally {
      setIsUpdating(false);
    }
  };

  if (!subOrder) {
    return <p>No sub-order data available.</p>;
  }

  return (
    <Container className="mt-4">
      {/* Back Button */}
      <Row className="mb-4">
        <Col>
          <BackButton />
        </Col>
      </Row>

      {/* Order Details */}
      <Card className="mb-4">
        <Card.Header>
          <h4>Sub Order Details</h4>
        </Card.Header>
        <Card.Body>
          <Row>
            <Col sm={6}>
              <p>
                <strong>Company Name:</strong> {subOrder.company_name}
              </p>
              <p>
                <strong>Status:</strong>{" "}
                <Badge bg="info">{subOrder.status}</Badge>
              </p>
              <p>
                <strong>Payment Status:</strong>{" "}
                <Badge
                  bg={
                    subOrder.payment_status === "pending"
                      ? "warning"
                      : "success"
                  }
                >
                  {subOrder.payment_status}
                </Badge>
              </p>
              <p>
                <strong>Total Amount:</strong> {subOrder.total_amount}
              </p>
              <p>
                <strong>Created At:</strong>{" "}
                {new Date(subOrder.created_at).toLocaleString()}
              </p>
            </Col>
            <Col sm={6}>
              <p>
                <strong>Shipping Method:</strong>{" "}
                {subOrder.shipping_option.method}
              </p>
              <p>
                <strong>Shipping Address:</strong>
              </p>
              <ul>
                <li>
                  <strong>City:</strong> {subOrder.shipping_address.city}
                </li>
                <li>
                  <strong>Address:</strong> {subOrder.shipping_address.address}
                </li>
                <li>
                  <strong>Governorate:</strong>{" "}
                  {subOrder.shipping_address.governorate}
                </li>
                <li>
                  <strong>Postal Code:</strong>{" "}
                  {subOrder.shipping_address.postal_code}
                </li>
              </ul>
            </Col>
          </Row>
        </Card.Body>
      </Card>

      {/* Items */}
      <Card className="mb-4">
        <Card.Header>
          <h4>Items</h4>
        </Card.Header>
        <Card.Body>
          <Table striped bordered hover>
            <thead>
              <tr>
                <th>#</th>
                <th>Product Name</th>
                <th>Quantity</th>
                <th>Price</th>
                <th>Total Item Price</th>
                <th>Action</th>
              </tr>
            </thead>
            <tbody>
              {subOrder.items.map((item, index) => (
                <tr key={item.id}>
                  <td>{index + 1}</td>
                  <td>{item.product_name}</td>
                  <td>{item.quantity}</td>
                  <td>{item.price}</td>
                  <td>{item.total_item_price}</td>
                  <td>
                    <Button
                      variant="link"
                      className="p-0 text-decoration-none mx-3"
                      onClick={() =>
                        navigate(
                          `/dashboard/product-management/${item.product}`
                        )
                      }
                    >
                      <FaEye /> {/* Eye Icon */}
                    </Button>
                  </td>
                </tr>
              ))}
            </tbody>
          </Table>
        </Card.Body>
      </Card>

      {/* Update Status Button */}
      {/* Conditionally render the "Update Status" button only if the status is not "cancelled" or "return" */}
      <Row className="mt-4">
        <Col className="d-flex justify-content-end">
          {/* Update Status Button */}
          {!["cancelled", "return"].includes(subOrder.status) && (
            <Button variant="primary" onClick={() => setShowModal(true)} className="mx-2">
              Order Status
            </Button>
          )}

          {/* Update Payment Status Button */}
         {subOrder.payment_status !=='refunded' &&  <Button variant="secondary" onClick={() => setShowPaymentModal(true)} className="mx-2">
            Payment Status
          </Button>}
        </Col>
      </Row>



      {/* Update Status Modal */}
      <Modal show={showModal} onHide={() => setShowModal(false)} centered>
        <Modal.Header style={{ margin: "auto" }}>
          <Modal.Title>
            <span>Update Order Status</span>
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Form className="mx-3">
            {filteredStatusOptions.map((status, index) => {
              const currentIndex = STATUS_FLOW.indexOf(subOrder.status);
              const statusIndex = STATUS_FLOW.indexOf(status.value);

              // Define the conditions for each status
              const isPrevious = statusIndex < currentIndex && status.value !== "cancelled";
              const isCurrent = statusIndex === currentIndex;
              const isNext = statusIndex === currentIndex + 1;
              const isDisabled = !isCurrent && !isNext && status.value !== "cancelled";

              const isSelected = selectedStatus === status.value;

              return (
                <Form.Group
                  key={status.value}
                  className="mb-3"
                  style={{ display: "flex", alignItems: "center" }}
                >
                  {isPrevious ? (
                    // Show tick icon for previous statuses except "cancelled"
                    <FaCheck style={{ color: "green", marginRight: "15px" }} />
                  ) : isDisabled ? (
                    // Empty space for disabled statuses
                    <div style={{ width: "15px", marginRight: "15px" }}></div>
                  ) : (
                    // Radio button for current, next, or "cancelled" statuses
                    <Form.Check
                      type="radio"
                      id={status.value}
                      name="status"
                      value={status.value}
                      checked={isSelected}
                      onChange={(e) => setSelectedStatus(e.target.value)}
                      style={{ marginRight: "15px" }}
                    />
                  )}
                  <Form.Label
                    htmlFor={status.value}
                    style={{
                      color: isPrevious
                        ? "green" // Green for previous statuses
                        : isDisabled
                          ? "lightgrey" // Grey for disabled statuses
                          : "inherit", // Default for current/next
                      margin: 0,
                      display: "inline",
                    }}
                  >
                    {status.label}
                  </Form.Label>
                </Form.Group>
              );
            })}
          </Form>
        </Modal.Body>


        <Modal.Footer>
          <Button variant="secondary" onClick={() => setShowModal(false)}>
            Cancel
          </Button>
          <Button
            variant="primary"
            onClick={handleStatusUpdate}
            disabled={isUpdating || selectedStatus === subOrder.status}
          >
            {isUpdating ? "Updating..." : "Update"}
          </Button>
        </Modal.Footer>
      </Modal>

      {/* Update Payment Status Modal */}
      <Modal show={showPaymentModal} onHide={() => setShowPaymentModal(false)} centered>
        <Modal.Header closeButton>
          <Modal.Title>Update Payment Status</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Form className="mx-3">
            {paymentStatusOptions.map((status) => {
              // Find the index of the current payment status and the option status
              // const currentIndex = PAYMENT_STATUS_FLOW.indexOf(subOrder.payment_status);
              // const statusIndex = PAYMENT_STATUS_FLOW.indexOf(status.value);

              // Define conditions for previous, current, next, and disabled statuses
              // const isPrevious = statusIndex < currentIndex; // Mark previous statuses for tick
              // const isCurrent = statusIndex === currentIndex; // Current status
              // const isNext = statusIndex === currentIndex + 1; // Next status
              // const isDisabled = !isCurrent && !isNext && status.value !== "refunded"; // Disabled statuses that can't be selected

              const isSelected = selectedPaymentStatus === status.value; // Ensure selectedPaymentStatus is used

              return (
                <Form.Group key={status.value} className="mb-3" style={{ display: "flex", alignItems: "center" }}>
                  {/* Show tick icon for previous statuses */}
                  {/* {isPrevious  && (
              <FaCheck style={{ color: "green",  fontSize: "18px" }} />
            )} */}


                  <Form.Check
                    type="radio"
                    id={status.value}
                    name="paymentStatus"
                    value={status.value}
                    checked={isSelected} // Check if it's selected
                    onChange={(e) => setSelectedPaymentStatus(e.target.value)} // Update selected status
                    style={{ marginRight: "15px" }}
                  />
                  {/* Show radio button for current, next, or "refunded" statuses */}
                  {/* {isCurrent || isNext || status.value === "refunded" ? (
              <Form.Check
                type="radio"
                id={status.value}
                name="paymentStatus"
                value={status.value}
                checked={isSelected} // Check if it's selected
                onChange={(e) => setSelectedPaymentStatus(e.target.value)} // Update selected status
                style={{ marginRight: "15px" }}
              />
            ) : (
              // Empty space for disabled statuses
              <div style={{marginRight: "15px" }}></div>
            )} */}

                  {/* Label for the payment status */}
                  <Form.Label
                    htmlFor={status.value}
                    // style={{
                    //   color: isPrevious
                    //     ? "green" // Green for previous statuses
                    //     : isDisabled
                    //       ? "lightgrey" // Grey for disabled statuses
                    //       : "inherit", // Default for current/next
                    //   margin: 0,
                    //   display: "inline",
                    // }}
                  >
                    {status.label}
                  </Form.Label>
                </Form.Group>
              );
            })}
          </Form>
        </Modal.Body>

        <Modal.Footer>
          <Button variant="secondary" onClick={() => setShowPaymentModal(false)}>
            Cancel
          </Button>
          <Button
            variant="primary"
            onClick={handlePaymentStatusUpdate}
            disabled={isUpdating || selectedPaymentStatus === subOrder.payment_status}
          >
            {isUpdating ? "Updating..." : "Update"}
          </Button>
        </Modal.Footer>
      </Modal>




    </Container>
  );
};

export default ViewOrderMainComp;
