import React from "react";
import { Col, Form, Row } from "react-bootstrap";

const AddProductFormKr = ({ productData, handleInputChange }) => {
  return (
    <div dir="rtl">
      <Row className="mb-3">
        <Col sm={12}>
          <h4 className="text-left mb-4 fw-bold ">
            زانیاریەکانی بەرهەم هەڵبژێنە (ئینگلیزی)
          </h4>
        </Col>

        <Col sm={12}>
          <Form.Group controlId="formProductName">
            <Form.Label>ناوی بەرهەم</Form.Label>
            <Form.Control
              type="text"
              name="name_ku"
              value={productData?.name_ku}
              onChange={handleInputChange}
              placeholder="ناوی بەرهەم هێنە"
              // required
            />
          </Form.Group>
        </Col>

        <Col sm={12}>
          <Form.Group controlId="formProductDescription" className="mb-3">
            <Form.Label>وەسفە</Form.Label>
            <Form.Control
              as="textarea"
              name="description_ku"
              rows={4}
              value={productData?.description_ku}
              onChange={handleInputChange}
              style={{
                border: "1px solid #ebedf2",
                padding: "15px 15px",
              }}
              placeholder="وەسفی بەرهەم بنووسە"
            />
          </Form.Group>
        </Col>
      </Row>
    </div>
  );
};

export default AddProductFormKr;
