import React, { useState, useEffect } from "react";
import { useParams } from "react-router-dom";
import {
  Container,
  Row,
  Col,
  Card,
  Table,
  Badge,
  Image,
  Button,
  Form,
} from "react-bootstrap";
import BackButton from "../../../../BackButton";
import { makeRequest } from "../../../../../api/axiosService";
import { toast } from "react-toastify";
import Spinner_component from "../../../../profile/Spinner";
import "./style.css";

const ViewProductMainComp = () => {
  const { id } = useParams();
  const [showComment, setShowComment] = useState(false);
  const [comment, setComment] = useState("");
  const [isFetching, setIsFetching] = useState(true);
  const [productData, setProductData] = useState({});

  useEffect(() => {
    document.title = `Swedebuy | Product Details - ${
      productData?.name_en || productData?.name_ar
    }`;
  }, [productData?.name_en, productData?.name_ar]);

  const fetchProductData = async () => {
    try {
      const response = await makeRequest(`/products/${id}`, "GET");
      setProductData(response);
      setIsFetching(false);
    } catch (error) {
      console.error("Error fetching product data:", error);
      toast.error("Failed to fetch product data. Please try again.");
      setIsFetching(false);
    }
  };

  useEffect(() => {
    fetchProductData();
  }, [id]);

  return (
    <Container className="mt-4">
      <Row className="mt-4 d-flex justify-content-end mb-4">
        <Col sm={12}>
          <BackButton /> Back
        </Col>
      </Row>
      {isFetching ? (
        <Spinner_component />
      ) : (
        <Row>
          <Row>
            {/* Product Images */}
            <Col sm={12} className="mb-4">
              <div className="d-flex flex-wrap gap-3 justify-content-start">
                {productData?.images?.length > 0 ? (
                  productData.images.map((img, index) => (
                    <Image
                      key={index}
                      src={img}
                      thumbnail
                      alt={`Product Image ${index + 1}`}
                      className="product-image-thumbnail"
                      style={{
                        width: "150px",
                        height: "150px",
                        objectFit: "cover",
                        borderRadius: "10px",
                      }}
                    />
                  ))
                ) : (
                  <p className="text-muted">
                    No images available for this product.
                  </p>
                )}
              </div>
            </Col>

            {/* Product Details */}
            <Col sm={12}>
              <Card className="shadow-sm border-0">
                <Card.Body>
                  {/* Product Names */}
                  <div className="mb-3">
                    <strong>Name:</strong>{" "}
                    {productData?.name_en ||
                    productData?.name_ar ||
                    productData?.name_ku ? (
                      `${productData?.name_en || "N/A"} / ${
                        productData?.name_ar || "N/A"
                      } / ${productData?.name_ku || "N/A"}`
                    ) : (
                      <span className="text-muted">N/A</span>
                    )}
                  </div>

                  {/* Product Descriptions */}
                  <p>
                    <strong>Description:</strong>{" "}
                    {productData?.description_en ||
                    productData?.description_ar ||
                    productData?.description_ku ? (
                      `${productData?.description_en || "N/A"} / ${
                        productData?.description_ar || "N/A"
                      } / ${productData?.description_ku || "N/A"}`
                    ) : (
                      <span className="text-muted">N/A</span>
                    )}
                  </p>

                  {/* Brand and Category with sub category*/}
                  <p>
                    <strong>Brand:</strong>{" "}
                    {productData?.brand_name || (
                      <span className="text-muted">N/A</span>
                    )}
                  </p>
                  <p>
                    <strong>Category:</strong>{" "}
                    {productData?.category_name || (
                      <span className="text-muted">N/A</span>
                    )}
                  </p>
                  <p>
                    <strong>Sub Category:</strong>{" "}
                    {productData?.subcategory_name || (
                      <span className="text-muted">N/A</span>
                    )}
                  </p>

                  {/* Badges */}
                  <div className="d-flex flex-wrap mt-3 gap-2">
                    {productData?.trending && (
                      <Badge bg="info" pill className="large-badge">
                        Trending
                      </Badge>
                    )}
                    {productData?.new_arrival && (
                      <Badge bg="primary" pill className="large-badge">
                        New Arrival
                      </Badge>
                    )}
                    {productData?.out_of_stock && (
                      <Badge bg="secondary" pill className="large-badge">
                        Out of Stock
                      </Badge>
                    )}
                    {productData?.hot && (
                      <Badge bg="danger" pill className="large-badge">
                        Hot
                      </Badge>
                    )}
                    {productData?.best_selling && (
                      <Badge bg="success" pill className="large-badge">
                        Best Selling
                      </Badge>
                    )}
                    {productData?.featured && (
                      <Badge bg="warning" pill className="large-badge">
                        Featured
                      </Badge>
                    )}
                    {productData?.active && (
                      <Badge bg="dark" pill className="large-badge">
                        Active
                      </Badge>
                    )}
                    {productData?.status && (
                      <Badge bg="light" pill className="large-badge text-dark">
                        Status:{" "}
                        <span style={{ textTransform: "capitalize" }}>
                          {productData?.status}
                        </span>
                      </Badge>
                    )}
                  </div>
                </Card.Body>
              </Card>
            </Col>
          </Row>

          <Row className="mt-4">
            <Col sm={12}>
              <Card>
                <Card.Header>
                  <h4>Dimensions and Weight</h4>
                </Card.Header>
                <Card.Body>
                  <p>
                    <strong>Weight:</strong> {productData?.weight} kg
                  </p>
                  <p>
                    <strong>Dimensions:</strong> {productData?.height} x{" "}
                    {productData?.width} x {productData?.depth} cm
                  </p>
                </Card.Body>
              </Card>
            </Col>
            <Col sm={12} className="mt-4">
              <Card>
                <Card.Header>
                  <h4>SKU & Taxonomy</h4>
                </Card.Header>
                <Card.Body>
                  <p>
                    <strong>SKU:</strong> {productData?.sku}
                  </p>
                  <p>
                    <strong>Taxonomy:</strong> {productData?.taxonomy}
                  </p>
                </Card.Body>
              </Card>
            </Col>
          </Row>

          {/* Pricing, Variants, and Stock Information */}
          <Row className="mt-4">
            <Col sm={12}>
              <Card>
                <Card.Header>
                  <h4>Pricing Details</h4>
                </Card.Header>
                <Card.Body>
                  <Table striped bordered hover responsive="sm">
                    <thead>
                      <tr>
                        <th>Scale</th>
                        <th>Price</th>
                        <th>Base Price</th>
                        <th>VAT</th>
                        <th>Profit</th>
                        <th>Last Updated</th>
                      </tr>
                    </thead>
                    <tbody>
                      {productData?.price_variations?.length ? (
                        productData?.price_variations?.map(
                          (variation, index) => (
                            <tr key={index}>
                              <td>{variation?.scale || "N/A"}</td>
                              <td>${variation?.price || "N/A"}</td>
                              <td>${variation?.basePrice || "N/A"}</td>
                              <td>
                                {variation?.VAT ? `$${variation?.VAT}` : "N/A"}
                              </td>
                              <td>${variation?.profit || "N/A"}</td>
                              <td>
                                {new Date(
                                  variation?.updatedAt
                                ).toLocaleDateString() || "N/A"}
                              </td>
                            </tr>
                          )
                        )
                      ) : (
                        <tr>
                          <td colSpan="6">No pricing details available</td>
                        </tr>
                      )}
                    </tbody>
                  </Table>
                </Card.Body>
              </Card>
            </Col>
            <Col sm={12} className="mt-4">
              <Card>
                <Card.Header>
                  <h4>Stock Information</h4>
                </Card.Header>
                <Card.Body>
                  {productData?.stock ? (
                    <>
                      <p>
                        <strong>Quantity:</strong>{" "}
                        {productData?.stock?.quantity}
                      </p>
                      <p>
                        <strong>Handling Days:</strong>{" "}
                        {productData?.stock?.minHandlingDays} -{" "}
                        {productData?.stock?.maxHandlingDays}
                      </p>
                    </>
                  ) : (
                    <p>Stock information is not available at the moment.</p>
                  )}
                </Card.Body>
              </Card>
            </Col>
          </Row>
          {/* Variants */}
          <Row className="mt-4">
            <Col sm={12}>
              {productData.variants?.length > 0 && (
                <Card className="mb-4">
                  <Card.Header>
                    <h4>Variants</h4>
                  </Card.Header>
                  <Card.Body>
                    {productData.variants.map((variant, index) => (
                      <div key={index} className="mb-3">
                        <h5>{variant.name}</h5>
                        <ul>
                          {variant.options.map((option) => (
                            <li key={option.id}>
                              {option.option} (Quantity: {option.quantity})
                            </li>
                          ))}
                        </ul>
                      </div>
                    ))}
                  </Card.Body>
                </Card>
              )}
            </Col>
          </Row>

          <Row className="mt-4">
            <Col sm={12}>
              {productData.discount && (
                <Card className="mb-4">
                  <Card.Header>
                    <h4>Discount Information</h4>
                  </Card.Header>
                  <Card.Body>
                    <p>
                      <strong>Discount Percentage:</strong>{" "}
                      {productData.discount.discount_percentage}%
                    </p>
                    <p>
                      <strong>Validity:</strong>{" "}
                      {new Date(
                        productData.discount.start_date
                      ).toLocaleDateString()}{" "}
                      to{" "}
                      {new Date(
                        productData.discount.end_date
                      ).toLocaleDateString()}
                    </p>
                  </Card.Body>
                </Card>
              )}
            </Col>
          </Row>

          {/* Quantity Discounts */}
          <Row cls>
            <Col sm={12}>
              {productData.quantity_discounts?.length > 0 && (
                <Card className="mb-4">
                  <Card.Header>
                    <h4>Quantity Discounts</h4>
                  </Card.Header>
                  <Card.Body>
                    <Table striped bordered hover>
                      <thead>
                        <tr>
                          <th>Quantity</th>
                          <th>Discount</th>
                        </tr>
                      </thead>
                      <tbody>
                        {productData.quantity_discounts.map((qd, index) => (
                          <tr key={index}>
                            <td>{qd.quantity}</td>
                            <td>${qd.discount}</td>
                          </tr>
                        ))}
                      </tbody>
                    </Table>
                  </Card.Body>
                </Card>
              )}
            </Col>
          </Row>

          {/* Comment Section */}
          {showComment && (
            <Row className="mt-4">
              <Col sm={12}>
                <Card>
                  <Card.Header>
                    <h4>Leave a Comment</h4>
                  </Card.Header>
                  <Card.Body>
                    <Form>
                      <Form.Group controlId="comment">
                        <Form.Control
                          as="textarea"
                          rows={3}
                          value={comment}
                          onChange={(e) => setComment(e.target.value)}
                          placeholder="Add a comment"
                        />
                      </Form.Group>
                      <Button
                        variant="primary"
                        className="mt-2"
                        onClick={() => toast.success("Comment added!")}
                      >
                        Submit
                      </Button>
                    </Form>
                  </Card.Body>
                </Card>
              </Col>
            </Row>
          )}
        </Row>
      )}
    </Container>
  );
};

export default ViewProductMainComp;
