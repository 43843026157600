import React, { useEffect, useState, useRef } from "react";
import { toast } from "react-toastify";
import { useNavigate } from "react-router-dom";
import { Form, Button, Row, Col, Spinner } from "react-bootstrap";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faTrash } from "@fortawesome/free-solid-svg-icons";
import { makeRequest } from "../../../../../api/axiosService";
import BackButton from "../../../../BackButton";
import Spinner_component from "../../../../profile/Spinner";

const AddBrandMainComp = () => {
  const MAX_FILE_SIZE = 5 * 1024 * 1024; // 5 MB
  const fileInputRef = useRef(null);
  const [uploadImg, setUplaodImg] = useState(false);

  const user = JSON.parse(localStorage.getItem("user"));


  const [brandData, setBrandData] = useState({
    name_en: "",
    name_ar: "",
    name_ku: "",
    image: "",
    active: false,
  });
  const [errors, setErrors] = useState({
    name_en: false,
    name_ar: false,
    name_ku: false,
    image: false,
  });
  const [loading, setLoading] = useState(false);
  const navigate = useNavigate();

  useEffect(() => {
    document.title = "Add Brand";
  }, []);

  const handleInputChange = (e) => {
    const { name, value, type, checked } = e.target;
    setBrandData({
      ...brandData,
      [name]: type === "checkbox" ? checked : value,
    });

    // Clear error on input change
    if (errors[name]) {
      setErrors({ ...errors, [name]: false });
    }
  };

  // const handleImageUpload = (e) => {
  //   const file = e.target.files[0];
  //   if (file) {
  //     setBrandData({ ...brandData, image: file });
  //   }
  // };

  // const handleImageUpload = async (e) => {
  //   const files = e.target.files; // Get all selected files

  //   if (files.length > 0) {
  //     for (const file of files) {
  //       try {
  //         // Prepare form data for the API request
  //         const formData = new FormData();
  //         formData.append("added_images", file);

  //         // Make the API call to upload the image
  //         const response = await makeRequest(
  //           "/upload-image/",
  //           "POST",
  //           formData
  //         );

  //         if (response?.uploaded_urls) {
  //           setBrandData((prevState) => {
  //             const updatedImage = [
  //               ...prevState?.image,
  //               ...response?.uploaded_urls,
  //             ];
  //             return { ...prevState, image: updatedImage };
  //           });
  //         }
  //       } catch (error) {
  //         console.error("Error uploading image:", error);
  //       }
  //     }
  //   }
  // };

  const handleImageUpload = async (e) => {
    const files = e.target.files; // Get all selected files

    if (files.length > 0) {
      for (const file of files) {
        try {
          if (file.size > MAX_FILE_SIZE) {
            toast.error(
              "File size is too large. Please upload files under 5 MB."
            );
            return;
          }
          setUplaodImg(true);
          //  else {
          //   toast.info("Image is uploading...", {
          //     autoClose: 2000, // Display for 2 seconds (2000 milliseconds)
          //   });
          // }

          const formData = new FormData();
          formData.append("added_images", file);

          // Make the API call to upload the image
          const response = await makeRequest(
            "/upload-image/",
            "POST",
            formData
          );

          if (response?.uploaded_urls?.[0]) {
            toast.success("Uplaoded successfully");

            setBrandData((prevState) => ({
              ...prevState,
              image: response.uploaded_urls[0], // Replace the image field with the new URL
            }));
          }
        } catch (error) {
          toast.error("Failed to uplaod image.Please try again");
          console.log("Error uploading image:", error);
        } finally {
          setUplaodImg(false);
        }
      }
    }
  };

  const handleRemoveImage = () => {
    setBrandData({ ...brandData, image: "" });
    if (fileInputRef.current) {
      fileInputRef.current.value = "";
    }
  };

  const validateFields = () => {
    const newErrors = {
      name_en: !brandData.name_en.trim(),
      name_ar: !brandData.name_ar.trim(),
      name_ku: !brandData.name_ku.trim(),
      image: !brandData.image,
    };

    setErrors(newErrors);
    return !Object.values(newErrors).some((error) => error);
  };

  const saveBrand = async () => {
    if (!validateFields()) {
      return;
    }

    setLoading(true); // Start loading
    try {
      const formData = new FormData();
      Object.keys(brandData).forEach((key) => {
        formData.append(key, brandData[key]);
      });

      await makeRequest("/brands/", "POST", formData);

      // Reset form and show success message
      setBrandData({
        name_en: "",
        name_ar: "",
        name_ku: "",
        image: "",
        active: true,
      });
      if (fileInputRef.current) fileInputRef.current.value = "";
      toast.success("Brand added successfully!");
      navigate("/dashboard/brand-management");
    } catch (error) {
      console.error("Error saving brand:", error);
      toast.error("Failed to add brand. Please try again.");
    } finally {
      setLoading(false); // Stop loading
    }
  };

  return (
    <div className="dashCard mb-4">
      <Row className="mb-3">
        <Col className="d-flex align-items-center">
          <BackButton />
          <h2 className="mainTitleAdd">Add New Brand</h2>
        </Col>
      </Row>

      <Form>
        {/* Name (English) */}
        <Row className="mb-3">
          <Col lg={4} md={6}>
            <Form.Group controlId="formBrandname_en">
              <Form.Label>Add Brand Name (English)</Form.Label>
              <Form.Control
                type="text"
                name="name_en"
                value={brandData.name_en}
                onChange={handleInputChange}
                placeholder="Enter Brand Name in English"
                isInvalid={errors.name_en}
                required
              />
              {errors.name_en && (
                <Form.Control.Feedback type="invalid">
                  English Brand Name is required.
                </Form.Control.Feedback>
              )}
            </Form.Group>
          </Col>
        </Row>

        {/* Name (Arabic) */}
        <Row className="mb-3" dir="rtl">
          <Col lg={4} md={6}>
            <Form.Group controlId="formBrandname_ar">
              <Form.Label>اسم العلامة التجارية (عربي)</Form.Label>
              <Form.Control
                type="text"
                name="name_ar"
                value={brandData.name_ar}
                onChange={handleInputChange}
                placeholder="أدخل اسم العلامة التجارية باللغة العربية"
                isInvalid={errors.name_ar}
                required
              />
              {errors.name_ar && (
                <Form.Control.Feedback type="invalid">
                  اسم العلامة التجارية بالعربية مطلوب
                </Form.Control.Feedback>
              )}
            </Form.Group>
          </Col>
        </Row>

        {/* Name (Kurdish) */}
        <Row className="mb-3" dir="rtl">
          <Col lg={4} md={6}>
            <Form.Group controlId="formBrandname_ku">
              <Form.Label>ناوی براند (كوردی)</Form.Label>
              <Form.Control
                type="text"
                name="name_ku"
                value={brandData.name_ku}
                onChange={handleInputChange}
                placeholder="ناوی براند داخل بکە بە زمانی کوردی"
                isInvalid={errors.name_ku}
                required
              />
              {errors.name_ku && (
                <Form.Control.Feedback type="invalid">
                  ناوی براندی کوردی پێویستە
                </Form.Control.Feedback>
              )}
            </Form.Group>
          </Col>
        </Row>

        {/* Image Upload */}
        <Row className="mb-3">
          <Col lg={4} md={6}>
            <Form.Group controlId="formBrandImage">
              <Form.Label>Upload Image</Form.Label>
              <Form.Control
                type="file"
                ref={fileInputRef}
                onChange={handleImageUpload}
                isInvalid={errors.image}
                required
              />
              {errors.image && (
                <Form.Control.Feedback type="invalid">
                  Image is required
                </Form.Control.Feedback>
              )}
            </Form.Group>
            {uploadImg ? (
              <Spinner_component />
            ) : (
              brandData?.image && (
                <div className="mt-3 text-center">
                  <img
                    src={brandData?.image}
                    alt="Brand Preview"
                    style={{
                      width: "200px",
                      height: "200px",
                      objectFit: "contain",
                      borderRadius: "12px",
                      backgroundColor: "#F0F0F0",
                    }}
                  />
                  <div className="mt-2">
                    <FontAwesomeIcon
                      icon={faTrash}
                      style={{
                        color: "red",
                        cursor: "pointer",
                        fontSize: "1rem",
                      }}
                      title="Remove image"
                      onClick={handleRemoveImage}
                    />
                  </div>
                </div>
              )
            )}
          </Col>
        </Row>

        {/* Is Active */}
        {(user?.user_type === "ADMIN_USER" || user?.user_type === "SUPER_USER") && (
          <Row className="mb-3 mx-2">
            <Col lg={4} md={6} className="d-flex align-items-center">
              <Form.Group controlId="formBrandactive">
                <Form.Check
                  type="checkbox"
                  name="active"
                  label="Is Active"
                  checked={brandData.active}
                  onChange={handleInputChange}
                />
              </Form.Group>
            </Col>
          </Row>
        )}


        {/* Save Button */}
        <Row>
          <Col lg={4} md={6}>
            <Button variant="primary" onClick={saveBrand} disabled={loading}>
              {loading ? <Spinner animation="border" size="sm" /> : "Save"}
            </Button>
          </Col>
        </Row>
      </Form>
    </div>
  );
};

export default AddBrandMainComp;
