import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { CircularProgress } from "@mui/material";
import React, { useState, useEffect } from "react";
import { faTrash } from "@fortawesome/free-solid-svg-icons";
import { makeRequest } from "../../../api/axiosService";
import CustomTable from "../../CustomTable";

const SubscribersCard = () => {
  const [subscribers, setSubscribers] = useState([]);
  const [isLoading, setIsLoading] = useState(true);
  const perPageValues = [10, 20, 50];
  const [itemsPerPage, setItemsPerPage] = useState(5);
  const [newsLetterId, setNewsLetterId] = useState(null);

  const getSubscribers = async () => {
    try {
      setIsLoading(true);

      const response = await makeRequest(`/newsletters/`, "GET");
      console.log(response, "response");
      setSubscribers(response?.results[0]?.emails || []);
      setNewsLetterId(response?.results[0]?.id);
    } catch (error) {
      console.error("Error fetching users:", error);
    } finally {
      setIsLoading(false);
    }
  };

  useEffect(() => {
    getSubscribers();
  }, []);

  const deleteHandler = async (email) => {
    try {
      setIsLoading(true);
      await makeRequest(`/newsletters/${newsLetterId}/unsubscribe/`, "POST", {
        email: email});
      await getSubscribers();
    } catch (error) {
      console.error("Error fetching users:", error);
    } finally {
      setIsLoading(false);
    }
  };

  const subscriberHeaders = [
    { title: "Subscriber's Email", sortable: false },
    { title: "Action", sortable: false },
  ];

  return (
    <div className="col-12 data-table dashCard">
      {isLoading ? (
        <div className="text-center">
          <CircularProgress />
        </div>
      ) : (
        <div>
          <CustomTable
            headers={subscriberHeaders}
            dateFilter={false}
            perPage
            perPageValues={perPageValues}
            itemsPerPage={itemsPerPage}
            setItemsPerPage={setItemsPerPage}
            length={subscribers.length}
          >
            <tbody>
              {subscribers?.map((subscriber, index) => (
                <tr key={index}>
                  <td className="text-start">{subscriber}</td>
                  <td>
                    <FontAwesomeIcon
                      onClick={() => deleteHandler(subscriber)}
                      icon={faTrash}
                      title="Unsubscribe"
                      className="tableActionIcon"
                      style={{ cursor: "pointer" }}
                    />
                  </td>
                </tr>
              ))}
            </tbody>
          </CustomTable>
        </div>
      )}
    </div>
  );
};

export default SubscribersCard;
