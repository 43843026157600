import React, { useEffect, useState } from "react";
import SidebarComp from "../../components/sidebar/sidebar";
import NavbarComp from "../../components/navbar/navbar";
import AllDataCommonComp from "../../components/body/alldatacomponent/common/common.js";
import { faBarcode } from "@fortawesome/free-solid-svg-icons";
import AllProductMainComp from "../../components/body/alldatacomponent/product/allProductsMainComp.js";
import { makeRequest } from "../../api/axiosService.js";
import { toast } from "react-toastify";

const ProductManagementScreen = () => {
  const [selectedProducts, setSelectedProducts] = useState([]);
  const [isDeleting, setIsDeleting] = useState(false);
  const [products, setProducts] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [companyData, setCompanyData] = useState([]);
  const [selectedFilter, setSelectedFilter] = useState("accept");
  const [company, setCompany] = useState(null);

  const [pagination, setPagination] = useState({
    currentPage: 1,
    totalCount: 0,
    next: null,
    previous: null,
  });

  const fetchProducts = async (page = 1, filter = "", company = "") => {
    try {
      setIsLoading(true);

      const statusQuery = filter ? `&status=${filter}` : "";
      const companyQuery = company ? `&company=${company}` : "";

      const resp = await makeRequest(
        `/products/?page=${page}${statusQuery}${companyQuery}`,
        "GET"
      );

      setProducts(resp?.results || []);
      setPagination({
        currentPage: page,
        totalCount: resp?.count || 0,
        next: resp?.next,
        previous: resp?.previous,
      });
    } catch (error) {
      console.error("Error fetching products:", error);
    } finally {
      setIsLoading(false); // Set loading state to false after API call
    }
  };

  const deletBulk = async () => {
    if (!selectedProducts.length) return;
    try {
      setIsDeleting(true);
      const resp = await makeRequest("/products/bulk_delete/ ", "POST", {
        ids: selectedProducts,
      });
      fetchProducts(pagination.currentPage, selectedFilter);
      toast.success(resp?.detail || "Deleted Successfully");
    } catch (error) {
      console.log(error);
    } finally {
      setIsDeleting(false);
    }
  };

  const fetchCompanyData = async () => {
    try {
      const resp = await makeRequest("/company/", "GET");
      setCompanyData(resp?.results);
    } catch (error) {
      console.log(error);
    }
  };
  useEffect(() => {
    fetchCompanyData();
    fetchProducts(pagination.currentPage, selectedFilter, company);
  }, [pagination.currentPage, selectedFilter, company]);

  return (
    <div className="container-scroller">
      <NavbarComp />
      <div className="container-fluid page-body-wrapper">
        <SidebarComp />
        <div className="main-panel">
          <div className="content-wrapper">
            <AllDataCommonComp
              addUrl="/dashboard/product-management/add"
              headerIcon={faBarcode}
              allemployee="All Products"
              downloadCall=""
              search={true}
              delete={true}
              handleDelete={deletBulk}
              deleteLoading={isDeleting}
            />

            <AllProductMainComp
              selectedProducts={selectedProducts}
              setSelectedProducts={setSelectedProducts}
              products={products}
              selectedFilter={selectedFilter}
              setSelectedFilter={setSelectedFilter}
              pagination={pagination}
              setPagination={setPagination}
              companyData={companyData}
              company={company}
              setCompany={setCompany}
              isLoading={isLoading}
              fetchProducts={fetchProducts}
            />
          </div>
        </div>
      </div>
    </div>
  );
};

export default ProductManagementScreen;
