import React from 'react'
import { faUsers } from "@fortawesome/free-solid-svg-icons";
import AllSubscribers from '../../../cards/subscribers/subscribersCard';
import AllDataCommonComp from '../common/common';

const allSubscribers = () => {
  return (
    <div class="container-fluid mt-4">
    <AllDataCommonComp
      headerIcon={faUsers}
      allemployee="All Subscribers"
    />

    <AllSubscribers />
  </div>
  )
}

export default allSubscribers;
