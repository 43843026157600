import React from "react";
import AddEmployeeForm from "../../../forms/addemployeeform";

const AddEmployeeMidComp = () => {
  return (
    <></>
    // <AddEmployeeForm></AddEmployeeForm>
  );
};

export default AddEmployeeMidComp;
