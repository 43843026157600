import React from "react";
import DasboarTopComp from "../../main/dashboardtop/dashboardtop";

const AllDataCommonComp = (props) => {
  return (
    <DasboarTopComp
      headerDash={props.allemployee.toUpperCase()}
      addUrl={props.addUrl}
      headerIcon={props.headerIcon}
      downloadCall={props.downloadCall}
      selectedIds={props.selectedIds}
      search={props.search}
      delete={props.delete}
      filter={props.filter}
      showButtons={props.showButtons !== undefined ? props.showButtons : true}
      handleDelete={props.handleDelete}
      deleteLoading={props.deleteLoading}
    />
  );
};

export default AllDataCommonComp;
