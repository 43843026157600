import React from "react";
import SidebarComp from "../../components/sidebar/sidebar";
import NavbarComp from "../../components/navbar/navbar";
// import AddCarMainComp from "../../components/body/car/addcarcomp/addcarcomp";
// import AddBrandMainComp from "../../components/body/alldatacomponent/brands/addBrand/addBrandComp";
// import EditBrandMainComp from "../../components/body/alldatacomponent/brands/editBrand/editBrandComp";
import EditCategoryMainComp from "../../components/body/alldatacomponent/categories/editCategory/editCategoryComp";
import EditSubCategoryMainComp from "../../components/body/alldatacomponent/subCategories/editSubCategory/editSubCategoryComp";

const EditSubCategoryScreen = () => {
  return (
    <div className="container-scroller">
      <NavbarComp />
      <div className="container-fluid page-body-wrapper">
        <SidebarComp />
        <div className="main-panel">
          <div className="content-wrapper">
            <EditSubCategoryMainComp />
          </div>
        </div>
      </div>
    </div>
  );
};

export default EditSubCategoryScreen;
