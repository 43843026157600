import React from "react";
import SidebarComp from "../../components/sidebar/sidebar";
import NavbarComp from "../../components/navbar/navbar";
import EditProductMainComp from "../../components/body/alldatacomponent/product/editProduct/editProductomp";

const EditProductScreen = () => {
  return (
    <div className="container-scroller">
      <NavbarComp />
      <div className="container-fluid page-body-wrapper">
        <SidebarComp />
        <div className="main-panel">
          <div className="content-wrapper">
            <EditProductMainComp />
          </div>
        </div>
      </div>
    </div>
  );
};

export default EditProductScreen;
