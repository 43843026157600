import React, { useState } from "react";
import AllDataCommonComp from "../common/common";
import UserCards from "../../../cards/users/userscard";
import { faUsers } from "@fortawesome/free-solid-svg-icons";
import AdminCards from "../../../cards/admins/adminCard";

const AllAdminsMainComp = () => {
  const [selectedIds, setselectedIds] = useState([]);
  const handleSSelectedIds = (ids) => {
    setselectedIds(ids);
  };
  return (
    <div class="container-fluid mt-4">
      <AllDataCommonComp
        addUrl="/dashboard/admins-management/add"
        headerIcon={faUsers}
        allemployee="All Admins"
        downloadCall=""
        selectedIds={selectedIds}
      ></AllDataCommonComp>
      <AdminCards handleSSelectedIds={handleSSelectedIds}></AdminCards>
    </div>
  );
};

export default AllAdminsMainComp;
