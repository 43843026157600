import React from "react";
import AddAdminForm from "../../../forms/addAdminForm";

const AddAdminMainComp = () => {
  return (
    <div class="container-fluid">
      <AddAdminForm />
    </div>
  );
};

export default AddAdminMainComp;
