import { debounce } from "@mui/material";
import React, { useEffect, useState } from "react";
import { Button, Modal, Form, Table, Row, Col, Spinner } from "react-bootstrap";
import { toast } from "react-toastify";
import { makeRequest } from "../../../../../api/axiosService";

const ActiveSections = () => {
  const [showModal, setShowModal] = useState(false);
  const [selectedProducts, setSelectedProducts] = useState([]);
  const [nextClicked, setNextClicked] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [sections, setSections] = useState([]);
  const [categories, setCategories] = useState([]);
  const [subcategories, setSubcategories] = useState([]);
  const [selectedSubCategory, setSelectedSubCategory] = useState("");
  const [products, setProducts] = useState([]);
  const [isFetchingProducts, setIsFetchingProducts] = useState(false);
  const [searchTerm, setSearchTerm] = useState("");
  const [isEdit, setIsEdit] = useState(false);
  const [isDeleting, setIsDeleting] = useState(null);
  const [isSaving, setIsSaving] = useState(false);
  const [editSectionId, setEditSectionId] = useState(null);
  const [isRemoving, setIsRemoving] = useState(false);
  const [checkRemove, setCheckRemove] = useState(null);
  const [sectionDetails, setSectionDetails] = useState({
    name_en: "",
    name_ar: "",
    name_ku: "",
    category: 0,
    products: [],
    position: 0,
    active: true,
  });

  const fetchSearchedProducts = async (categoryId, searchQuery) => {
    try {
      setIsFetchingProducts(true);
      setProducts([]);
      const resp = await makeRequest(
        `/public/search/?category=${categoryId}&name=${searchQuery}`,
        "GET"
      );
      setProducts(resp.results || []);
    } catch (error) {
      console.error("Failed to fetch searched products:", error);
    } finally {
      setIsFetchingProducts(false);
    }
  };

  const handleSearchInput = debounce((query) => {
    if (selectedCategory) {
      fetchSearchedProducts(selectedCategory, query);
    }
  }, 300);

  const onSearchChange = (e) => {
    const query = e.target.value;
    setSearchTerm(query);
    handleSearchInput(query);
  };

  const fetchSections = async () => {
    try {
      setIsLoading(true);
      setSections([]);
      const resp = await makeRequest("/public/sections/", "GET");
      if (resp[0]) {
        setSections(resp);
      }
    } catch (error) {
      console.error("Failed to fetch sections:", error);
    } finally {
      setIsLoading(false);
    }
  };

  const fetchCategory = async () => {
    try {
      setIsLoading(true);
      const resp = await makeRequest("/category/", "GET");
      if (resp.results) {
        setCategories(resp.results);
      }
    } catch (error) {
      console.error("Failed to fetch sections:", error);
    } finally {
      setIsLoading(false);
    }
  };

  const fetchProducts = async (categoryId, subCategoryId) => {
    try {
      setIsFetchingProducts(true);
      setProducts([]);
      const resp = await makeRequest(
        `/public/products?category=${categoryId}&subcategory=${subCategoryId}`,
        "GET"
      );
      setProducts(resp.results || []);
    } catch (error) {
      console.error("Failed to fetch products:", error);
    } finally {
      setIsFetchingProducts(false);
    }
  };

  useEffect(() => {
    fetchSections();
    fetchCategory();
  }, []);

  const toggleProductSelection = async (productId) => {
    setSelectedProducts((prevSelectedProducts) => {
      const isAlreadySelected = prevSelectedProducts.includes(productId);

      // If in edit mode and product is already selected, call the remove API
      if (isEdit && isAlreadySelected && checkRemove !== productId) {
        (async () => {
          setCheckRemove(productId);
          try {
            const response = await makeRequest(
              `/sections/${editSectionId}/remove_product/`,
              "POST",
              { product_id: productId }
            );

            if (response.success) {
              toast.success("Product removed from section successfully!");
            }
          } catch (error) {
            console.error("Error removing product:", error);
            toast.error("Failed to remove product. Please try again.");
          } finally {
            setCheckRemove(null);
          }
        })();
      }

      // Update the selected products array
      const updatedSelectedProducts = isAlreadySelected
        ? prevSelectedProducts.filter((id) => id !== productId) // Remove if already selected
        : [...prevSelectedProducts, productId]; // Add if not selected

      // Update the sectionDetails state with the updated product IDs
      setSectionDetails((prevDetails) => ({
        ...prevDetails,
        products: updatedSelectedProducts,
      }));

      return updatedSelectedProducts;
    });
  };

  const handleShow = () => setShowModal(true);
  const handleClose = () => {
    setShowModal(false);
    setNextClicked(false);
    setSelectedProducts([]);
    setSelectedCategory(null);
    setProducts([]);
    setSearchTerm("");
    setIsEdit(false);
    setSectionDetails({});
  };

  const [selectedCategory, setSelectedCategory] = useState(null);
  const [selectedSubcategory, setSelectedSubcategory] = useState("");

  const handleCategoryChange = (e) => {
    const categoryId = e.target.value;
    setSelectedCategory(categoryId);

    // Find the selected category to fetch its subcategories
    const selectedCat = categories.find(
      (cat) => cat.id === parseInt(categoryId)
    );
    setSubcategories(selectedCat?.subcategories || []);
    setSectionDetails((prev) => ({ ...prev, category: categoryId }));
    setSelectedSubcategory(""); // Reset subcategory selection
  };

  const handleSubCategoryChange = (e) => {
    const selectedSubCategoryId = e.target.value;
    setSelectedSubCategory(selectedSubCategoryId);

    if (selectedCategory && selectedSubCategoryId) {
      fetchProducts(selectedCategory, selectedSubCategoryId);
    }
  };

  const handleNext = () => {
    setNextClicked(!nextClicked); // Show selected products
  };

  const handleRemove = async (productId) => {
    setIsRemoving(true);
    if (isEdit) {
      await makeRequest(`/sections/${editSectionId}/remove_product/`, "POST", {
        product_id: productId,
      });
    }
    setSelectedProducts(
      selectedProducts.filter((product) => product.id !== productId)
    );
    setIsRemoving(false);
  };

  const selectedProductDetails = products.filter((product) =>
    selectedProducts.includes(product.id)
  );

  const handleSaveSection = async () => {
    try {
      setIsSaving(true);
      if (isEdit) {
        await makeRequest(
          `/sections/${editSectionId}/`,
          "PATCH",
          sectionDetails
        );
      } else {
        await makeRequest("/sections/", "POST", sectionDetails);
      }

      await fetchSections();
      setSectionDetails({});
      setSelectedProducts([]);
      setSelectedCategory(null);
      setProducts([]);
      setShowModal(false);
      setNextClicked(false);
    } catch (error) {
      console.error("Failed to create section:", error);
    } finally {
      setIsSaving(false);
    }
  };

  const handleDeleteSection = async (sectionId) => {
    try {
      setIsDeleting(sectionId);
      await makeRequest(`/sections/${sectionId}/`, "DELETE");
      await fetchSections();
    } catch (error) {
      console.error("Failed to delete section:", error);
    } finally {
      setIsDeleting(null);
    }
  };

  const handleEdit = (sectionId) => {
    setIsEdit(true);
    const sectionToEdit = sections.find((section) => section.id === sectionId);
    setEditSectionId(sectionId);
    // Set the section details to edit
    setSectionDetails({
      name_en: sectionToEdit.name_en,
      name_ar: sectionToEdit.name_ar,
      name_ku: sectionToEdit.name_ku,
      category: sectionToEdit.category.id,
      products: sectionToEdit.products.map((product) => product.id),
      position: sectionToEdit.position,
      active: sectionToEdit.active,
    });

    setSelectedProducts(sectionToEdit.products.map((product) => product.id));
    setProducts(sectionToEdit.products);

    setSelectedCategory(sectionToEdit.category.id);
    setSubcategories(sectionToEdit.category.subcategories || []);
    setSelectedSubCategory(sectionToEdit.subcategory?.id || "");
    setShowModal(true);
  };

  return (
    <div className="dashCard mb-4">
      <div className="d-flex justify-content-between align-items-center mb-3">
        <h2 className="mainTitleAdd">Section Management</h2>
        <Button variant="primary" onClick={handleShow}>
          Add Section
        </Button>
      </div>

      {/* Table */}
      <Table bordered hover responsive>
        <thead>
          <tr>
            <th>S.no</th>
            <th>Section Name</th>
            <th>Order</th>
            <th>Category</th>
            {/* <th>Created At</th> */}
            <th>Actions</th>
          </tr>
        </thead>
        <tbody>
          {sections.map((section, index) => (
            <tr key={section.id}>
              <td>{index + 1}</td>
              <td>{section.name_en}</td>
              <td>{section.position}</td>
              <td>{section.category.name_en}</td>
              {/* <td>{section.createdAt}</td> */}
              <td>
                <Button
                  variant="warning"
                  size="sm"
                  className="me-2"
                  onClick={() => handleEdit(section.id)}
                >
                  Edit
                </Button>
                <Button
                  variant="danger"
                  size="sm"
                  onClick={() => handleDeleteSection(section.id)}
                  disabled={isDeleting === section.id}
                >
                  {isDeleting === section.id ? (
                    <Spinner animation="border" size="sm" />
                  ) : (
                    "Delete"
                  )}
                </Button>
              </td>
            </tr>
          ))}
          {isLoading && (
            <tr>
              <td colSpan="6" className="text-center">
                <Spinner animation="border" variant="primary" />
              </td>
            </tr>
          )}
          {sections.length === 0 && !isLoading && (
            <tr>
              <td className="text-center" colSpan="6">
                No sections found
              </td>
            </tr>
          )}
        </tbody>
      </Table>

      {/* Modal */}
      <Modal show={showModal} onHide={handleClose} size="lg">
        <Modal.Header closeButton style={{ backgroundColor: "white" }}>
          <Modal.Title>
            {isEdit ? "Edit Section" : "Add New Section"}
          </Modal.Title>
        </Modal.Header>
        <Modal.Body style={{ backgroundColor: "white" }}>
          {nextClicked && (
            <div>
              <h4>Selected Products for Trending section</h4>
            </div>
          )}
          {!nextClicked && (
            <Form>
              <Row>
                <Col lg={6}>
                  <Form.Group controlId="orderNumber" className="mb-4">
                    <Form.Label>Order Number</Form.Label>
                    <Form.Control
                      type="number"
                      placeholder="Enter order number"
                      value={sectionDetails.position}
                      onChange={(e) =>
                        setSectionDetails((prev) => ({
                          ...prev,
                          position: e.target.value,
                        }))
                      }
                    />
                  </Form.Group>
                  <Form.Group controlId="category" className="mb-4">
                    <Form.Label>Category</Form.Label>
                    <Form.Select
                      value={selectedCategory}
                      onChange={handleCategoryChange}
                    >
                      <option value="">Select Category</option>
                      {categories.map((category) => (
                        <option key={category.id} value={category.id}>
                          {category.name_en}
                        </option>
                      ))}
                    </Form.Select>
                  </Form.Group>
                  <Form.Group controlId="subcategory" className="mb-4">
                    <Form.Label>Subcategory</Form.Label>
                    <Form.Select
                      value={selectedSubcategory.id}
                      onChange={handleSubCategoryChange}
                      disabled={!selectedCategory}
                    >
                      <option value="">Select Subcategory</option>
                      {subcategories?.map((subcategory) => (
                        <option key={subcategory.id} value={subcategory.id}>
                          {subcategory.name_en}
                        </option>
                      ))}
                    </Form.Select>
                  </Form.Group>
                </Col>
                <Col lg={6}>
                  <Form.Group controlId="sectionName" className="mb-3">
                    <Form.Label>Section Name</Form.Label>
                    <Form.Control
                      type="text"
                      placeholder="Enter section name"
                      value={sectionDetails.name_en}
                      onChange={(e) =>
                        setSectionDetails((prev) => ({
                          ...prev,
                          name_en: e.target.value,
                        }))
                      }
                    />
                  </Form.Group>
                  <Form.Group
                    controlId="sectionNameInAr"
                    dir="rtl"
                    className="mb-3"
                  >
                    <Form.Label>اسم القسم باللغة العربية</Form.Label>
                    <Form.Control
                      type="text"
                      placeholder="أدخل اسم القسم"
                      value={sectionDetails.name_ar}
                      onChange={(e) =>
                        setSectionDetails((prev) => ({
                          ...prev,
                          name_ar: e.target.value,
                        }))
                      }
                    />
                  </Form.Group>
                  <Form.Group
                    controlId="sectionNameInKu"
                    dir="rtl"
                    className="mb-3"
                  >
                    <Form.Label>ناوی بەشی بە زمانی کوردی</Form.Label>
                    <Form.Control
                      type="text"
                      placeholder="ناوی بەشەکە داخڵ بکە"
                      value={sectionDetails.name_ku}
                      onChange={(e) =>
                        setSectionDetails((prev) => ({
                          ...prev,
                          name_ku: e.target.value,
                        }))
                      }
                    />
                  </Form.Group>
                </Col>
              </Row>

              {/* Search Bar */}
              <Row className="mt-3 mb-4">
                <Col md={12}>
                  <Form.Group controlId="searchBar">
                    <Form.Label>Search</Form.Label>
                    <Form.Control
                      type="text"
                      placeholder="Search products"
                      value={searchTerm}
                      onChange={onSearchChange}
                      disabled={!selectedCategory}
                    />
                  </Form.Group>
                </Col>
              </Row>
            </Form>
          )}
          {nextClicked ? (
            <Table bordered hover responsive>
              <thead>
                <tr>
                  <th>Product Image</th>
                  <th>Product Name</th>
                  <th>Category</th>
                  <th>Sub Category</th>
                  <th>Status</th>
                  <th>Actions</th>
                </tr>
              </thead>
              <tbody>
                {selectedProductDetails.map((product) => (
                  <tr key={product.id}>
                    <td>
                      <img
                        src={product.images[0]}
                        alt={product.name}
                        width={50}
                      />
                    </td>
                    <td>{product.name_en}</td>
                    <td>{product.category_name}</td>
                    <td>{product.subcategory_name}</td>
                    <td>{product.status}</td>
                    <td>
                      <Button
                        variant="danger"
                        size="sm"
                        onClick={() => handleRemove(product.id)}
                      >
                        Remove
                      </Button>
                    </td>
                  </tr>
                ))}
              </tbody>
            </Table>
          ) : (
            // Show All Products for Selection
            <Table bordered hover responsive className="mt-3">
              <thead>
                <tr>
                  <th>Select</th>
                  <th>Product Image</th>
                  <th>Product Name</th>
                  <th>Category</th>
                  <th>Sub Category</th>
                  <th>Status</th>
                </tr>
              </thead>
              <tbody>
                {isFetchingProducts && (
                  <tr>
                    <td colSpan="6" className="text-center">
                      {" "}
                      <Spinner animation="border" variant="primary" />
                    </td>
                  </tr>
                )}
                {products?.map((product) => (
                  <tr key={product.id}>
                    <td>
                      {checkRemove === product.id ? (
                        <Spinner animation="border" size="sm" />
                      ) : (
                        <Form.Check
                          type="checkbox"
                          checked={selectedProducts.includes(product.id)}
                          onChange={() => toggleProductSelection(product.id)}
                        />
                      )}
                    </td>
                    <td>
                      <img
                        src={product.images[0]}
                        alt={product.name}
                        width={50}
                      />
                    </td>
                    <td>{product.name_en}</td>
                    <td>{product.category_name}</td>
                    <td>{product.subcategory_name}</td>
                    <td>{product.status}</td>
                  </tr>
                ))}
                {products.length === 0 && !isFetchingProducts && (
                  <tr>
                    <td colSpan="6" className="text-center">
                      No products found
                    </td>
                  </tr>
                )}
              </tbody>
            </Table>
          )}
        </Modal.Body>
        <Modal.Footer style={{ backgroundColor: "white" }}>
          {!nextClicked ? (
            <Button variant="secondary" onClick={handleClose}>
              Cancel
            </Button>
          ) : (
            <Button variant="secondary" onClick={handleNext}>
              Back
            </Button>
          )}
          {!nextClicked ? (
            <Button
              variant="primary"
              onClick={handleNext}
              disabled={selectedProducts.length === 0}
            >
              Next
            </Button>
          ) : (
            <Button
              variant="primary"
              disabled={isSaving}
              onClick={handleSaveSection}
            >
              {isSaving ? <Spinner animation="border" size="sm" /> : "Save"}
            </Button>
          )}
        </Modal.Footer>
      </Modal>
    </div>
  );
};

export default ActiveSections;
