import React, { useState } from "react";
import { Form, Row, Col, Button, Spinner } from "react-bootstrap";
import { toast } from "react-toastify";
import PhoneInput from "react-phone-number-input";
import "react-phone-number-input/style.css";
import { makeRequest } from "../../api/axiosService";
import { Link, useNavigate } from "react-router-dom";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faArrowLeft, faBuilding } from "@fortawesome/free-solid-svg-icons";

const AddCompanyForm = () => {
  const [isLoading, setIsLoading] = useState(false);
  const [nameEn, setNameEn] = useState("");
  const [nameAr, setNameAr] = useState("");
  const [nameKu, setNameKu] = useState("");
  const [aboutEn, setAboutEn] = useState("");
  const [aboutAr, setAboutAr] = useState("");
  const [aboutKu, setAboutKu] = useState("");
  const [addressEn, setAddressEn] = useState("");
  const [addressAr, setAddressAr] = useState("");
  const [addressKu, setAddressKu] = useState("");
  const [phone, setPhone] = useState("");
  const [organizationNumber, setOrganizationNumber] = useState("");
  const [email, setEmail] = useState("");
  const [website, setWebsite] = useState("");
  const [rating, setRating] = useState(0);
  const [bankAccount, setBankAccount] = useState("");

  const navigate = useNavigate();

  const handleAddCompany = async (e) => {
    e.preventDefault();

    try {
      setIsLoading(true);

      const data = {
        name_en: nameEn,
        name_ar: nameAr,
        name_ku: nameKu,
        about_en: aboutEn,
        about_ar: aboutAr,
        about_ku: aboutKu,
        rating,
        address_en: addressEn,
        address_ar: addressAr,
        address_ku: addressKu,
        phone_number: phone,
        organization_email: email,
        organization_number: organizationNumber,
        website,
        bank_account: bankAccount,
      };

      await makeRequest("/companys/create/", "POST", data); // API call
      toast("Company created successfully");

      // Reset form fields
      resetFormFields();
      navigate("/dashboard/all-company");
    } catch (error) {
      handleError(error);
    } finally {
      setIsLoading(false);
    }
  };

  // Utility function to reset form fields
  const resetFormFields = () => {
    setNameEn("");
    setNameAr("");
    setNameKu("");
    setAboutEn("");
    setAboutAr("");
    setAboutKu("");
    setAddressEn("");
    setAddressAr("");
    setAddressKu("");
    setPhone("");
    setOrganizationNumber("");
    setEmail("");
    setWebsite("");
    setRating(0);
    setBankAccount("");
  };

  // Utility function to handle errors
  const handleError = (error) => {
    console.log(error);
    if (error?.response?.status === 401) {
      toast("Your session has expired. Please log in again.");
    } else if (error?.response?.status === 400) {
      // toast("Company name already exists.");
    } else {
      // toast("An unexpected error occurred.");
    }
  };

  return (
    <>
      <div className="d-sm-flex align-items-center justify-content-between my-3">
        <h3 className="page-title">
          <span className="page-title-icon bg-gradient-primary text-white me-2">
            <i>
              <FontAwesomeIcon icon={faBuilding} />
            </i>
          </span>{" "}
          ADD COMPANY
        </h3>
        <div className="d-flex justify-content-end align-items-center">
          <Link
            className="p-2 rounded bg-gradient-success text-white me-2 shadow-sm"
            to="/dashboard/all-company"
            style={{ cursor: "pointer" }}
          >
            <FontAwesomeIcon icon={faArrowLeft} />
          </Link>
        </div>
      </div>

      <div className="col-12">
        <div className="card shadow" style={{ padding: "1rem" }}>
          <div className="card-body">
            <Form onSubmit={handleAddCompany}>
              <h5 className="mb-3">Company Information:</h5>

              <Row className="mb-3">
                <Col sm={12} md={6}>
                  <Form.Group controlId="name_en">
                    <Form.Label>Company Name (English)</Form.Label>
                    <Form.Control
                      type="text"
                      value={nameEn}
                      onChange={(e) => setNameEn(e.target.value)}
                      placeholder="Enter Company Name in English"
                      // required
                    />
                  </Form.Group>
                </Col>

                <Col sm={12} md={6}>
                  <Form.Group controlId="address_en">
                    <Form.Label>Address (English)</Form.Label>
                    <Form.Control
                      type="text"
                      value={addressEn}
                      onChange={(e) => setAddressEn(e.target.value)}
                      placeholder="Enter Address in English"
                      // required
                    />
                  </Form.Group>
                </Col>
                <Col className="mt-3" sm={12}>
                  <Form.Group controlId="about_en">
                    <Form.Label>About (English)</Form.Label>
                    <Form.Control
                      as="textarea"
                      value={aboutEn}
                      onChange={(e) => setAboutEn(e.target.value)}
                      placeholder="Enter About in English"
                      rows={3}
                      // required
                    />
                  </Form.Group>
                </Col>
              </Row>

              {/* Arabic Fields */}
              <Row className="mb-3">
                <Col sm={12} md={6} dir="rtl">
                  <Form.Group controlId="name_ar">
                    <Form.Label>اسم الشركة (بالعربية)</Form.Label>
                    <Form.Control
                      type="text"
                      value={nameAr}
                      onChange={(e) => setNameAr(e.target.value)}
                      placeholder="أدخل اسم الشركة بالعربية"
                    />
                  </Form.Group>
                </Col>

                <Col sm={12} md={6} dir="rtl">
                  <Form.Group controlId="address_ar">
                    <Form.Label>عنوان (عربي)</Form.Label>
                    <Form.Control
                      type="text"
                      value={addressAr}
                      onChange={(e) => setAddressAr(e.target.value)}
                      placeholder="أدخل العنوان باللغة العربية"
                    />
                  </Form.Group>
                </Col>
                <Col className="mt-3" sm={12} dir="rtl">
                  <Form.Group controlId="about_ar">
                    <Form.Label>معلومات عن الشركة (بالعربية)</Form.Label>
                    <Form.Control
                      as="textarea"
                      value={aboutAr}
                      onChange={(e) => setAboutAr(e.target.value)}
                      placeholder="أدخل معلومات عن الشركة بالعربية"
                      rows={3}
                    />
                  </Form.Group>
                </Col>
              </Row>

              {/* Kurdish Fields */}
              <Row className="mb-3">
                <Col sm={12} md={6} dir="rtl">
                  <Form.Group controlId="name_ku">
                    <Form.Label>ناوی کۆمپانیا (کوردی)</Form.Label>
                    <Form.Control
                      type="text"
                      value={nameKu}
                      onChange={(e) => setNameKu(e.target.value)}
                      placeholder="ناوی کۆمپانیا لە کوردی"
                    />
                  </Form.Group>
                </Col>

                <Col sm={12} md={6} dir="rtl">
                  <Form.Group controlId="address_ku">
                    <Form.Label>ناونیشان (کوردی)</Form.Label>
                    <Form.Control
                      type="text"
                      value={addressKu}
                      onChange={(e) => setAddressKu(e.target.value)}
                      placeholder="ناونیشانی کەسەکە لە کوردی بنوسە"
                    />
                  </Form.Group>
                </Col>
                <Col className="mt-3" sm={12} dir="rtl">
                  <Form.Group controlId="about_ku">
                    <Form.Label>دەربارەی کۆمپانیا (کوردی)</Form.Label>
                    <Form.Control
                      as="textarea"
                      value={aboutKu}
                      onChange={(e) => setAboutKu(e.target.value)}
                      placeholder="دەربارەی کۆمپانیا لە کوردی"
                      rows={3}
                    />
                  </Form.Group>
                </Col>
              </Row>

              {/* Phone Number */}
              <Row className="mb-3">
                <Col lg={6} md={6} sm={12}>
                  <Form.Group controlId="phone_number">
                    <Form.Label>Phone Number</Form.Label>
                    <PhoneInput
                      className="form-control"
                      defaultCountry="SE"
                      value={phone}
                      onChange={setPhone}
                      limitMaxLength={true}
                      placeholder="Contact Number"
                      style={{
                        height: "46px",
                        borderRadius: "none",
                        border: "1px solid #ebedf2",
                      }}
                    />
                  </Form.Group>
                </Col>
                <Col lg={6} md={6} sm={12}>
                  <Form.Group controlId="rating">
                    <Form.Label>Rating</Form.Label>
                    <Form.Control
                      type="number"
                      value={rating}
                      onChange={(e) => setRating(Number(e.target.value))}
                      placeholder="Enter Rating"
                      min={0}
                      max={5}
                    />
                  </Form.Group>
                </Col>
              </Row>

              {/* New Fields for Email, Organization Number, Website, and Bank Account */}
              <Row className="mb-3">
                <Col lg={6} md={6} sm={12}>
                  <Form.Group controlId="organization_email">
                    <Form.Label>Organization Email</Form.Label>
                    <Form.Control
                      type="email"
                      value={email}
                      onChange={(e) => setEmail(e.target.value)}
                      placeholder="Enter Organization Email"
                      // required
                    />
                  </Form.Group>
                </Col>
                <Col lg={6} md={6} sm={12}>
                  <Form.Group controlId="organization_number">
                    <Form.Label>Organization Number</Form.Label>
                    <Form.Control
                      type="text"
                      value={organizationNumber}
                      onChange={(e) => setOrganizationNumber(e.target.value)}
                      placeholder="Enter Organization Number"
                      // required
                    />
                  </Form.Group>
                </Col>
              </Row>

              <Row className="mb-3">
                <Col lg={6} md={6} sm={12}>
                  <Form.Group controlId="website">
                    <Form.Label>Website</Form.Label>
                    <Form.Control
                      type="url"
                      value={website}
                      onChange={(e) => setWebsite(e.target.value)}
                      placeholder="Enter Website URL"
                      // required
                    />
                  </Form.Group>
                </Col>
                <Col lg={6} md={6} sm={12}>
                  <Form.Group controlId="bank_account">
                    <Form.Label>Bank Account</Form.Label>
                    <Form.Control
                      type="text"
                      value={bankAccount}
                      onChange={(e) => setBankAccount(e.target.value)}
                      placeholder="Enter Bank Account"
                      // required
                    />
                  </Form.Group>
                </Col>
              </Row>

              {/* Submit Button */}
              <Row className="mb-3">
                <Col className="d-flex justify-content-center">
                  <Button disabled={isLoading} type="submit" variant="primary">
                    {isLoading ? (
                      <Spinner animation="border" size="sm" />
                    ) : (
                      "Submit"
                    )}
                  </Button>
                </Col>
              </Row>
            </Form>
          </div>
        </div>
      </div>
    </>
  );
};

export default AddCompanyForm;
