import React, { useState, useRef, useEffect } from "react";
import { toast } from "react-toastify";
import { Form, Button, Row, Col, Spinner } from "react-bootstrap";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faTrash } from "@fortawesome/free-solid-svg-icons";
import { makeRequest } from "../../../../../api/axiosService";
import BackButton from "../../../../BackButton";

const AddReturnMainComp = () => {
  const MAX_FILE_SIZE = 5 * 1024 * 1024; // 5 MB
  const fileInputRef = useRef(null);
  const [subOrders, setSubOrders] = useState([]);
  const [selectedSubOrderId, setSelectedSubOrderId] = useState(null);
  const [returnData, setReturnData] = useState({
    return_reason: "",
    return_images: [],
  });
  const [errors, setErrors] = useState({
    return_reason: false,
  });
  const [uploading, setUploading] = useState(false);
    const [loading, setLoading] = useState(false);
    const [loadingSubOrders, setLoadingSubOrders] = useState(false);

  

  useEffect(() => {
    fetchSubOrders();
  }, []);

  const fetchSubOrders = async () => {
    try {
      setLoadingSubOrders(true)
      const response = await makeRequest("/sub-orders/?status=delivered", "GET");
      if (response?.results) {
        setSubOrders(response?.results);
      }
    } catch (error) {
      console.error("Error fetching sub-orders:", error);
      toast.error("Failed to load sub-orders. Please try again.");
    }finally{
      setLoadingSubOrders(false)

    }
  };

  const handleSubOrderChange = (e) => {
    setSelectedSubOrderId(e.target.value);
  };

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setReturnData({ ...returnData, [name]: value });

    // Clear error on input change
    if (errors[name]) {
      setErrors({ ...errors, [name]: false });
    }
  };

  const handleImageUpload = async (e) => {
    const files = e.target.files;

    if (files.length > 0) {
      for (const file of files) {
        try {
          if (file.size > MAX_FILE_SIZE) {
            toast.error("File size is too large. Please upload files under 5 MB.");
            return;
          }

          setUploading(true);
          const formData = new FormData();
          formData.append("added_images", file);

          // Make the API call to upload the image
          const response = await makeRequest("/upload-image/", "POST", formData);

          if (response?.uploaded_urls?.[0]) {
            toast.success("Image uploaded successfully!");
            setReturnData((prevState) => ({
              ...prevState,
              return_images: [...prevState.return_images, response.uploaded_urls[0]],
            }));
          }
        } catch (error) {
          toast.error("Failed to upload image. Please try again.");
          console.error("Error uploading image:", error);
        } finally {
          setUploading(false);
        }
      }
    }
  };

  const handleRemoveImage = (index) => {
    setReturnData((prevState) => ({
      ...prevState,
      return_images: prevState.return_images.filter((_, i) => i !== index),
    }));
    if (fileInputRef.current) {
      fileInputRef.current.value = "";
    }
  };

  const validateFields = () => {
    const newErrors = {
      return_reason: !returnData.return_reason.trim(),
    };

    setErrors(newErrors);
    return !Object.values(newErrors).some((error) => error);
  };

  const saveReturn = async () => {
    if (!validateFields() || !selectedSubOrderId) {
      if (!selectedSubOrderId) toast.error("Please select a sub-order.");
      return;
    }

    try {
    setLoading(true); // Start loading

      await makeRequest(`/sub-orders/${selectedSubOrderId}/return/`, "POST", returnData);
      toast.success("Return added successfully!");

      // Reset form
      setReturnData({
        return_reason: "",
        return_images: [],
      });
      setSelectedSubOrderId(null);
      if (fileInputRef.current) fileInputRef.current.value = "";
    } catch (error) {
      console.error("Error saving return:", error);
      toast.error(error?.response?.data?.error || "Failed to add return. Please try again.");
    }
    finally{
    setLoading(false); // Start loading

    }
  };

  return (
    <div className="dashCard mb-4">
        
      <Row className="mb-3">
      <Col className="d-flex align-items-center">
          <BackButton />
          <h2 className="mainTitleAdd">Add New Return</h2>
        </Col>
      </Row>

      <Form>
        {/* Sub-Order Dropdown */}
        <Row className="mb-3">
          <Col >
            <Form.Group controlId="formSubOrderSelect">
              <Form.Label>Select Sub-Order</Form.Label>
              <Form.Control
                as="select"
                value={selectedSubOrderId || ""}
                onChange={handleSubOrderChange}
                style={{
                    border: "1px solid #ebedf2",
                    padding: "15px 15px",
                    width: "100%",
                  }}   
                  disabled={loadingSubOrders}           >
                <option value="" disabled>
                 {loadingSubOrders?'loading...':' Select a sub-order'}
                </option>
                {subOrders.map((order) => (
                  <option key={order.id} value={order.id}>
                   Order ID:  {order.id} - Company: {order.company_name}
                  </option>
                ))}
              </Form.Control>
            </Form.Group>
          </Col>
        </Row>

        {/* Return Reason */}
        <Row className="mb-3">
          <Col >
            <Form.Group controlId="formReturnReason">
              <Form.Label>Return Reason</Form.Label>
              <Form.Control
                type="text"
                name="return_reason"
                value={returnData.return_reason}
                onChange={handleInputChange}
                placeholder="Enter return reason"
                isInvalid={errors.return_reason}
                required
              />
              {errors.return_reason && (
                <Form.Control.Feedback type="invalid">
                  Return reason is required.
                </Form.Control.Feedback>
              )}
            </Form.Group>
          </Col>
        </Row>

        {/* Image Upload */}
        <Row className="mb-3">
          <Col >
            <Form.Group controlId="formReturnImages">
              <Form.Label>Upload Images</Form.Label>
              <Form.Control
                type="file"
                ref={fileInputRef}
                onChange={handleImageUpload}
                multiple
              />
            </Form.Group>

            {uploading && <Spinner animation="border" size="lg" className="mt-5 mb-3 mx-3" />}

            {returnData.return_images.length > 0 && (
              <div className="mt-3  d-flex">
                {returnData.return_images.map((image, index) => (
                  <div key={index} className="mt-4 text-center">
                    <img
                      src={image}
                      alt={`Return Preview ${index + 1}`}
                      style={{
                        width: "200px",
                        height: "200px",
                        objectFit: "contain",
                        borderRadius: "12px",
                        backgroundColor: "#F0F0F0",
                      }}
                    />
                    <div className="mt-2">
                      <FontAwesomeIcon
                        icon={faTrash}
                        style={{
                          color: "red",
                          cursor: "pointer",
                          fontSize: "1rem",
                        }}
                        title="Remove image"
                        onClick={() => handleRemoveImage(index)}
                      />
                    </div>
                  </div>
                ))}
              </div>
            )}
          </Col>
        </Row>

        {/* Save Button */}
        <Row>
          <Col >
            <Button variant="primary" onClick={saveReturn} disabled={uploading}>
              {loading ? <Spinner animation="border" size="sm" /> : "Save"}
            </Button>
          </Col>
        </Row>
      </Form>
    </div>
  );
};

export default AddReturnMainComp;
