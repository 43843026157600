import React from "react";
import { Col, Row } from "react-bootstrap";
import NewsLetter from "./newsLetter/NewsLetter";
import ActiveSections from "./activeSections/ActiveSectionsComponent";
import CarouselItems from "./carouselItems/CarouselItems";
import TopHeader from "./topHeader/TopHeader";

const WebSettingsMainComp = () => {
  return (
    <div>
      <Row>
        <Col sm={12}>
          <TopHeader />
        </Col>
        <Col sm={12}>
          <NewsLetter />
        </Col>
        <Col sm={12}>
          <ActiveSections />
        </Col>
        <Col sm={12}>
          <CarouselItems />
        </Col>
      </Row>
    </div>
  );
};

export default WebSettingsMainComp;
