import React, { useState, useEffect, useRef } from "react";
import { toast } from "react-toastify";
import { Form, Button, Row, Col, Spinner } from "react-bootstrap";
import { makeRequest } from "../../../../../api/axiosService";

const NewsLetter = () => {
  const MAX_FILE_SIZE = 5 * 1024 * 1024; // 5 MB
  const fileInputRef = useRef(null);
  const [newsletterData, setNewsletterData] = useState({
    title: "",
    description: "",
    emails: [],
    first_time_newsletter: "",
    image_url: "",
  });

  const [loading, setLoading] = useState({ fetch: false, save: false });
  const [isEditing, setIsEditing] = useState(false);

  useEffect(() => {
    fetchNewsletter();
  }, []);

  const fetchNewsletter = async () => {
    setLoading((prev) => ({ ...prev, fetch: true }));
    try {
      const response = await makeRequest("/newsletters/", "GET");
      if (response?.results?.length > 0) {
        setNewsletterData(response.results[0]);
        setIsEditing(true);
      }
    } catch (error) {
      console.error("Error fetching newsletter:", error);
      toast.error("Failed to load newsletter.");
    } finally {
      setLoading((prev) => ({ ...prev, fetch: false }));
    }
  };

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setNewsletterData({ ...newsletterData, [name]: value });
  };

  const handleImageUpload = async (e) => {
    const file = e.target.files[0];
    if (!file) return;

    if (file.size > MAX_FILE_SIZE) {
      toast.error("File size is too large. Please upload files under 5 MB.");
      return;
    }

    try {
      setLoading((prev) => ({ ...prev, save: true }));
      const formData = new FormData();
      formData.append("added_images", file);

      const response = await makeRequest("/upload-image/", "POST", formData);

      if (response?.uploaded_urls?.[0]) {
        toast.success("Image uploaded successfully.");
        setNewsletterData((prevState) => ({
          ...prevState,
          image_url: response.uploaded_urls[0],
        }));
      }
    } catch (error) {
      toast.error("Failed to upload image. Please try again.");
      console.error("Error uploading image:", error);
    } finally {
      setLoading((prev) => ({ ...prev, save: false }));
    }
  };

  const saveNewsletter = async () => {
    setLoading((prev) => ({ ...prev, save: true }));
    try {
      const method = isEditing ? "PATCH" : "POST";
      const endpoint = isEditing
        ? `/newsletters/${newsletterData.id}/`
        : "/newsletters/";

      await makeRequest(endpoint, method, newsletterData);
      toast.success(
        `Newsletter ${isEditing ? "updated" : "created"} successfully.`
      );
      if (!isEditing) fetchNewsletter();
    } catch (error) {
      console.error(
        `Error ${isEditing ? "updating" : "creating"} newsletter:`,
        error
      );
      toast.error(`Failed to ${isEditing ? "update" : "create"} newsletter.`);
    } finally {
      setLoading((prev) => ({ ...prev, save: false }));
    }
  };

  return (
    <div className="dashCard mb-4">
      <Row className="mb-3">
        <Col className="d-flex align-items-center">
          <h2 className="mainTitleAdd">Newsletter</h2>
        </Col>
      </Row>

      <Form>
        <Row className="mb-3">
          <Col sm={12} md={6}>
            <Form.Group controlId="title">
              <Form.Label>Add Title</Form.Label>
              <Form.Control
                type="text"
                name="title"
                value={newsletterData.title}
                onChange={handleInputChange}
                placeholder="Enter Title"
                required
              />
            </Form.Group>
          </Col>
          <Col sm={12} md={6}>
            <Form.Group controlId="description">
              <Form.Label>Add Description</Form.Label>
              <Form.Control
                type="text"
                name="description"
                value={newsletterData.description}
                onChange={handleInputChange}
                placeholder="Enter Description"
                required
              />
            </Form.Group>
          </Col>
        </Row>

        <Row className="mb-3">
          <Col sm={12}>
            <Form.Group controlId="first_time_newsletter">
              <Form.Label>First Time Newsletter Message</Form.Label>
              <Form.Control
                type="text"
                name="first_time_newsletter"
                value={newsletterData.first_time_newsletter}
                onChange={handleInputChange}
                placeholder="Enter First Time Message"
                required
              />
            </Form.Group>
          </Col>
        </Row>

        <Row className="mb-3">
          <Col sm={12} md={6}>
            <Form.Group controlId="emails">
              <Form.Label>Emails</Form.Label>
              <div
                style={{
                  maxHeight: "150px", // Set a fixed height
                  overflowY: "auto", // Enable vertical scrolling
                  border: "1px solid #ccc", // Optional: Add a border for visibility
                  padding: "10px", // Optional: Add padding for aesthetics
                  borderRadius: "5px", // Optional: Round the corners
                  backgroundColor: "#f9f9f9", // Optional: Add a light background
                }}
              >
                {newsletterData.emails.length > 0 ? (
                  <ul style={{ margin: 0, paddingLeft: "20px" }}>
                    {newsletterData.emails.map((email, index) => (
                      <li key={index}>{email}</li>
                    ))}
                  </ul>
                ) : (
                  <p>No emails available.</p>
                )}
              </div>
            </Form.Group>
          </Col>

          <Col sm={12} md={6}>
            <Form.Group controlId="image_url">
              <Form.Label>Upload Image</Form.Label>
              <Form.Control
                type="file"
                ref={fileInputRef}
                onChange={handleImageUpload}
                required
              />
            </Form.Group>
            {newsletterData.image_url && (
              <div className="mt-3 text-center">
                <img
                  src={newsletterData.image_url}
                  alt="Preview"
                  style={{
                    width: "200px",
                    height: "200px",
                    objectFit: "contain",
                    borderRadius: "12px",
                    backgroundColor: "#F0F0F0",
                  }}
                />
              </div>
            )}
          </Col>
        </Row>

        <Row>
          <Col sm={12} className="d-flex justify-content-end">
            <Button
              variant="primary"
              onClick={saveNewsletter}
              disabled={loading.save}
            >
              {loading.save ? <Spinner animation="border" size="sm" /> : "Save"}
            </Button>
          </Col>
        </Row>
      </Form>

      {loading.fetch && (
        <Row className="mt-4">
          <Col sm={12} className="d-flex justify-content-center">
            <Spinner animation="border" />
          </Col>
        </Row>
      )}
    </div>
  );
};

export default NewsLetter;
